import React from 'react';

// importing material UI elements
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/';

// importing internal components
import { PAGE_SIZE } from '../utils/constants';

const useStyles = makeStyles(() => {
  return {
    itemContainer: {
      height: '650px',
      width: '100%',
    },
    miniListContainer: {
      height: '350px',
      width: '100%',
    },
  };
});

const ListContainer = (props) => {
  const {
    items,
    loading,
    columns,
    gridPage,
    rowCount,
    onPageChange,
    setSelection,
    miniListContainer = false,
    ...otherProps
  } = props;

  const classes = useStyles();

  const checkboxSelection =
    typeof otherProps.checkboxSelection === 'undefined'
      ? true
      : otherProps.checkboxSelection;

  return (
    <div
      className={
        miniListContainer ? classes.miniListContainer : classes.itemContainer
      }
    >
      <DataGrid
        rows={items}
        columns={columns}
        loading={loading}
        disableColumnFilter={true}
        // --- Checkboxes ---
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick={true}
        onSelectionChange={(newSelection) => {
          setSelection(newSelection.rowIds);
        }}
        // --- Pagination ---
        paginationMode='server'
        pagination
        rowCount={rowCount}
        pageSize={PAGE_SIZE}
        page={gridPage}
        onPageChange={onPageChange}
        {...otherProps}
      />
    </div>
  );
};

export default ListContainer;
