import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import FeedbackForm from '../components/dialogs/feedback-form';

import { observer } from 'mobx-react';
import get from 'get-value';

// importing Material UI elements
import {
  Drawer,
  CssBaseline,
  Hidden,
  useTheme,
  makeStyles,
} from '@material-ui/core';

// importing internal components
import HeaderContent from './header';
import withSnackbar from '../utils/with-snackbar';
import Navigation from './navigation';

import { useStore } from '../stores';
import { getLanguageSupport } from '../utils/helper';

// importing constants
import { SNACKBAR_SEVERITY } from '../utils/constants';
import APIHelper from '../helpers/api-helper';

const drawerWidth = 240;
const appBarHeight = 45;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: appBarHeight,
  },
  feedbackButton: {
    color: 'white',
    backgroundColor: '#3D4977',
    cursor: 'pointer',
    bottom: '50%',
    right: 0,
    position: 'fixed',
    transform: 'rotate(270deg) translateY(-50%)',
    transformOrigin: 'right',
    border: 'none',
    borderRadius: '2px 2px 0px 0px',
    outline: 'none',
    padding: '10px 20px',
    overflow: 'hidden',
    boxShadow: 'rgb(0 0 0 / 10%) -4px -12px 25px 0px',
    boxSizing: 'border-box',
    alignItems: 'center',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#56649c',
    },
  },
}));

const Main = observer((props) => {
  const { userAuthenticated } = props;

  const settings = getLanguageSupport();

  const store = useStore();
  const { employeeID, fullName, email } = store.employeeData;

  // STATE
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  useEffect(() => {
    Promise.all([store.getUserData()]).then(([userLoaded]) => {
      if (!userLoaded) {
        props.showSnackbar(
          get(settings, 'access.loggedOut', {
            default: 'You are not logged in!',
          }),
          SNACKBAR_SEVERITY.ERROR
        );
        history.push('/login');
      }
    });
  }, []);

  function handleLoginButton() {
    APIHelper.prototype
      .fetchData(`/auth/logout`, 'GET')
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem('lastLocation');
          if (localStorage.getItem('rememberMe') === 'false') {
            localStorage.removeItem('loginData');
          }
          history.push('/login');
          history.go();
        } else {
          throw new Error(res.error);
        }
      })
      .catch(console.error);
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const openForm = () => {
    setIsFormOpen(true);
  };

  if (userAuthenticated && employeeID)
    return (
      <>
        <div className={classes.root}>
          <CssBaseline />
          <HeaderContent handleDrawerToggle={handleDrawerToggle} />
          <nav aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <Navigation
                  settings={settings}
                  currentName={fullName}
                  currentEmail={email}
                  handleLoginButton={handleLoginButton}
                />
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                <Navigation
                  settings={settings}
                  currentName={fullName}
                  currentEmail={email}
                  handleLoginButton={handleLoginButton}
                />
              </Drawer>
            </Hidden>
          </nav>

          <FeedbackForm
            isOpen={isFormOpen}
            handleClose={() => setIsFormOpen(false)}
            settings={settings}
          />

          <button className={classes.feedbackButton} onClick={openForm}>
            Feedback
          </button>
        </div>
      </>
    );

  return null;
});

export default withRouter(withSnackbar(Main));
