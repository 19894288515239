import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'get-value';

//Material UI imports
import {
  makeStyles,
  TextField,
  Grid,
  Button,
  IconButton,
  Tooltip,
  InputAdornment,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import APIHelper from '../../helpers/api-helper';

// importing internal components
import { getLanguageSupport } from '../../utils/helper';
import {
  decryptData,
  encryptData,
  LOGIN_ERRORS,
} from '../../helpers/login-helper';

const settings = getLanguageSupport();

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  main: {
    paddingTop: 200,
    width: '100%',
    height: '10em',
    display: 'block',
    margin: '0 auto',
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 30,
  },
  paperEmail: {
    display: 'flex',
    justifyContent: 'center',
    width: 380,
  },
  paperPassword: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 380,
  },

  customAdornment: {
    marginRight: -14,
  },

  paperButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 30,
  },
  paperDiv: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '25px',
    marginLeft: '8px',
  },
  img: {
    width: '180px',
    height: '30px',
    marginLeft: '5px',
  },
  width: {
    width: '500px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)',
    },
  },
  input: {
    marginRight: '10px',
  },
  text: {
    fontWeight: 'bold',
  },
  spinner: {
    display: 'flex',
    flexDirection: 'row',
  },
  circular: {
    marginTop: '8px',
  },
}));

const Login = () => {
  const classes = useStyles();
  const history = useHistory();

  // Remember me logic
  let rememberMe = localStorage.getItem('rememberMe');
  let userEmail = '';
  let userPassword = '';

  if (rememberMe === 'true') {
    const data = decryptData(localStorage.getItem('loginData'));
    if (data && data.length > 0) {
      userEmail = data[0].savedEmail.toString();
      userPassword = data[1].savedPassword.toString();
    }

    rememberMe = true;
  } else rememberMe = false;

  const [email, setEmail] = useState(userEmail);
  const [password, setPassword] = useState(userPassword);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(rememberMe);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = () => {
    const body = { email: email, password: password };
    setIsLoading(true);
    APIHelper.prototype
      .fetchData(`/auth/login`, 'POST', body, true)
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          throw new Error(response.error);
        }
        if (checked) {
          localStorage.setItem('loginData', encryptData(email, password));
          localStorage.setItem('rememberMe', checked);
        } else {
          localStorage.setItem('rememberMe', checked);
          localStorage.removeItem('email');
          localStorage.removeItem('password');
        }
        const lastLocation = localStorage.getItem('lastLocation');
        lastLocation === '/login'
          ? history.push('/')
          : history.push(lastLocation);
        history.go();
      })
      .catch((err) => {
        setIsLoading(false);
        if (Object.values(LOGIN_ERRORS).includes(err.message)) {
          setErrorMessage(
            get(settings, `loginPage.${err.message}`, {
              default: '',
            })
          );
        } else {
          setErrorMessage(
            get(settings, `loginPage.serverError`, {
              default: '',
            })
          );
        }
      });
  };

  // login when Enter was pressed
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleSpinner = isLoading ? (
    <div className={classes.spinner}>
      {get(settings, 'loginPage.login', {
        default: 'Login',
      })}
      <CircularProgress size={10} className={classes.circular} />
    </div>
  ) : (
    get(settings, 'loginPage.login', {
      default: 'Login',
    })
  );

  return (
    <div className={classes.main}>
      <div className={classes.paper}>
        <div>
          <div className={classes.paperDiv}>
            <Typography variant='h5'>
              {get(settings, 'loginPage.authentification', {
                default: 'Authentification',
              })}
            </Typography>
            <img
              src='/images/timekey-logo-purple.png'
              alt='Logo'
              className={classes.img}
            />
          </div>

          <Grid
            container
            direction='column'
            justify='flex-start'
            alignItems='flex-start'
          >
            <Typography variant='subtitle2' className={classes.text}>
              {get(settings, 'loginPage.email', {
                default: 'Email address',
              })}
            </Typography>

            <TextField
              className={classes.margin}
              disabled={isLoading}
              value={email}
              error={errorMessage !== ''}
              variant='outlined'
              onChange={(event) => setEmail(event.target.value)}
              onKeyUp={handleKeyPress}
              className={classes.width}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' className={classes.input}>
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            container
            direction='column'
            justify='flex-start'
            alignItems='flex-start'
          >
            <Typography variant='subtitle2' className={classes.text}>
              {get(settings, 'loginPage.password', {
                default: 'Password',
              })}
            </Typography>
            <TextField
              id='passInput'
              disabled={isLoading}
              className={classes.margin}
              type={showPassword ? 'text' : 'password'}
              value={password}
              error={errorMessage !== ''}
              className={classes.width}
              helperText={errorMessage !== '' && errorMessage}
              variant='outlined'
              onChange={(event) => setPassword(event.target.value)}
              onKeyUp={handleKeyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' variant='standard'>
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position='end'
                    className={classes.customAdornment}
                  >
                    <Tooltip
                      title={get(settings, 'loginPage.passwordTooltip', {
                        default: 'Show password',
                      })}
                    >
                      <IconButton
                        className={classes.iconStyle}
                        onClick={() =>
                          setShowPassword((prevShowPassword) => {
                            return !prevShowPassword;
                          })
                        }
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <div className={classes.paperButton}>
            <FormControlLabel
              control={
                <Checkbox
                  name='checked'
                  onChange={handleChecked}
                  checked={checked}
                />
              }
              label={get(settings, 'loginPage.rememberMe', {
                default: 'Remember me',
              })}
            />
            <Button
              variant='contained'
              color='primary'
              onClick={handleLogin}
              disabled={isLoading}
            >
              {handleSpinner}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
