import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import get from 'get-value';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

// importing Material UI elements
import {
  Grid,
  InputAdornment,
  IconButton,
  Switch,
  FormControlLabel,
} from '@material-ui/core';

// importing Material UI icons
import { Visibility, VisibilityOff } from '@material-ui/icons';

// importing internal components
import TextField from '../../../../components/forms/text-field';
import DialogLayout from '../../../../components/dialogs/dialog-layout';
import { getLanguageSupport } from '../../../../utils/helper';
import { checkSSN } from '../../../../helpers/companies-helper';

// importing constants
import { DATE_FORMAT } from '../../../../helpers/date-helper';
import {
  EMPLOYEE_FIELDS,
  VALID_EMAIL,
  VALID_MOBILE_NUMBER,
} from '../../../../utils/form-constants';
import {
  STATUS_TYPE,
  EMPLOYMENT_TYPE,
} from '../../../../helpers/employee-helper';

// Query:
const readAllEmployeeEmailsQuery = gql`
  query {
    readAllEmployeeEmails
  }
`;

const readEmployeeQuery = gql`
  query Employee($id: ID!) {
    readEmployee(id: $id) {
      id
      firstName
      lastName
      birthDate
      mobile
      status
      positionName
      contractType
      employmentType
      userData {
        email
        isAdmin
      }
      employmentData {
        ssn
        identityCard {
          series
          number
        }
        vacationDays
        takenVacationDays
        dateOfEmployment
      }
    }
  }
`;

// Mutation queries:
const createEmployeeMutation = gql`
  mutation CreateEmployee(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $birthDate: DateTime
    $mobile: String
    $idCardSeries: String!
    $idCardNumber: String!
    $ssn: String!
    $vacationDays: Int!
    $takenVacationDays: Int!
    $dateOfEmployment: DateTime!
    $isAdmin: Boolean!
    $employmentType: String!
    $positionName: String!
    $contractType: Int!
  ) {
    createEmployee(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      birthDate: $birthDate
      mobile: $mobile
      idCardSeries: $idCardSeries
      idCardNumber: $idCardNumber
      ssn: $ssn
      vacationDays: $vacationDays
      takenVacationDays: $takenVacationDays
      dateOfEmployment: $dateOfEmployment
      isAdmin: $isAdmin
      employmentType: $employmentType
      positionName: $positionName
      contractType: $contractType
    ) {
      id
    }
  }
`;

const updateEmployeeMutation = gql`
  mutation (
    $id: ID!
    $firstName: String
    $lastName: String
    $birthDate: DateTime
    $mobile: String
    $idCardSeries: String
    $idCardNumber: String
    $ssn: String
    $vacationDays: Int
    $takenVacationDays: Int
    $dateOfEmployment: DateTime
    $isAdmin: Boolean
    $employmentType: String
    $positionName: String
    $contractType: Int
    $status: String
  ) {
    updateEmployee(
      id: $id
      firstName: $firstName
      lastName: $lastName
      birthDate: $birthDate
      mobile: $mobile
      idCardSeries: $idCardSeries
      idCardNumber: $idCardNumber
      ssn: $ssn
      vacationDays: $vacationDays
      takenVacationDays: $takenVacationDays
      dateOfEmployment: $dateOfEmployment
      isAdmin: $isAdmin
      employmentType: $employmentType
      positionName: $positionName
      contractType: $contractType
      status: $status
    )
  }
`;

const addEmployeesToCompanyMutation = gql`
  mutation ($companyId: ID!, $employeeIds: [ID]!) {
    addEmployeesToCompany(companyId: $companyId, employeeIds: $employeeIds)
  }
`;

const createEmployeePasswordMutation = gql`
  mutation ($id: ID!, $newPassword: String!) {
    createEmployeePassword(id: $id, newPassword: $newPassword)
  }
`;

// TODO: implement global language support
const language = getLanguageSupport();

const PREFIX = 'employees.formDialog.';

const t = (key, defaultValue, prefix = true) => {
  return get(language, `${prefix ? PREFIX : ''}${key}`, {
    default: defaultValue,
  });
};

// Validation helpers
const validateUserEmail = (email, employeeEmails) => {
  if (employeeEmails.includes(email)) {
    // TODO: Move this check on backend
    return t(
      'helperText.alreadyExistEmail',
      'User with this e-mail address already exists!'
    );
  }

  const isValidEmail = VALID_EMAIL.test(email);
  return !isValidEmail
    ? t('helperText.incorrectEmailFormat', 'Invalid e-mail format!')
    : '';
};

const validateMobileNumber = (mobileNumber) => {
  const isValidMobileNumber = VALID_MOBILE_NUMBER.test(mobileNumber);
  return !isValidMobileNumber
    ? t('helperText.incorrectMobileFormat', 'Invalid mobile number format!')
    : '';
};

const validateUserPassword = (confirmPassword, password) => {
  return password !== confirmPassword
    ? t('helperText.passwordNotMatch', "Passwords don't match!")
    : '';
};

const FORM_LABELS = {
  add: {
    dialogTitle: t('createTitle', 'Add employee'),
    submitButtonLabel: t('formDialog.submitButtonLabel', 'Submit', false),
  },
  edit: {
    dialogTitle: t('editTitle', 'Edit employee'),
    submitButtonLabel: t('formDialog.modifyButtonLabel', 'Modify', false),
  },
};

const REQUIRED = t('formDialog.requiredInput', 'Required!', false);

const INCORRECT_FORMAT = t(
  'formDialog.incorrectFormatInput',
  'Incorrect format!',
  false
);

const EmployeesForm = (props) => {
  const { open, companyId, handleSubmitForm, handleClose, focusedEmployee } =
    props;

  const formType = focusedEmployee ? 'edit' : 'add';

  const [formInputs, setFormInputs] = useState({
    ...EMPLOYEE_FIELDS,
    vacationDays: 21,
    takenVacationDays: 0,
    dateOfEmployment: moment().format(DATE_FORMAT),
  });

  const [formInputErrors, setFormInputErrors] = useState({
    ...EMPLOYEE_FIELDS,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [createdPassword, setCreatedPassword] = useState('');
  const [showCreatedPassword, setShowCreatedPassword] = useState(false);

  const [employeeEmails, setEmployeeEmails] = useState([]);

  const [dataLoading, setDataLoading] = useState(false);

  const { dialogTitle, submitButtonLabel } = FORM_LABELS[formType];

  // Clear dialog when needed
  const resetDialog = (message) => {
    setFormInputs({
      ...EMPLOYEE_FIELDS,
      vacationDays: 21,
      takenVacationDays: 0,
      dateOfEmployment: moment().format(DATE_FORMAT),
    });
    setFormInputErrors(EMPLOYEE_FIELDS);
    setShowPassword(false);
    setShowConfirmPassword(false);
    setCreatedPassword('');

    if (message) {
      handleSubmitForm(message);
    }
    handleClose();
  };

  // Apollo Queries
  // Extract all employee emails
  const [readEmployeesEmails] = useLazyQuery(readAllEmployeeEmailsQuery, {
    onCompleted: (data) => {
      const emails = data?.readAllEmployeeEmails || [];
      setEmployeeEmails(emails);
      setDataLoading(false);
    },
  });

  // Bring data for edit employee
  const [readEmployee] = useLazyQuery(readEmployeeQuery, {
    onCompleted: (data) => {
      if (data?.readEmployee) {
        const { userData, birthDate, employmentData, ...employee } =
          data.readEmployee;

        const { isAdmin, email } = userData;

        const convertedBirthDate = birthDate
          ? moment(birthDate).format(DATE_FORMAT)
          : '';

        const {
          identityCard,
          ssn,
          vacationDays,
          takenVacationDays,
          dateOfEmployment,
        } = employmentData;

        const { series: idCardSeries, number: idCardNumber } = identityCard;

        const updatedInputs = {
          birthDate: convertedBirthDate,
          isAdmin,
          email,
          ssn,
          idCardSeries,
          idCardNumber,
          vacationDays,
          takenVacationDays,
          dateOfEmployment: moment(dateOfEmployment).format(DATE_FORMAT),
          ...employee,
        };

        Object.entries(EMPLOYEE_FIELDS).forEach(([fieldKey, fieldValue]) => {
          if (!isNil(updatedInputs[fieldKey])) return;

          updatedInputs[fieldKey] = fieldValue;
        });

        setFormInputs(updatedInputs);
        setDataLoading(false);
      }
    },
  });

  // If focusedEmployee is valid make the call for query to bring data of the member when the dialog is open
  useEffect(() => {
    if (open) {
      setDataLoading(true);
      if (focusedEmployee) {
        readEmployee({
          variables: { id: focusedEmployee },
        });
      } else {
        readEmployeesEmails();
      }
    }
  }, [open]);

  // Apollo Mutations
  const [addEmployeeToCompany, {}] = useMutation(
    addEmployeesToCompanyMutation,
    {
      onCompleted: () => {
        resetDialog('successfullyCreatedEmployeeMessage');
      },
    }
  );

  const [updateEmployeePassword, {}] = useMutation(
    createEmployeePasswordMutation
  );

  const [createEmployee, {}] = useMutation(createEmployeeMutation, {
    onCompleted: (res) => {
      addEmployeeToCompany({
        variables: {
          companyId: companyId,
          employeeIds: res.createEmployee.id,
        },
      });
    },
  });

  const handleCreateEmployee = async () => {
    const {
      confirmPassword,
      birthDate,
      dateOfEmployment,
      vacationDays,
      takenVacationDays,
      contractType,
      ...employee
    } = formInputs;

    await createEmployee({
      variables: {
        ...employee,
        vacationDays: parseInt(vacationDays),
        takenVacationDays: parseInt(takenVacationDays),
        birthDate: moment.utc(birthDate),
        dateOfEmployment: moment.utc(dateOfEmployment),
        contractType: parseInt(contractType),
      },
    });
  };

  const [updateEmployee, {}] = useMutation(updateEmployeeMutation, {
    onCompleted: () => {
      resetDialog('successfullyUpdatedEmployeeMessage');
    },
  });

  const handleUpdateEmployee = async () => {
    const {
      birthDate,
      email,
      dateOfEmployment,
      vacationDays,
      takenVacationDays,
      contractType,
      ...employee
    } = formInputs;

    await updateEmployee({
      variables: {
        ...employee,
        id: focusedEmployee,
        vacationDays: parseInt(vacationDays),
        takenVacationDays: parseInt(takenVacationDays),
        birthDate: moment.utc(birthDate),
        dateOfEmployment: moment.utc(dateOfEmployment),
        contractType: parseInt(contractType),
      },
    });
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;

    let newValue;

    switch (name) {
      case 'isAdmin':
        newValue = checked;
        break;
      case 'employmentType':
        newValue = checked ? EMPLOYMENT_TYPE.PFA : EMPLOYMENT_TYPE.CONTRACT;
        break;
      case 'status':
        newValue = checked ? STATUS_TYPE.ENABLED : STATUS_TYPE.DISABLED;
        break;
      default:
        newValue = value;
        break;
    }

    // Reset error if any
    if (name in formInputErrors && formInputErrors[name] !== '')
      setFormInputErrors({ ...formInputErrors, [name]: '' });

    setFormInputs({ ...formInputs, [name]: newValue });
  };

  const checkFormIncomplete = () => {
    let errors = {};

    const addFieldError = (fieldName, errorMessage) => {
      if (errorMessage) errors[fieldName] = errorMessage;
    };

    Object.entries(formInputs).forEach(([name, value]) => {
      if (!isNil(value) && value !== '') {
        // Check value integrity when value is not null/undefined or empty

        switch (name) {
          case 'mobile': // Check if mobile format is correct
            addFieldError(name, validateMobileNumber(value));
            break;
          case 'idCardSeries': // Check if idCardSeries length is correct
            if (value.length !== 2) {
              addFieldError(name, INCORRECT_FORMAT);
            }
            break;
          case 'idCardNumber': // Check if idCardNumber length is correct
            if (value.length !== 6) {
              addFieldError(name, INCORRECT_FORMAT);
            }
            break;
          case 'ssn': // Check if SSN is correct
            addFieldError(name, checkSSN(value, language));
            break;
          default:
            break;
        }

        if (!focusedEmployee) {
          // Checks ONLY when creating a new employee

          switch (name) {
            case 'email': // Check if user e-mail address already exists
              addFieldError(name, validateUserEmail(value, employeeEmails));
              break;
            case 'confirmPassword':
              addFieldError(
                name,
                validateUserPassword(value, formInputs.password)
              );
              break;
            default:
              break;
          }
        }
      } else {
        const optionalField = [
          'birthDate',
          'mobile',
          'isAdmin',
          'employmentType',
        ];

        if (focusedEmployee) {
          optionalField.push('password', 'confirmPassword');
        }

        if (!optionalField.includes(name)) {
          errors[name] = REQUIRED;
        }
      }
    });

    setFormInputErrors(errors);

    return !isEmpty(errors);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formIncomplete = checkFormIncomplete();

    if (!formIncomplete) {
      !focusedEmployee ? handleCreateEmployee() : handleUpdateEmployee();
      if (createdPassword.length !== 0) {
        generateNewPass();
      }
      handleClose();
    }
  };

  const generateNewPass = () => {
    updateEmployeePassword({
      variables: {
        id: focusedEmployee,
        newPassword: createdPassword,
      },
    });
  };

  // Clear field and close the dialog
  const handleCloseForm = (event) => {
    event.preventDefault();
    resetDialog();
  };

  const isActive = formInputs.status === 'enabled';
  const isCollaborator = formInputs.employmentType === EMPLOYMENT_TYPE.PFA;

  const statusMessage = isActive
    ? t('companyEmployee.enabled', 'Enabled', false)
    : t('companyEmployee.disabled', 'Disabled', false);

  return (
    <DialogLayout
      isLoading={dataLoading}
      formTitle={dialogTitle}
      open={open}
      formSubmitButton={submitButtonLabel}
      formCancelButton={t('formDialog.cancelButtonLabel', 'Cancel', false)}
      handleSubmit={handleSubmit}
      handleClose={handleCloseForm}
    >
      <Grid container spacing={2}>
        <TextField
          name="firstName"
          value={formInputs.firstName}
          label={t('fieldsLabel.firstName', 'First name')}
          error={formInputErrors.firstName}
          handleChange={handleChange}
        />
        <TextField
          name="lastName"
          value={formInputs.lastName}
          label={t('fieldsLabel.lastName', 'Last name')}
          error={formInputErrors.lastName}
          handleChange={handleChange}
        />
        <TextField
          length={12}
          name="email"
          value={formInputs.email}
          label={t('fieldsLabel.email', 'E-mail address')}
          error={formInputErrors.email}
          handleChange={handleChange}
          disabled={!!focusedEmployee}
        />
        {!focusedEmployee && (
          <>
            <TextField
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={formInputs.password}
              label={t('fieldsLabel.password', 'Password')}
              error={formInputErrors.password}
              handleChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      tabIndex="-1"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type={showConfirmPassword ? 'text' : 'password'}
              name="confirmPassword"
              value={formInputs.confirmPassword}
              label={t('fieldsLabel.confirmPassword', 'Confirm password')}
              error={formInputErrors.confirmPassword}
              handleChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      tabIndex="-1"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        <TextField
          type="date"
          name="birthDate"
          required={false}
          value={formInputs.birthDate}
          label={t('fieldsLabel.birthDate', 'Birth date')}
          error={formInputErrors.birthDate}
          handleChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          name="mobile"
          required={false}
          value={formInputs.mobile}
          label={t('fieldsLabel.mobile', 'Mobile phone')}
          error={formInputErrors.mobile}
          handleChange={handleChange}
        />

        <TextField
          length={2}
          name="idCardSeries"
          required
          value={formInputs.idCardSeries}
          label={t('fieldsLabel.idCardSeries', 'IdCard Series')}
          error={formInputErrors.idCardSeries}
          handleChange={handleChange}
        />
        <TextField
          length={4}
          name="idCardNumber"
          required
          value={formInputs.idCardNumber}
          label={t('fieldsLabel.idCardNumber', 'IdCard Number')}
          error={formInputErrors.idCardNumber}
          handleChange={handleChange}
        />
        <TextField
          name="ssn"
          required
          value={formInputs.ssn}
          label={t('fieldsLabel.ssn', 'SSN')}
          error={formInputErrors.ssn}
          handleChange={handleChange}
        />
        <TextField
          type="number"
          length={4}
          name="vacationDays"
          required
          value={formInputs.vacationDays}
          label={t('fieldsLabel.vacationDays', 'vacationDays')}
          error={formInputErrors.vacationDays}
          handleChange={handleChange}
          inputProps={{ min: 0, max: 100 }}
        />
        <TextField
          type="number"
          length={4}
          name="takenVacationDays"
          required
          value={formInputs.takenVacationDays}
          label={t('fieldsLabel.takenVacationDays', 'takenVacationDays')}
          error={formInputErrors.takenVacationDays}
          handleChange={handleChange}
          inputProps={{ min: 0, max: 100 }}
        />
        <TextField
          type="date"
          length={4}
          name="dateOfEmployment"
          required
          value={formInputs.dateOfEmployment}
          label={t('fieldsLabel.dateOfEmployment', 'dateOfEmployment')}
          error={formInputErrors.dateOfEmployment}
          handleChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          name="contractType"
          type="number"
          length={4}
          required
          value={formInputs.contractType}
          label={t('fieldsLabel.contractType', 'Contract Type')}
          error={formInputErrors.contractType}
          handleChange={handleChange}
          inputProps={{ min: 4, max: 8 }}
        />
        <TextField
          name="positionName"
          required
          value={formInputs.positionName}
          label={t('fieldsLabel.positionName', 'Position name')}
          error={formInputErrors.positionName}
          handleChange={handleChange}
        />
        {focusedEmployee && (
          <TextField
            type={showCreatedPassword ? 'text' : 'password'}
            name="password"
            value={createdPassword}
            label={t('fieldsLabel.generatePassword', 'generatePass')}
            required={false}
            handleChange={(event) => setCreatedPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowCreatedPassword(!showCreatedPassword)}
                    tabIndex="-1"
                  >
                    {showCreatedPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        <Grid container>
          <Grid item sm={4} xs>
            <FormControlLabel
              control={
                <Switch
                  checked={formInputs.isAdmin}
                  onChange={handleChange}
                  name="isAdmin"
                />
              }
              label={t('fieldsLabel.isAdmin', 'Admin')}
            />
          </Grid>
          <Grid item sm={4} xs>
            <FormControlLabel
              control={
                <Switch
                  checked={isCollaborator}
                  onChange={handleChange}
                  name="employmentType"
                />
              }
              label={t('fieldsLabel.isCollaborator', 'Collaborator')}
            />
          </Grid>
          {focusedEmployee && (
            <Grid item sm={4} xs>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActive}
                    onChange={handleChange}
                    name="status"
                  />
                }
                label={statusMessage}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </DialogLayout>
  );
};

export default EmployeesForm;
