import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import get from 'get-value';

// importing material UI elements
import {
  Avatar,
  ListItem,
  Link,
  makeStyles,
  ListItemText,
  ListItemAvatar,
  Typography,
} from '@material-ui/core';

// import material UI icons
import { Delete, PersonAdd } from '@material-ui/icons';

// import components
import { PAGE_SIZE, SNACKBAR_SEVERITY } from '../../../utils/constants';

// importing components
import Toolbar from '../../../core/toolbar';
import ToolbarFilter from '../../../core/toolbar-filter';
import ListContainer from '../../../core/list-container';
import ConfirmForm from '../../../components/dialogs/confirm-form';
import MembersForm from '../components/dialogs/members-form';
import { getMemberType } from '../../../helpers/employee-helper';
import { useStore } from '../../../stores';
import withSnackbar from '../../../utils/with-snackbar';

const useStyles = makeStyles(() => {
  return {
    addButton: {
      margin: '5px',
      color: '#ffffff',
      backgroundColor: '#3f51b5',
    },
    removeButton: {
      margin: '5px',
    },
  };
});

// Queries:
const readTeamEmployeesQuery = gql`
  query(
    $teamId: ID!
    $offset: Int!
    $limit: Int!
    $filter: String
    $employeeID: ID!
  ) {
    readIsTeamLeaderInTeam(teamID: $teamId, employeeID: $employeeID)
    countTeamEmployees(teamId: $teamId, filter: $filter)
    readTeamEmployees(
      teamId: $teamId
      offset: $offset
      limit: $limit
      filter: $filter
    ) {
      members {
        member {
          _id
          fullName
          initials
          userData {
            email
          }
        }
        memberType
      }
    }
  }
`;

const readTeamQuery = gql`
  query($id: ID!) {
    readTeam(id: $id) {
      teamName
    }
  }
`;

// Mutations:
const removeEmployeesFromTeamMutation = gql`
  mutation($teamId: ID!, $employeeIds: [ID]!) {
    removeEmployeesFromTeam(teamId: $teamId, employeeIds: $employeeIds)
  }
`;

const Members = (props) => {
  const { settings } = props;
  const classes = useStyles();
  const teamId = useParams().id;
  const store = useStore();
  const { employeeID, isAdmin } = store.employeeData;

  const [isTeamLeader, setIsTeamLeader] = useState(false);

  // State for confirm-dialog
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // State for form-dialog
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [selection, setSelection] = useState([]);
  const [formInputs, setFormInputs] = useState({});
  const [focusedEmployeeId, setFocusedEmployeeId] = useState(null);

  // State for toolbar filter
  const [filterValue, setFilterValue] = useState('');
  const handleFilterChange = (value) => {
    setFilterValue(value);
  };

  // State for title
  const [teamName, setTeamName] = useState('');

  // DataGrid Pagination
  const [gridPage, setGridPage] = useState(1);
  const handlePageChange = async (params) => {
    setGridPage(params.page);
  };

  useEffect(() => {
    if (teamId)
      readTeam({
        variables: { id: teamId },
      });
    return () => {
      setTeamName('');
    };
  }, []);

  // Query functions:
  const { data, loading, refetch } = useQuery(readTeamEmployeesQuery, {
    variables: {
      teamId: teamId,
      offset: (gridPage - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      filter: filterValue,
      employeeID: employeeID,
    },
    skip: !employeeID,
    onCompleted: (res) => {
      isAdmin
        ? setIsTeamLeader(true)
        : setIsTeamLeader(res.readIsTeamLeaderInTeam);
    },
  });

  const [readTeam, {}] = useLazyQuery(readTeamQuery, {
    onCompleted: (res) => {
      setTeamName(res.readTeam.teamName);
    },
  });

  // Mutation functions:
  const [removeEmployeesFromTeam, {}] = useMutation(
    removeEmployeesFromTeamMutation,
    {
      onCompleted: async () => {
        handleSubmitForm('successfullyRemovedEmployeesFromTeam');
        setSelection([]);
      },
    }
  );

  const handleClickRemoveButton = () => {
    if (selection.length !== 0) {
      setOpenConfirmDialog(true);
    } else {
      props.showSnackbar(
        get(settings, 'teams.snackbar.firstSelectEmployeesToRemoveMessage', {
          default: 'Warning',
        }),
        SNACKBAR_SEVERITY.WARNING
      );
    }
  };
  const handleRemoveEmployees = () => {
    removeEmployeesFromTeam({
      variables: { teamId: teamId, employeeIds: selection },
    });
  };

  const handleCloseDialog = () => {
    setOpenFormDialog(false);
    setFormInputs({});
    setFocusedEmployeeId(null);
  };

  const handleAddEmployeesButton = () => {
    setOpenFormDialog(true);
  };

  const handleRowClick = async (id) => {
    setOpenFormDialog(true);
    setFocusedEmployeeId(id);
  };

  const handleSubmitForm = async (message) => {
    setFocusedEmployeeId(null);
    setOpenConfirmDialog(false);
    await refetch();
    props.showSnackbar(
      get(settings, `teams.snackbar.${message}`, { default: 'Success' }),
      SNACKBAR_SEVERITY.SUCCESS
    );
  };

  // Stuff for toolbar
  const toolbarButtons = !data
    ? []
    : [
        {
          name: get(settings, 'teams.team.addMembersButton', {
            default: 'Add members',
          }),
          style: classes.addButton,
          icon: <PersonAdd />,
          handleAction: handleAddEmployeesButton,
          disabled: !isTeamLeader,
        },
        {
          name: get(settings, 'teams.team.removeMembersButton', {
            default: 'Remove members',
          }),
          style: classes.removeButton,
          icon: <Delete />,
          handleAction: handleClickRemoveButton,
          disabled: !isTeamLeader,
        },
      ];

  // Define DataGrid columns
  const columns = [
    {
      field: 'fullName',
      headerName: get(settings, 'teams.team.fullName', {
        default: '',
      }),
      width: 350,
      renderCell: (params) => {
        return (
          <ListItem>
            <ListItemAvatar>
              <Avatar>{params.row.initials}</Avatar>
            </ListItemAvatar>
            {isTeamLeader ? (
              <Link onClick={() => handleRowClick(params.row.id)}>
                <ListItemText
                  primary={params.row.fullName}
                  secondary={params.row.userData.email}
                />
              </Link>
            ) : (
              <ListItemText
                primary={params.row.fullName}
                secondary={params.row.userData.email}
              />
            )}
          </ListItem>
        );
      },
    },
    {
      field: 'memberType',
      headerName: get(settings, 'teams.team.memberType', {
        default: 'Member type',
      }),
      width: 150,
      valueFormatter: ({ value }) =>
        get(
          settings,
          `teams.team.memberTypeSelectLabel.${getMemberType(value)}`,
          {
            default: getMemberType(value),
          }
        ),
    },
  ];

  const newEmployeeData = data?.readTeamEmployees?.map((row) => ({
    ...row.members.member,
    memberType: row.members.memberType,
    id: row.members.member._id,
  }));

  return (
    <div className={'sectionContainer'}>
      <Toolbar
        backwardLink='/teams'
        title={`${get(settings, 'teams.team.toolbarTitle', {
          default: 'Employees',
        })} - ${teamName}
        `}
        buttons={toolbarButtons}
      >
        <ToolbarFilter
          settings={get(settings, 'toolbarFilter', {
            default: { filterLabel: 'Filter' },
          })}
          handleFilterChange={handleFilterChange}
        />
      </Toolbar>

      {data && (
        <ListContainer
          items={newEmployeeData}
          columns={columns}
          loading={loading}
          gridPage={gridPage}
          rowCount={data.countTeamEmployees}
          onPageChange={handlePageChange}
          setSelection={setSelection}
        />
      )}

      <MembersForm
        open={openFormDialog}
        handleSubmitForm={handleSubmitForm}
        settings={settings}
        teamId={teamId}
        focusedEmployee={focusedEmployeeId}
        handleClose={handleCloseDialog}
      />

      <ConfirmForm
        settings={settings}
        title={`${get(settings, 'confirmDialog.dialogTitleRemove', {
          default: '',
        })} ${get(settings, 'teams.team.confirmDialog.title', {
          default: 'members from team',
        })}`}
        open={openConfirmDialog}
        handleClose={() => setOpenConfirmDialog(false)}
        handleSubmit={handleRemoveEmployees}
      >
        <Typography>
          {get(settings, 'teams.team.confirmDialog.confirmDialogMessage', {
            default: 'Do you want to delete members from team?',
          })}
        </Typography>
      </ConfirmForm>
    </div>
  );
};

export default withSnackbar(Members);
