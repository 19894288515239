import React from 'react';
import get from 'get-value';

// importing Material UI elements
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getLanguageSupport } from '../../../utils/helper';

const settings = getLanguageSupport();

const AutocompleteField = (props) => {
  const {
    filterData,
    handleChange,
    filterElements,
    filterPlaceHolder,
    filterSelectedOptions = true,
    limitTags = 2,
    ChipProps = {},
    optionElement = 'elementName',
    multiple = false,
    fieldVariant = 'standard',
    fieldMargin = 'none',
    disabled = false,
    teamAutocomplete = false,
    focusedEmployee = null,
  } = props;

  const getOptionSelectedTeams = (option, value) => {
    return focusedEmployee ? true : option.value === value.value;
  };

  const getOptionSelected = (option, value) => {
    return option.id === value.id;
  };

  return (
    <>
      <Autocomplete
        autoHighlight
        disabled={disabled}
        multiple={multiple}
        limitTags={limitTags}
        id='tags'
        options={filterData}
        getOptionLabel={(option) => option[optionElement] || ''}
        value={filterElements}
        onChange={handleChange}
        ChipProps={ChipProps}
        filterSelectedOptions={filterSelectedOptions}
        getOptionSelected={(option, value) =>
          teamAutocomplete
            ? getOptionSelectedTeams(option, value)
            : getOptionSelected(option, value)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            margin={fieldMargin}
            variant={fieldVariant}
            label={filterPlaceHolder}
            placeholder={filterPlaceHolder}
          />
        )}
        noOptionsText={get(
          settings,
          'companies.formDialog.noOptionsTextLabel',
          {
            default: 'No options!',
          }
        )}
      />
    </>
  );
};

export default AutocompleteField;
