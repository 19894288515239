import "rc-time-picker/assets/index.css";

import React, { useState } from "react";

import moment from "moment";

import TimePicker from "rc-time-picker";

const TimePickerDate = (props) => {
  const { value, onChange, disabled, index, name } = props;

  let fieldValue = null;

  if (value) {
    const [currentHour, currentMinute] = value ? value.split(":") : "";
    fieldValue = moment().hour(currentHour).minute(currentMinute);
  }

  const [currentValue, setCurrentValue] = useState(fieldValue);

  const updateValue = () => {
    onChange(index, currentValue, name);
  };

  const setTime = (newValue) => {
    setCurrentValue(newValue);
  };

  return (
    <TimePicker
      disabled={disabled}
      showSecond={false}
      value={currentValue}
      popupStyle={{ zIndex: 5000 }}
      minuteStep={5}
      onClose={updateValue}
      onChange={(newValue) => setTime(newValue)}
      clearIcon={false}
      allowEmpty={false}
    />
  );
};

export default TimePickerDate;
