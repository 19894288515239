import React, { useState } from 'react';
import get from 'get-value';
import startCase from 'lodash/startCase';
import { Card, makeStyles, Typography } from '@material-ui/core';
import { gql, useMutation } from '@apollo/client';

import WorkingScheduleRow from './working-schedule-row';
import WorkingScheduleDialog from './dialogs/working-schedule-dialog';

const WORKING_DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

//* Mutations
const updateEmployeeWorkingSchedule = gql`
  mutation ($employeeID: ID!, $workingSchedule: WorkingSchedules) {
    updateEmployeeSchedule(
      employeeID: $employeeID
      workingSchedule: $workingSchedule
    )
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    padding: '10px',
    marginBottom: 'auto',
    marginTop: '2em',
    marginLeft: '10px',
    borderRadius: '5px',
    [theme.breakpoints.only('md')]: {
      width: 'calc(50% - 10px)',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 10px)',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
      width: '100%',
    },
  },
  title: {
    fontSize: '22px',
    fontWeight: 'bold',
    marginBottom: '10px',
    padding: '35px',
  },
}));

const WorkingSchedule = ({ settings }) => {
  const classes = useStyles();

  const [selectedDay, setSelectedDay] = useState(null);

  //*Mutations:
  const [updateWorkingSchedule, {}] = useMutation(
    updateEmployeeWorkingSchedule
  );

  return (
    <>
      <Card className={classes.root}>
        <Typography className={classes.title}>
          {get(settings, `accountPage.account.workingSchedule`, {
            default: 'Working schedule',
          })}
        </Typography>

        {WORKING_DAYS.map((day) => {
          const translatedDay = get(settings, `accountPage.account.${day}`, {
            default: startCase(day),
          });

          return (
            <WorkingScheduleRow
              key={day}
              name={translatedDay}
              onEdit={() => setSelectedDay({ day, name: translatedDay })}
              currentDay={day}
            />
          );
        })}
      </Card>

      <WorkingScheduleDialog
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        settings={settings}
        updateWorkingSchedule={updateWorkingSchedule}
      />
    </>
  );
};

export default WorkingSchedule;
