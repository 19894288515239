import React from 'react';
import get from 'get-value';

// importing Material UI elements
import { Tooltip, IconButton, Link } from '@material-ui/core';

// importing Material UI icons
import { GetApp } from '@material-ui/icons';

// importing internal components
import ListContainer from '../../../core/list-container';

import APIHelper from '../../../helpers/api-helper';

// Queries :

const DownloadFilesTable = (props) => {
  const { settings, data, loading } = props;

  // Define DataGrid columns
  const columns = [
    {
      field: 'description',
      headerName: get(settings, 'documents.dataGridColumns.description', {
        default: 'Description',
      }),
      width: 330,
    },
    {
      field: 'type',
      headerName: get(settings, 'documents.dataGridColumns.documentType', {
        default: 'Type',
      }),
      width: 100,
      valueFormatter: ({ value }) =>
        get(settings, `registry.documentTypeSelectLabel.${value}`, {
          default: value,
        }),
    },
    {
      field: 'download',
      headerName: get(settings, 'documents.dataGridColumns.download', {
        default: 'Download',
      }),
      width: 120,
      align: 'center',
      renderCell: (params) => {
        return (
          <Tooltip
            title={get(settings, 'documents.dataGridColumns.download', {
              default: 'Download',
            })}
          >
            <Link
              href={`${APIHelper.baseUrl}/cdn/${params.row.uploadURL}`}
              target="_blank"
              rel="noreferrer"
            >
              <IconButton>
                <GetApp />
              </IconButton>
            </Link>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <ListContainer
        miniListContainer
        checkboxSelection={false}
        items={data.readDocumentsFromDocumentRequest}
        columns={columns}
        loading={loading}
      />
    </>
  );
};

export default DownloadFilesTable;
