import React, { useState, useEffect } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import get from 'get-value';

// importing material UI elements
import { Grid, Button, Input } from '@material-ui/core';

// importing internal components
import TextField from '../../../../components/forms/text-field';
import DialogLayout from '../../../../components/dialogs/dialog-layout';
import CircularUnderLoad from '../../../../components/loading-animation';
import withSnackbar from '../../../../utils/with-snackbar';

// importing constants
import { TEAM_FIELDS } from '../../../../utils/form-constants';
import { SNACKBAR_SEVERITY } from '../../../../utils/constants';
import APIHelper from '../../../../helpers/api-helper';

// Queries:
const readTeamQuery = gql`
  query ($id: ID!) {
    readTeam(id: $id) {
      id
      teamName
      description
      logo
    }
  }
`;

// Queries:
const readTeamsNamesQuery = gql`
  query readTeamsNames($excludedID: ID) {
    readTeamsNames(excludedID: $excludedID)
  }
`;

// Mutation:
const createTeamMutation = gql`
  mutation ($teamName: String!, $description: String!, $logo: String) {
    createTeam(teamName: $teamName, description: $description, logo: $logo)
  }
`;

const updateTeamMutation = gql`
  mutation (
    $id: ID!
    $teamName: String!
    $description: String!
    $logo: String
  ) {
    updateTeam(
      id: $id
      teamName: $teamName
      description: $description
      logo: $logo
    )
  }
`;

const TeamsForm = (props) => {
  const { settings, open, teamID, handleSubmitForm, handleCloseForm } = props;

  const [teamLogo, setTeamLogo] = useState(undefined);
  const [formInputs, setFormInputs] = useState(TEAM_FIELDS);
  const [formInputErrors, setFormInputErrors] = useState(TEAM_FIELDS);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [currentTeams, setCurrentTeams] = useState([]);

  const isCreateForm = !teamID;

  const [readTeam] = useLazyQuery(readTeamQuery, {
    onCompleted: (data) => {
      if (data && data.readTeam) {
        setFormInputs(data.readTeam);
        setIsDataLoading(false);
      } else {
        props.showSnackbar(
          get(settings, `formDialog.errorLoadingData`, {
            default: 'Error on loading data.',
          }),
          SNACKBAR_SEVERITY.ERROR
        );
      }
    },
  });

  const [readTeamsNames] = useLazyQuery(readTeamsNamesQuery, {
    onCompleted: (data) => {
      if (data && data.readTeamsNames) {
        setCurrentTeams(data.readTeamsNames);
        setIsDataLoading(false);
      } else {
        props.showSnackbar(
          get(settings, `formDialog.errorLoadingData`, {
            default: 'Error on loading data.',
          }),
          SNACKBAR_SEVERITY.ERROR
        );
      }
    },
  });

  useEffect(() => {
    if (teamID) {
      setIsDataLoading(true);
      readTeam({
        variables: {
          id: teamID,
        },
      });
      readTeamsNames({
        variables: {
          excludedID: teamID,
        },
      });
    } else {
      readTeamsNames();
      setIsDataLoading(false);
    }
  }, [open]);

  const uploadLogo = (path) => {
    const form = new FormData();
    form.append('myfile', teamLogo, path);
    APIHelper.prototype
      .fetchData(`/cdn/upload`, 'POST', form)
      .catch(console.error);
  };

  // Mutation function:
  const [createTeam, {}] = useMutation(createTeamMutation, {
    onCompleted: (team) => {
      if (teamLogo) uploadLogo(team.createTeam);
      handleClose();
      handleSubmitForm('successfullyCreatedMessage');
    },
  });
  const handleCreateTeam = () => {
    createTeam({ variables: formInputs });
  };

  const [updateTeam, {}] = useMutation(updateTeamMutation, {
    onCompleted: (team) => {
      if (teamLogo) {
        uploadLogo(team.updateTeam);
      }
      handleClose();
      handleSubmitForm('successfullyUpdatedMessage');
    },
  });
  const handleUpdateTeam = () => {
    const { id, logo, ...team } = formInputs;
    updateTeam({
      variables: { ...team, id: id, logo: teamLogo ? logo : undefined },
    });
  };

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    formInputErrors[name] !== '' &&
      setFormInputErrors({ ...formInputErrors, [name]: '' });
    if (files) {
      setTeamLogo(files[0]);
      const logoValue = value.split('\\').slice(-1)[0];
      setFormInputs({ ...formInputs, [name]: logoValue });
    } else {
      setFormInputs({ ...formInputs, [name]: value });
    }
  };

  const checkFormIncomplete = () => {
    let formIncomplete = false;

    const validateTeamName = (teamName) => {
      return currentTeams.includes(teamName)
        ? get(settings, 'teams.formDialog.alreadyExistTeam', {
            default: 'Team already exists!',
          })
        : '';
    };

    let formInputErrors = { ...TEAM_FIELDS };
    Object.entries(formInputs).map(([name, value]) => {
      // Check if company name already exist:
      if (name === 'teamName' && value) {
        const isInvalid = validateTeamName(value);
        if (isInvalid) formIncomplete = true;
        formInputErrors = { ...formInputErrors, [name]: isInvalid };
      }
      // Skip logo field:
      else if (name === 'logo') return;
      // Check if it has empty field(s):
      else if (!value) {
        formIncomplete = true;
        formInputErrors = {
          ...formInputErrors,
          [name]: get(settings, 'formDialog.requiredInput', {
            default: 'Required',
          }),
        };
      }
    });
    setFormInputErrors(formInputErrors);
    return formIncomplete;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formIncomplete = checkFormIncomplete();
    if (!formIncomplete) {
      isCreateForm ? handleCreateTeam() : handleUpdateTeam();
    }
  };

  const handleClose = () => {
    setFormInputs(TEAM_FIELDS);
    setFormInputErrors(TEAM_FIELDS);
    setTeamLogo(undefined);
    handleCloseForm();
  };

  return (
    <DialogLayout
      open={!!open}
      formTitle={
        isCreateForm
          ? get(settings, 'teams.formDialog.createTitle', {
              default: 'Add team',
            })
          : get(settings, 'teams.formDialog.editTitle', {
              default: 'Modify team',
            })
      }
      formSubmitButton={
        isCreateForm
          ? get(settings, 'formDialog.submitButtonLabel')
          : get(settings, 'formDialog.modifyButtonLabel')
      }
      formCancelButton={get(settings, 'formDialog.cancelButtonLabel')}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
    >
      {isDataLoading ? (
        <CircularUnderLoad />
      ) : (
        <Grid container spacing={2}>
          <TextField
            name="teamName"
            value={formInputs.teamName}
            label={get(settings, 'teams.formDialog.fieldsLabel.teamName', {
              default: "Team's name",
            })}
            length={12}
            error={formInputErrors['teamName']}
            handleChange={handleChange}
          />
          <TextField
            disabled
            value={formInputs.logo || ''}
            required={false}
            label={get(settings, 'teams.formDialog.fieldsLabel.logo', {
              default: "Team's logo",
            })}
            InputLabelProps={{ shrink: true }}
          />
          <Grid item sm={6} xs>
            <label htmlFor="contained-button-file">
              <Input
                style={{ display: 'none' }}
                accept="image/*"
                id="contained-button-file"
                type="file"
                name="logo"
                onChange={handleChange}
              />
              <Button variant="outlined" component="span">
                {get(settings, 'companies.formDialog.fieldsLabel.upload', {
                  default: 'Upload',
                })}
              </Button>
            </label>
          </Grid>

          <TextField
            length={12}
            name="description"
            value={formInputs.description}
            label={get(settings, 'teams.formDialog.fieldsLabel.description', {
              default: "Team's description",
            })}
            error={formInputErrors['description']}
            handleChange={handleChange}
          />
        </Grid>
      )}
    </DialogLayout>
  );
};

export default withSnackbar(TeamsForm);
