import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import get from 'get-value';
import moment from 'moment';

// importing Material UI elements
import {
  Grid,
  Button,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
  IconButton,
  Paper,
  makeStyles,
} from '@material-ui/core';

// importing Material UI icons
import { Clear, Add as AddIcon } from '@material-ui/icons';

// importing internal components
import TextField from '../../../components/forms/text-field';
import StatusTable from '../components/status-table';
import withSnackbar from '../../../utils/with-snackbar';
import Toolbar from '../../../core/toolbar';
import DownloadFilesTable from '../components/download-files-table';
import UploadDocumentsDialog from '../components/dialogs/upload-files';
import CircularUnderLoad from '../../../components/loading-animation';
import ConfirmForm from '../../../components/dialogs/confirm-form';
import { useStore } from '../../../stores';
import {
  calculateDays,
  getPendingStatus,
  FORM_FIELDS,
  completeEditFields,
  inputPropsRender,
  SOURCE_TYPES,
  enumerateForFreeDay,
} from '../../../helpers/document-helper';
import { getLanguageSupport } from '../../../utils/helper';

// importing constants
import {
  DATE_FORMAT,
  RO_DATE_FORMAT,
  RO_DATE_TIME_FORMAT,
  SINGLE_DATE_FORMAT,
} from '../../../helpers/date-helper';
import {
  SNACKBAR_SEVERITY,
  HISTORY_LOCATION,
  DOCUMENT_REQUEST_STATUS,
} from '../../../utils/constants';

//* Styles

const useStyles = makeStyles((theme) => ({
  paperLayout: {
    padding: '20px',
    height: 'auto',
    width: '50%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inline: {
    display: 'inline',
  },
  typography: {
    height: 'auto',
    width: '40%',
    margin: '0 auto',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textField: {
    width: '100%',
  },
  alignRightDiv: {
    direction: 'rtl',
  },
  button: {
    marginTop: '4px',
  },
  grid: {
    margin: '0 auto',
  },
  uploadDialog: {
    marginBottom: '1px',
    marginRight: '10px',
    padding: '0px',
  },
  iconButton: {
    padding: 0,
  },
  alignCenterDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  disableEdit: {
    marginTop: '2px',
  },
}));

//* Queries
const readDocumentRequestByID = gql`
  query readDocumentRequestByID($id: ID!) {
    readDocumentRequestByID(id: $id) {
      documentRequest {
        id
        date
        description
        documentVerification {
          destination {
            firstName
            lastName
            id
          }
          status
        }
        fields {
          fieldName
          fieldType
          fieldValue
          fieldSource
        }
        template {
          name
        }
        source {
          firstName
          lastName
        }
        comments {
          id
          date
          source {
            id
            firstName
            lastName
          }
          comment
        }
      }
      globalStatus
    }
  }
`;

const readDocumentsFromDocumentRequestQuery = gql`
  query ($id: ID!) {
    readDocumentsFromDocumentRequest(id: $id) {
      id
      type
      description
      uploadURL
    }
  }
`;

const readCommentsByID = gql`
  query readCommentsByID($id: ID!, $limit: Int) {
    readCommentsByID(id: $id, limit: $limit) {
      id
      date
      source {
        id
        firstName
        lastName
      }
      comment
    }
  }
`;

// Mutations:
const createCommentInDocumentRequestMutation = gql`
  mutation (
    $documentRequestId: ID!
    $date: DateTime!
    $source: ID!
    $comment: String!
  ) {
    createCommentInDocumentRequest(
      documentRequestId: $documentRequestId
      date: $date
      source: $source
      comment: $comment
    ) {
      id
    }
  }
`;

const removeCommentFromDocumentRequestMutation = gql`
  mutation ($documentRequestId: ID!, $commentId: ID!) {
    removeCommentFromDocumentRequest(
      documentRequestId: $documentRequestId
      commentId: $commentId
    )
  }
`;

const updateDocumentRequestMutation = gql`
  mutation updateDocumentRequest($id: ID!, $fields: [FieldInput]) {
    updateDocumentRequest(id: $id, fields: $fields)
  }
`;

const cancelRequestMutation = gql`
  mutation cancelDocumentRequest(
    $id: ID!
    $status: StatusType!
    $employeeID: ID!
  ) {
    cancelDocumentRequest(id: $id, status: $status, employeeID: $employeeID)
  }
`;

// Queries
const readAllFreeDaysForYearQuery = gql`
  query readAllFreeDaysForYear($year: String!) {
    readAllFreeDaysForYear(year: $year) {
      year
      days
    }
  }
`;

const language = getLanguageSupport();

const CURRENT_YEAR = moment().format(SINGLE_DATE_FORMAT.YEAR);

const COMMENT_BUTTON = {
  MORE: get(language, 'documents.formDialog.moreButton', {
    default: 'More',
  }),
  LESS: get(language, 'documents.formDialog.lessButton', {
    default: 'Less',
  }),
};

const DEFAULT_COMMENTS_LIMIT = 2;

const RequestDetails = (props) => {
  const documentRequestID = useParams().id;
  const location = useParams().location;
  const classes = useStyles();
  const editable = !(location === HISTORY_LOCATION);

  const store = useStore();
  const { employeeID } = store.employeeData;

  const { settings } = props;

  // States
  const [commentBody, setCommentBody] = useState('');
  const [commentBodyError, setCommentBodyError] = useState('');
  const [expand, setExpand] = useState(false);
  const [formInputs, setFormInputs] = useState('');
  const [openUploadDialog, setUploadDialog] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const [openCancelRequestDialog, setOpenCancelRequestDialog] = useState(false);

  // Queries
  const {
    data: documentRequestData,
    loading: documentRequestLoading,
    refetch: documentRequestRefetch,
  } = useQuery(readDocumentRequestByID, {
    variables: {
      id: documentRequestID,
    },
  });

  const { data: allDaysData, loading: loadingDays } = useQuery(
    readAllFreeDaysForYearQuery,
    {
      variables: {
        year: CURRENT_YEAR,
      },
    }
  );

  const {
    data: commentsData,
    loading: commentsLoading,
    refetch: commentsRefetch,
  } = useQuery(readCommentsByID, {
    variables: {
      id: documentRequestID,
      limit: DEFAULT_COMMENTS_LIMIT,
    },
  });

  const {
    data: downloadsData,
    refetch: refetchDownload,
    loading: loadingDownload,
  } = useQuery(readDocumentsFromDocumentRequestQuery, {
    variables: {
      id: documentRequestID,
    },
  });

  useEffect(() => {
    if (documentRequestData) {
      let documentFields = {};

      documentRequestData.readDocumentRequestByID.documentRequest.fields.forEach(
        (fieldItem) => {
          documentFields[fieldItem.fieldName] = fieldItem.fieldValue;
        }
      );

      setFormInputs(documentFields);
    }
  }, [documentRequestData]);

  useEffect(() => {
    if (commentsData?.readCommentsByID?.length === 1 && expand) {
      setExpand(false);
    }
  }, [commentsData?.readCommentsByID?.length]);

  // Mutations

  const [updateDocumentRequest, {}] = useMutation(
    updateDocumentRequestMutation,
    {
      onCompleted: async () => {
        props.showSnackbar(
          get(settings, 'documents.formDialog.editCompleteMessage', {
            default: 'Edit was successful!',
          }),
          SNACKBAR_SEVERITY.SUCCESS
        );
        await documentRequestRefetch();
      },
    }
  );

  const [createCommentInDocumentRequest, {}] = useMutation(
    createCommentInDocumentRequestMutation,
    {
      onCompleted: async () => {
        await commentsRefetch();
      },
    }
  );

  const [removeCommentFromDocumentRequest, {}] = useMutation(
    removeCommentFromDocumentRequestMutation,
    {
      onCompleted: async () => {
        await commentsRefetch();
      },
    }
  );

  const [cancelRequest, {}] = useMutation(cancelRequestMutation, {
    onCompleted: async () => {
      await documentRequestRefetch();
    },
  });

  const handleChange = (event) => {
    let { name, value } = event.target;

    const freeDays = allDaysData?.readAllFreeDaysForYear?.days;
    let hasFreeDay = 0;
    let start = formInputs?.dateFrom;
    let end = formInputs?.dateTo;
    // if selected interval contains a free day, recalculate period

    // dateTo -> dateFrom
    if (start !== '' && name === FORM_FIELDS.dateTo) {
      hasFreeDay = enumerateForFreeDay(start, value, freeDays);

      if (hasFreeDay !== 0) {
        value = moment(value).add(freeDays, 'days');
        value = value.format(DATE_FORMAT);
      }

      setFormInputs({
        ...formInputs,
        [FORM_FIELDS.numberOfDays]:
          hasFreeDay === 0
            ? String(calculateDays(start, value, freeDays))
            : String(calculateDays(start, value, freeDays) - hasFreeDay),
        [FORM_FIELDS.dateTo]: value,
      });

      return;
    }

    // dateFrom -> dateTo
    if (end !== '' && name === FORM_FIELDS.dateFrom) {
      hasFreeDay = enumerateForFreeDay(value, end, freeDays);

      if (hasFreeDay !== 0) {
        value = moment(value).subtract(freeDays, 'days');
        value = value.format(DATE_FORMAT);
      }

      setFormInputs({
        ...formInputs,
        [FORM_FIELDS.numberOfDays]:
          hasFreeDay === 0
            ? String(calculateDays(value, end, freeDays))
            : String(calculateDays(value, end, freeDays) - hasFreeDay),
        [FORM_FIELDS.dateFrom]: value,
      });

      return;
    }

    setFormInputs({ ...formInputs, [name]: value });
  };

  const handleSubmit = async () => {
    const requestFields = completeEditFields(formInputs, retrievedData.fields);

    await updateDocumentRequest({
      variables: {
        id: documentRequestID,
        fields: requestFields,
      },
    });

    setSaveButton(false);
  };

  const handleExpandClick = async () => {
    const limit = expand ? DEFAULT_COMMENTS_LIMIT : 0;
    await commentsRefetch({
      id: documentRequestID,
      limit: limit,
    });
    expand ? setExpand(false) : setExpand(true);
  };

  const handleCreateComment = () => {
    if (commentBody === '') {
      setCommentBodyError(
        get(settings, 'formDialog.requiredInput', {
          default: 'Required',
        })
      );
    } else {
      createCommentInDocumentRequest({
        variables: {
          documentRequestId: documentRequestID,
          date: moment.utc(new Date()),
          source: employeeID,
          comment: commentBody,
        },
      });
      setCommentBody('');
      setCommentBodyError('');
    }
  };

  const handleRemoveComment = (commentID) => {
    removeCommentFromDocumentRequest({
      variables: {
        documentRequestId: documentRequestID,
        commentId: commentID,
      },
    });
  };

  const handleCancelRequest = () => {
    setOpenCancelRequestDialog(true);
  };

  const handleSubmitCancelRequest = async () => {
    await cancelRequest({
      variables: {
        id: documentRequestID,
        status: DOCUMENT_REQUEST_STATUS.CANCELED,
        employeeID: employeeID,
      },
    });
    setOpenCancelRequestDialog(false);
  };

  if (
    documentRequestLoading ||
    commentsLoading ||
    loadingDownload ||
    loadingDays
  ) {
    return (
      <div className="sectionContainer">
        <Toolbar
          title={get(settings, 'documents.formDialog.loadingTitle', {
            default: 'Document request',
          })}
          buttons={[]}
          backwardLink="/documents"
        />
        <CircularUnderLoad />
      </div>
    );
  }

  const handleCancelChanges = () => {
    let documentFields = {};
    documentRequestData?.readDocumentRequestByID?.documentRequest.fields.forEach(
      (fieldItem) => {
        documentFields[fieldItem.fieldName] = fieldItem.fieldValue;
      }
    );

    setFormInputs(documentFields);

    setSaveButton(false);
  };

  const retrievedData =
    documentRequestData?.readDocumentRequestByID?.documentRequest;

  const globalStatus =
    documentRequestData?.readDocumentRequestByID?.globalStatus;

  const retrievedComments =
    commentsData.readCommentsByID.length > 1 && !expand
      ? commentsData.readCommentsByID.slice(0, 1)
      : commentsData.readCommentsByID;

  const disabledEdit = getPendingStatus(retrievedData.documentVerification);

  const commentButtonText = expand ? COMMENT_BUTTON.LESS : COMMENT_BUTTON.MORE;

  //TODO: Refactor this file. Try to break it in different components
  return (
    <div className="sectionContainer">
      {retrievedData.template && (
        <Toolbar
          title={retrievedData.template.name}
          buttons={[]}
          backwardLink="/documents"
        />
      )}
      <Typography
        className={classes.typography}
        variant="h6"
        color="textSecondary"
      >
        {get(settings, 'requestDetails.informations', {
          default: 'Informations',
        })}
      </Typography>
      <Paper className={classes.paperLayout}>
        <TextField
          length={12}
          type="text"
          variant="outlined"
          name="source"
          value={moment(retrievedData.date).format(RO_DATE_FORMAT)}
          label={get(settings, 'documents.formDialog.fieldsLabel.date', {
            default: 'Date',
          })}
          InputLabelProps={{ shrink: true }}
          disabled={true}
          className={classes.textField}
        />
        {/* Number of days and date  */}
        {documentRequestData.readDocumentRequestByID.documentRequest.fields.map(
          (mapItem) => {
            if (mapItem.fieldSource === SOURCE_TYPES.user) {
              return (
                <TextField
                  length={12}
                  className={classes.textField}
                  variant="outlined"
                  key={mapItem.fieldName}
                  type={mapItem.fieldType}
                  name={mapItem.fieldName}
                  value={formInputs ? formInputs[mapItem.fieldName] : ''}
                  label={get(
                    settings,
                    `documents.formDialog.fieldsLabel.${mapItem.fieldName}`,
                    {
                      default: `${mapItem.fieldName}`,
                    }
                  )}
                  InputProps={inputPropsRender(
                    mapItem.fieldName,
                    formInputs?.dateFrom || ''
                  )}
                  InputLabelProps={{ shrink: true }}
                  handleChange={handleChange}
                  disabled={
                    !saveButton ||
                    mapItem.fieldName === FORM_FIELDS.numberOfDays
                  }
                />
              );
            }
          }
        )}
        <div className={classes.alignRightDiv}>
          {location === HISTORY_LOCATION ? (
            <>
              {!saveButton ? (
                !disabledEdit &&
                !editable && (
                  <Button
                    color="primary"
                    disabled={disabledEdit || editable}
                    onClick={() => setSaveButton(true)}
                    className={classes.disableEdit}
                  >
                    {get(settings, 'documents.formDialog.editButton', {
                      default: 'Edit',
                    })}
                  </Button>
                )
              ) : (
                <>
                  <Button onClick={handleSubmit} color="primary">
                    {get(
                      settings,
                      'timesheetViewer.dialogItems.saveButtonLabel',
                      {
                        default: 'Save',
                      }
                    )}
                  </Button>
                  <Button color="secondary" onClick={handleCancelChanges}>
                    {get(
                      settings,
                      'timesheetViewer.dialogItems.cancelButtonLabel',
                      {
                        cancelButtonLabel: 'Cancel',
                      }
                    )}
                  </Button>
                </>
              )}
            </>
          ) : (
            <></>
          )}

          {location === HISTORY_LOCATION &&
            globalStatus === DOCUMENT_REQUEST_STATUS.APPROVED && (
              <Button
                color="secondary"
                onClick={handleCancelRequest}
                className={classes.button}
              >
                {get(settings, 'documents.cancelDialog.openButton')}
              </Button>
            )}
        </div>
      </Paper>
      {retrievedData.documentVerification.length !== 0 && (
        <>
          <Typography
            variant="h6"
            color="textSecondary"
            className={classes.typography}
          >
            {get(settings, 'requestDetails.status', {
              default: 'Status',
            })}
          </Typography>
          <Paper className={classes.paperLayout}>
            <Grid item sm={10} className={classes.grid}>
              <StatusTable
                documentRequestID={documentRequestID}
                documentVerification={retrievedData.documentVerification}
                globalStatus={globalStatus}
                validate={editable}
                disabledEdit={disabledEdit}
                settings={settings}
                refetchDownload={refetchDownload}
                documentRequestRefetch={documentRequestRefetch}
              />
            </Grid>
          </Paper>
        </>
      )}

      <Typography
        variant="h6"
        color="textSecondary"
        className={classes.typography}
      >
        {get(settings, 'requestDetails.documents', {
          default: 'documents',
        })}
      </Typography>
      <Paper className={classes.paperLayout}>
        {location === HISTORY_LOCATION ? (
          <div className={classes.alignRightDiv}>
            <Button
              color="primary"
              onClick={() => setUploadDialog(true)}
              className={classes.uploadDialog}
            >
              {get(settings, 'documents.formDialog.uploadDocumentsButton', {
                default: 'Upload documents',
              })}
            </Button>
          </div>
        ) : (
          <></>
        )}
        <DownloadFilesTable
          loading={loadingDownload}
          settings={settings}
          data={downloadsData}
          documentRequestID={documentRequestID}
        />
      </Paper>

      <Typography
        variant="h6"
        color="textSecondary"
        className={classes.typography}
      >
        {get(settings, 'requestDetails.comments', { default: 'Comments' })}
      </Typography>
      <Paper className={classes.paperLayout}>
        <Grid container>
          <TextField
            length={11}
            type="text"
            name="commentBody"
            value={commentBody}
            error={commentBodyError}
            label={get(
              settings,
              'documents.formDialog.fieldsLabel.commentBody',
              {
                default: 'Comment',
              }
            )}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setCommentBody(e.target.value);
              setCommentBodyError('');
            }}
          />
          <Grid item sm={1} xs={1}>
            <IconButton onClick={handleCreateComment} color="inherit">
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item sm={12} xs>
          <List>
            {retrievedComments.length !== 0 &&
              retrievedComments.map((comment) => {
                return (
                  <React.Fragment key={comment.id}>
                    <ListItem key={comment.id} alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar>
                          {[
                            comment.source.firstName[0],
                            comment.source.lastName[0],
                          ].join('')}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography>
                            {[
                              comment.source.firstName,
                              comment.source.lastName,
                            ].join(' ')}
                          </Typography>
                        }
                        secondary={
                          <>
                            {`${moment(comment.date).format(
                              RO_DATE_TIME_FORMAT
                            )} - `}
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {comment.comment}
                            </Typography>
                          </>
                        }
                      />
                      {comment.source.id === employeeID && (
                        <IconButton
                          onClick={() => handleRemoveComment(comment.id)}
                        >
                          <Clear />
                        </IconButton>
                      )}
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })}
          </List>
        </Grid>
        <div className={classes.alignCenterDiv}>
          <Button
            onClick={handleExpandClick}
            color="primary"
            disabled={commentsData.readCommentsByID.length <= 1}
          >
            {commentButtonText}
          </Button>
        </div>
      </Paper>

      <UploadDocumentsDialog
        refetch={refetchDownload}
        settings={settings}
        openForm={openUploadDialog}
        documentRequestID={documentRequestID}
        handleClose={() => setUploadDialog(false)}
        snackbar={props.showSnackbar}
      />
      <ConfirmForm
        open={openCancelRequestDialog}
        title={get(settings, 'documents.cancelDialog.title')}
        submitButtonLabel={get(
          settings,
          'documents.cancelDialog.submitButtonLabel'
        )}
        cancelButtonLabel={get(
          settings,
          'documents.cancelDialog.cancelButtonLabel'
        )}
        handleSubmit={handleSubmitCancelRequest}
        handleClose={() => setOpenCancelRequestDialog(false)}
      >
        <Typography>
          {get(settings, 'documents.cancelDialog.content')}
        </Typography>
      </ConfirmForm>
    </div>
  );
};

export default withSnackbar(RequestDetails);
