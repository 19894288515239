import React from 'react';
import get from 'get-value';

// importing Material UI elements
import { Typography, Grid, Chip, Tooltip, makeStyles } from '@material-ui/core';
import { AdminDestination } from './admin-destination';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flexStart',
    flexDirection: 'column',
    padding: theme.spacing(0.5),
    margin: 0,
    paddingTop: 5,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  autocompleteContainer: {
    minWidth: '125px',
  },
}));

const DestinationsRender = ({
  settings,
  destinations,
  adminDestination,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography>
        {get(settings, 'documents.formDialog.destinations', {
          default: 'Destinations',
        })}
        :
      </Typography>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        wrap="nowrap"
      >
        <AdminDestination
          admins={destinations[1]?.destinations}
          settings={settings}
          adminDestination={adminDestination} // FIXME: it is undefined as it is never been sent
          handleChange={handleChange}
        />

        {destinations[0]?.destinations?.map((data) => (
          <Grid item key={data.id}>
            <Tooltip
              title={get(settings, 'documents.formDialog.destinations', {
                default: 'Destinations',
              })}
            >
              <Chip label={data.fullName} className={classes.chip} />
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default DestinationsRender;
