import React, { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

// importing material UI elements
import { Card, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '300px',
    borderRadius: '10px',
    marginTop: '1px',
    padding: '5px',
  },
  reactCalendar: {
    width: '100%',
    border: 'none',
  },
}));

const CalendarDashboard = () => {
  const [value, setValue] = useState(new Date());

  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <Card className={classes.root} elevation={2}>
        <Calendar
          onChange={setValue}
          value={value}
          className={classes.reactCalendar}
        />
      </Card>
    </Grid>
  );
};

export default CalendarDashboard;
