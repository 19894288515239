import React from 'react';
import { Link, NavLink } from 'react-router-dom';

// importing material UI elements
import { IconButton, Grid, Paper, Button, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

export const useStyles = makeStyles(() => {
  return {
    toolbar: {
      width: '100%',
      padding: '20px 20px 0px 20px',
      borderRadius: '0px',
    },
    buttons: {
      textAlign: 'right',
    },
    h1: {
      fontSize: '1.5em',
    },
    div: {
      display: 'flex',
      flexDirection: 'row',
    },
  };
});

const Toolbar = (props) => {
  const { backwardLink, title, buttons = [], children } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.toolbar}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {backwardLink ? (
            <div className={classes.div}>
              <Link to={backwardLink}>
                <IconButton aria-label='back to'>
                  <ArrowBack fontSize='large' />
                </IconButton>
              </Link>
              <h1 className={classes.h1}>{title}</h1>
            </div>
          ) : (
            <h1 className={classes.h1}>{title}</h1>
          )}
        </Grid>
        <Grid item xs={6} className={classes.buttons}>
          {buttons.map((button, index) => {
            return (
              <Button
                key={`toolbar-button-${index}`}
                className={button.style}
                size={button.size || 'small'}
                variant={button.variant || 'contained'}
                startIcon={button.icon}
                onClick={button.handleAction}
                target={button.target}
                component={button.to ? NavLink : 'button'}
                to={button.to}
                disabled={button.disabled || false}
              >
                {button.name}
              </Button>
            );
          })}
        </Grid>
      </Grid>
      {children}
    </Paper>
  );
};

export default Toolbar;
