import { makeAutoObservable, runInAction } from 'mobx';
import APIHelper from '../helpers/api-helper';

export class MainStore {
  employeeData = {};
  refetchTimeSheets = true;
  refetchStepData = false;

  isFetching = false;

  userSchedule = {};

  constructor() {
    makeAutoObservable(this);
  }

  setEmployeeData({
    employeeID,
    fullName,
    email,
    isAdmin,
    workingSchedule,
    employmentType,
  }) {
    this.employeeData = {
      employeeID,
      fullName,
      email,
      isAdmin,
      employmentType,
      workingSchedule,
    };
  }

  triggerRefetchStep(value) {
    this.refetchStepData = value;
  }

  toggleRefetch(value) {
    runInAction(() => {
      this.refetchTimeSheets = value;
    });
  }

  setUserSchedule(schedule) {
    this.employeeData.workingSchedule = schedule;
  }

  getUserData = async () => {
    runInAction(() => (this.isFetching = true));

    return APIHelper.prototype
      .fetchData(`/auth/user-data`, 'POST')
      .then((response) => response.json())
      .then((data) => {
        this.setEmployeeData(data);
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => runInAction(() => (this.isFetching = false)));
  };
}
