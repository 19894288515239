import React from 'react';
import get from 'get-value';

//* Material UI imports
import {
  Avatar,
  Button,
  Divider,
  Grid,
  List,
  makeStyles,
} from '@material-ui/core';

//* Material UI icons
import {
  ExitToApp as ExitToAppIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  Timelapse as TimelapseIcon,
  DashboardOutlined as DashboardOutlinedIcon,
  Business as BusinessIcon,
  GroupOutlined as GroupOutlinedIcon,
  AccountCircleOutlined as AccountCircleOutlined,
  SettingsApplicationsOutlined as SettingsIcon,
  ContactSupportOutlined,
} from '@material-ui/icons/';

//*Custom imports
import NavigationItem from '../components/navigation-item';
import StepButtons from './step-buttons';
import { useStore } from '../stores';

const useStyles = makeStyles((theme) => ({
  logOutBtnContainer: {
    width: '100%',
    textAlign: 'center',
    position: 'absolute',
    bottom: '100px',
  },
  avatarGrid: {
    padding: '10px',
  },
  userContainer: {
    padding: '10px 0',
  },
  userName: {
    fontWeight: 600,
    fontSize: '1.1em',
    margin: 0,
    lineHeight: 1,
    textAlign: 'center',
  },
  //* necessary for content to be bellow app bar
  userClearance: {
    fontSize: '0.8em',
    color: '#a5a5a5',
    margin: 0,
  },
  toolbar: theme.mixins.toolbar,
  avatar: {
    backgroundColor: '#9AAAEE',
  },
}));

const MENU_ITEMS = [
  {
    title: 'dashboard',
    placeholder: 'Dashboard',
    icon: DashboardOutlinedIcon,
    link: '/',
    hidden: true,
  },
  {
    title: 'attendance',
    placeholder: 'Attendance',
    icon: TimelapseIcon,
    link: '/attendance',
  },
  {
    title: 'documents',
    placeholder: 'Documents',
    icon: DescriptionOutlinedIcon,
    link: '/documents',
  },
  {
    title: 'companies',
    placeholder: 'Companies',
    icon: BusinessIcon,
    link: '/companies',
    isAdmin: true,
  },
  {
    title: 'teams',
    placeholder: 'Teams',
    icon: GroupOutlinedIcon,
    link: '/teams',
  },
  {
    title: 'settings',
    placeholder: 'Settings',
    icon: SettingsIcon,
    link: '/settings',
    isAdmin: true,
  },
  {
    title: 'support',
    placeholder: 'Support',
    icon: ContactSupportOutlined,
    link: '/support',
  },
  {
    title: 'account',
    placeholder: 'Account',
    icon: AccountCircleOutlined,
    link: '/account',
  },
];

const NavigationItems = (props) => {
  const { isAdmin = false, settings } = props;
  return MENU_ITEMS.filter(
    (it) => (it.isAdmin ? isAdmin : true) && !it.hidden
  ).map((menuItem, index) => (
    <NavigationItem
      key={index}
      to={menuItem.link}
      icon={menuItem.icon}
      title={get(settings, `dashboardMenu.${menuItem.title}`, {
        default: menuItem.placeholder,
      })}
    />
  ));
};

const Navigation = (props) => {
  const { settings, handleLoginButton } = props;

  const classes = useStyles();
  const store = useStore();
  const { isAdmin, email, fullName } = store.employeeData;

  return (
    <>
      <div className={classes.toolbar}>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.userContainer}
        >
          <Grid item xs={3} className={classes.avatarGrid}>
            <Avatar className={classes.avatar} />
          </Grid>
          <Grid item xs={9}>
            <p className={classes.userName}>{fullName}</p>
            <p className={classes.userClearance}>{email}</p>
          </Grid>

          <StepButtons settings={settings} />
        </Grid>
        {/* <Divider variant='middle' /> */}
      </div>
      <List>
        <Divider variant="middle" />
        <NavigationItems isAdmin={isAdmin} settings={settings} />
      </List>
      <Divider variant="middle" />
      <div className={classes.logOutBtnContainer}>
        <Divider variant="middle" />
        <Button onClick={handleLoginButton} startIcon={<ExitToAppIcon />}>
          {get(settings, 'dashboardMenu.logOut', { default: 'Log Out' })}
        </Button>
      </div>
    </>
  );
};

export default Navigation;
