import React from 'react';
import { NavLink } from 'react-router-dom';

//* Material UI imports
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

const NavigationItem = (props) => {
  const { icon, title, to } = props;
  const Icon = icon;
  return (
    <ListItem
      button
      component={NavLink}
      to={to}
      activeClassName='Mui-selected'
      exact
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
};
export default NavigationItem;
