import React from 'react';
import get from 'get-value';

// importing Material UI core
import { makeStyles, Card, Typography, Divider } from '@material-ui/core';

// importing internal components
import { getLanguageSupport } from '../../utils/helper';
import Toolbar from '../../core/toolbar';
import APIHelper from '../../helpers/api-helper';

const settings = getLanguageSupport();

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    width: '700px',
    height: 'auto',
    padding: '10px',
    marginTop: '2em',
    borderRadius: '5px',
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
  },
}));

// TODO: Add support for translation
const RESOURCES = [
  {
    title: 'Cum poti ponta',
    description:
      'Prezentarea metodelor prin care un utilizator oarecare poate ponta in cadrul aplicatiei precum si ce alte facilitati sunt oferit de sectiunea de pontaj.',
    videoPath: '/assets/support/ro/timekey-cum-poti-ponta.mp4',
  },
  {
    title: 'Cererea de concediu',
    description:
      'Descrierea procesului de creare a unei cereri de concediu precum si mecanismul de aprobare.',
    videoPath: '/assets/support/ro/timekey-cerere-de-concediu.mp4',
  },
];

const Support = () => {
  const classes = useStyles();

  return (
    <div className="sectionContainer">
      <Toolbar
        title={get(settings, 'support.title', {
          default: 'Support',
        })}
      />

      {RESOURCES.map(({ title, description, videoPath }) => (
        <Card className={classes.root} variant="outlined">
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {description}
          </Typography>
          <Divider />
          <video
            width="100%"
            src={APIHelper.prototype.buildUrl(videoPath)}
            controls
          />
        </Card>
      ))}
    </div>
  );
};

export default Support;
