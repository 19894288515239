import React from 'react';

// importing internal components
import {
  calculateWorkingTime,
  minutesToHoursAndMinutes,
} from '../../../helpers/date-helper';
import { WORK_STATUS } from '../../../utils/constants';
import { makeStyles } from '@material-ui/core/styles';
import get from 'get-value';

import { TableCell, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  workDayCell: {
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#d4dee1',
    },
    textAlign: 'center',
  },
  weekendDayCell: {
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: '#f2f2f2',
    '&:hover': {
      backgroundColor: '#d4dee1',
    },
    textAlign: 'center',
  },
  holidayCell: {
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: '#FFE4E1',
    '&:hover': {
      backgroundColor: '#d4dee1',
    },
    textAlign: 'center',
  },
}));

const CELL_CLASSNAMES = {
  [WORK_STATUS.WORK]: 'workDayCell',
  [WORK_STATUS.REST]: 'holidayCell',
  [WORK_STATUS.MATERNAL_LEAVE]: 'holidayCell',
  [WORK_STATUS.PARENTAL_LEAVE]: 'holidayCell',
};

function getCellContent(attendanceLocal, day) {
  const emptyContent = '';
  const isFreeDay = day.weekend || day.holiday;

  if (attendanceLocal?.status) {
    switch (attendanceLocal.status) {
      case WORK_STATUS.REST:
        return (day.date > Date.now() || day.workingDay) && !isFreeDay
          ? WORK_STATUS.REST
          : emptyContent;
      case WORK_STATUS.MATERNAL_LEAVE:
        return isFreeDay ? emptyContent : WORK_STATUS.MATERNAL_LEAVE;
      case WORK_STATUS.PARENTAL_LEAVE:
        return isFreeDay ? emptyContent : WORK_STATUS.PARENTAL_LEAVE;
      case WORK_STATUS.WORK:
        return minutesToHoursAndMinutes(
          calculateWorkingTime(attendanceLocal.hours)
        );
      default:
        return emptyContent;
    }
  }

  return emptyContent;
}

function getClassNames(day, status) {
  if (day.weekend) return 'weekendDayCell';
  if (day.holiday) return 'holidayCell';

  return CELL_CLASSNAMES[status];
}

const TableCellContent = ({
  attendanceLocal,
  day,
  tooltipTitle,
  onClick,
  settings,
}) => {
  const classes = useStyles();
  const cellContent = getCellContent(attendanceLocal, day);

  const translatedLabel = get(
    settings,
    `timesheetViewer.workTypes.${cellContent}`,
    {
      default: cellContent,
    }
  );

  const cellType = getClassNames(day, cellContent);
  const cellClassName = classes[cellType] ?? classes.workDayCell;
  return (
    <Tooltip title={tooltipTitle} interactive>
      <TableCell className={cellClassName} onClick={onClick}>
        {translatedLabel}
      </TableCell>
    </Tooltip>
  );
};

export default TableCellContent;
