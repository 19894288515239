import React, { useState } from 'react';
import get from 'get-value';
import { gql, useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { observer } from 'mobx-react';

// importing Material UI core
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core';

// importing Material UI icons
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AutorenewIcon from '@material-ui/icons/Autorenew';

// importing internal components
import AttendanceSheet from './components/table-sheet';
import {
  DATE_FORMAT,
  momentDateFormat,
  calculateMonthAndYear,
} from '../../helpers/date-helper';
import Toolbar from '../../core/toolbar';
import AutocompleteField from './components/autocomplete-field';
import DateBrowser from './components/date-browser';
import CircularUnderLoad from '../../components/loading-animation';
import withSnackbar from '../../utils/with-snackbar';
import { useStore } from '../../stores';
import { getLanguageSupport } from '../../utils/helper';
import APIHelper from '../../helpers/api-helper';
import { SNACKBAR_SEVERITY } from '../../utils/constants';

const settings = getLanguageSupport();

const TEAM_FIELD = 'team';
const COMPANY_FIELD = 'company';
const FILTER_NAME = get(settings, 'attendancePage.filterPlaceHolder', {
  default: 'Companies and Teams',
});

const useStyles = makeStyles((theme) => ({
  toolbar: {
    width: '100%',
    padding: '20px 20px',
    marginTop: '-15px',
    fontSize: '20px',
    fontWeight: '700',
  },
  filterField: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      width: '250px',
    },
  },
  overviewSheet: {
    paddingTop: 30,
  },
  checkBoxSpacing: {
    marginLeft: 20,
  },
}));

// GraphQL Queries & Operations
const readOverviewOutputQuery = gql`
  query (
    $monthAndYear: String!
    $filter: [filterObject]
    $displayInactive: Boolean
    $displayCollaborators: Boolean
  ) {
    readOverviewOutput(
      monthAndYear: $monthAndYear
      filter: $filter
      displayInactive: $displayInactive
      displayCollaborators: $displayCollaborators
    ) {
      employeeID
      employeeName
      attendance {
        date
        status
        hours {
          stepIn
          stepOut
        }
      }
      totalHours
    }
  }
`;

const readAllTeamsQuery = gql`
  query ($filter: String) {
    readAllTeams(filter: $filter) {
      id
      teamName
    }
  }
`;

const readAllCompaniesQuery = gql`
  query {
    readAllCompanies {
      id
      companyName
    }
  }
`;

const quickFillTimesheetActivity = gql`
  mutation quickFillTimesheetActivity(
    $id: ID!
    $workingSchedule: WorkingSchedules
  ) {
    quickFillTimesheetActivity(id: $id, workingSchedule: $workingSchedule)
  }
`;

const DATE_NAVIGATION = {
  back: 0,
  next: 1,
};

const Attendance = observer((props) => {
  const classes = useStyles();

  const store = useStore();
  const { fullName, isAdmin, employeeID, workingSchedule } = store.employeeData;

  // States
  const [filterElements, setfilterElements] = useState([]);
  const [currentSheetMonth, setCurrentSheetMonth] = useState(
    momentDateFormat()
  );
  const currentMonthAndYear = calculateMonthAndYear(currentSheetMonth);

  const [showInactive, setShowInactive] = useState(false);
  const [showCollaborators, setShowCollaborators] = useState(false);

  let filterQuery = [
    { field: TEAM_FIELD, value: [], operator: 'equals' },
    { field: COMPANY_FIELD, value: [], operator: 'equals' },
  ];

  if (filterElements.length != 0) {
    filterElements.forEach((filterElement) => {
      if (filterElement.type === 'team') {
        filterQuery[0].value.push(filterElement.id);
      } else if (filterElement.type === 'company') {
        filterQuery[1].value.push(filterElement.id);
      }
    });
  }

  // GraphQL
  const {
    data: overviewData,
    refetch: refetchData,
    loading: loadingData,
  } = useQuery(readOverviewOutputQuery, {
    variables: {
      monthAndYear: currentMonthAndYear,
      displayInactive: showInactive,
      displayCollaborators: showCollaborators,
      filter:
        filterQuery[0].value != 0 || filterQuery[1].value != 0
          ? filterQuery
          : [],
    },
  });

  // Quick fill mutation call
  const [quickFillTimesheet] = useMutation(quickFillTimesheetActivity, {
    onCompleted: async () => {
      await refetchData();
      props.showSnackbar(
        get(settings, 'attendancePage.toolbar.successMessage', {
          default: 'Quick fill done!',
        }),
        SNACKBAR_SEVERITY.SUCCESS
      );
    },
  });

  const { data: teamsData } = useQuery(readAllTeamsQuery);

  const { data: companiesData } = useQuery(readAllCompaniesQuery);

  const handleChange = (_event, values) => {
    setfilterElements(values);
  };

  const changeFieldValue = (event) => {
    setCurrentSheetMonth(event.target.value);
  };

  const handleClickGenerate = () => {
    APIHelper.prototype
      .fetchData(
        `/report/generate`,
        'POST',
        {
          monthAndYear: currentMonthAndYear,
          displayInactive: showInactive,
          displayCollaborators: showCollaborators,
          filter:
            filterQuery[0].value != 0 || filterQuery[1].value != 0
              ? filterQuery
              : [],
        },
        true
      )
      .then((response) => response.blob())
      .then((blob) => {
        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');

        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        link.download = currentMonthAndYear;

        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        // Remove link from body
        document.body.removeChild(link);
      });
  };

  const dateNavigate = (action) => {
    const currentSheetDate = currentSheetMonth;
    let newSheetDate;

    switch (action) {
      case DATE_NAVIGATION.back:
        newSheetDate = moment(currentSheetDate)
          .subtract(1, 'months')
          .format(DATE_FORMAT);
        break;
      case DATE_NAVIGATION.next:
        newSheetDate = moment(currentSheetDate)
          .add(1, 'months')
          .format(DATE_FORMAT);
        break;
      default:
        return;
    }
    setCurrentSheetMonth(newSheetDate);
  };

  const handleFilterCheck = (event) => {
    switch (event.target.name) {
      case 'showCollaborators':
        setShowCollaborators(event.target.checked);
        break;
      case 'showInactive':
      default:
        setShowInactive(event.target.checked);
        break;
    }
  };

  if (!teamsData || !companiesData) {
    return <CircularUnderLoad />;
  }

  const filterData = [];

  teamsData.readAllTeams.forEach((teamElement) =>
    filterData.push({
      elementName: `${get(settings, 'attendancePage.team', {
        default: 'Team',
      })}: ${teamElement.teamName}`,
      id: teamElement.id,
      type: 'team',
    })
  );

  companiesData.readAllCompanies.forEach((companyElement) =>
    filterData.push({
      elementName: `${get(settings, 'attendancePage.company', {
        default: 'Company',
      })}: ${companyElement.companyName}`,
      id: companyElement.id,
      type: 'company',
    })
  );

  const toolbarButtons =
    !teamsData || !companiesData
      ? []
      : [
          {
            name: get(settings, 'attendancePage.toolbar.fillButton', {
              default: 'Quick fill',
            }),
            style: classes.addButton,
            icon: <AutorenewIcon />,
            handleAction: async () => {
              await quickFillTimesheet({
                variables: {
                  id: employeeID,
                  workingSchedule,
                },
              });
            },
          },
        ];

  return (
    <div className="sectionContainer">
      <Toolbar
        title={get(settings, 'attendancePage.title', { default: 'Attendance' })}
        buttons={toolbarButtons}
      >
        <div className={classes.toolbar}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <div className={classes.filterField}>
                <AutocompleteField
                  multiple={true}
                  filterData={filterData}
                  handleChange={handleChange}
                  filterElements={filterElements}
                  filterPlaceHolder={FILTER_NAME}
                />
              </div>
            </Grid>

            <Grid item>
              <>
                <DateBrowser
                  currentMonthAndYear={currentMonthAndYear}
                  changeFieldValue={changeFieldValue}
                  dateNavigate={dateNavigate}
                  dateNavigation={DATE_NAVIGATION}
                />
              </>
            </Grid>

            {isAdmin && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<DynamicFeedIcon />}
                  onClick={handleClickGenerate}
                >
                  {get(settings, 'attendance.excelGenerate', {
                    default: 'excelGenerate',
                  })}
                </Button>
              </Grid>
            )}

            {isAdmin && (
              <Tooltip
                title={get(settings, 'attendance.filterInactiveTip', {
                  default: 'filterInactiveTip',
                })}
              >
                <Grid item className={classes.checkBoxSpacing}>
                  <FormControlLabel
                    label={get(settings, 'attendance.filterInactive', {
                      default: 'filterInactive',
                    })}
                    control={
                      <Checkbox
                        checked={showInactive}
                        name="showInactive"
                        color="primary"
                        size="medium"
                        onChange={handleFilterCheck}
                      />
                    }
                  />
                </Grid>
              </Tooltip>
            )}

            {isAdmin && (
              <Tooltip
                title={get(settings, 'attendance.filterCollaboratorsTip', {
                  default: 'Show collaborators',
                })}
              >
                <Grid item className={classes.checkBoxSpacing}>
                  <FormControlLabel
                    label={get(settings, 'attendance.filterCollaborators', {
                      default: 'Show collaborators',
                    })}
                    control={
                      <Checkbox
                        checked={showCollaborators}
                        name="showCollaborators"
                        color="primary"
                        size="medium"
                        onChange={handleFilterCheck}
                      />
                    }
                  />
                </Grid>
              </Tooltip>
            )}
          </Grid>
        </div>
      </Toolbar>
      <div className={classes.overviewSheet}>
        <AttendanceSheet
          settings={settings}
          userName={fullName}
          currentSheetDate={currentMonthAndYear}
          overviewData={overviewData}
          refetchData={refetchData}
          loadingData={loadingData}
        />
      </div>
    </div>
  );
});

export default withSnackbar(Attendance);
