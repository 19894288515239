//Library imports
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { gql, useQuery } from '@apollo/client';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import get from 'get-value';

// importing Material UI core
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Avatar,
} from '@material-ui/core';

// importing internal components
import TableCellContent from './table-cell-content';
import {
  DATE_FORMAT,
  calculateWorkingTime,
  minutesToHoursAndMinutes,
} from '../../../helpers/date-helper';
import CircularUnderLoad from '../../../components/loading-animation';
import AttendanceInfo from './dialogs/attendance-info';
import { useStore } from '../../../stores';
import {
  DAYS_ROMANIAN,
  SUM_SYMBOL,
  WORK_STATUS,
} from '../../../utils/constants';

// Queries
const readAllFreeDaysForYearQuery = gql`
  query readAllFreeDaysForYear($year: String!) {
    readAllFreeDaysForYear(year: $year) {
      year
      days
    }
  }
`;

const tooltipTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: 'black',
        backgroundColor: 'white',
        maxHeight: 400,
        overflow: 'scroll',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  currentUserAvatarIcon: {
    marginRight: 5,
    fontSize: 12,
    width: 30,
    height: 30,
    backgroundColor: '#9AAAEE',
  },
  tableHeaderStickyCell: {
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: 'white',
    minWidth: '150px',
    position: 'sticky',
    left: 0,
    zIndex: theme.zIndex.appBar + 2,
  },
  tableBodyStickyCell: {
    display: 'flex',
    alignItems: 'center',
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: 'white',
    minWidth: '207px',
    padding: '5px',
    position: 'sticky',
    left: 0,
    zIndex: theme.zIndex.appBar + 1,
  },
  tableHeaderWorkDaysCell: {
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: 'white',
    minWidth: '50px',
  },
  tableHeaderWeekendDaysCell: {
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: '#fafafa',
    borderStyle: 'solid',
    borderBottom: '2px solid #fafafa',
    backgroundColor: '#fafafa',
    minWidth: '50px',
  },
  tableHeaderCellContent: {
    textAlign: 'center',
  },
  hoursCell: {
    textAlign: 'center',
    position: 'sticky',
    width: '17px',
  },
  userAvatarIcon: {
    marginRight: 5,
    backgroundColor: '#BDBDBD',
    fontSize: 12,
    width: 30,
    height: 30,
  },
  overviewTableSheet: {
    width: '100%',
    marginLeft: '5px',
    marginRight: '5px',
  },
}));

const getFreeDaysInMonth = (queryResult, currentMonth) => {
  const freeDays = [];

  if (!queryResult || !queryResult.readAllFreeDaysForYear) return freeDays;

  const { days = [], year: yearPart } = queryResult.readAllFreeDaysForYear;

  days?.forEach((freeDay) => {
    let [dayPart, monthPart] = freeDay.split('/');
    let parsedDate = new Date(yearPart, monthPart, dayPart);

    if (parsedDate.getMonth() === parseInt(currentMonth) - 1)
      freeDays.push(parsedDate.getDate());
  });

  return freeDays;
};

const AttendanceSheet = observer((props) => {
  const {
    settings,
    userName,
    currentSheetDate,
    overviewData,
    refetchData,
    loadingData,
  } = props;

  const store = useStore();
  const classes = useStyles();

  const [currentYear, currentMonth] = currentSheetDate.split('-');

  // STATE
  const [state, setState] = useState({});
  const [statusLocal, setStatusLocal] = useState('');
  const [openDialogInfo, setOpenDialogInfo] = useState(false);

  const { employeeID } = store.employeeData;

  useEffect(() => {
    if (store.refetchTimeSheets) {
      refetchData();
      store.toggleRefetch(false);
    }
  }, [store.refetchTimeSheets]);

  let attendances = [];

  if (overviewData) {
    attendances = [...overviewData.readOverviewOutput];
  }

  // Move logged in user on first position
  if (attendances.length > 1) {
    const connectedIndex = attendances.findIndex(
      (attendance) => attendance.employeeID === employeeID
    );
    if (connectedIndex >= 0) {
      const connectedUser = attendances.splice(connectedIndex, 1);
      attendances.unshift(connectedUser[0]);
    }
  }

  const { data: allDaysData, loading: loadingDays } = useQuery(
    readAllFreeDaysForYearQuery,
    {
      variables: {
        year: currentYear,
      },
    }
  );

  if (loadingData || loadingDays) {
    return <CircularUnderLoad size={40} />;
  }

  const freeDaysThisMonth = getFreeDaysInMonth(allDaysData, currentMonth);

  // Action to perform when clicking table cell
  const handleClickCell = (attendanceLocal, id, name, day) => {
    // You can edit only days in the past + today
    if (day.date < Date.now()) {
      // You cannot edit days off
      if (attendanceLocal && attendanceLocal.status === WORK_STATUS.REST) {
        return;
      }

      let newState = { id, name, day };
      let newStatus = WORK_STATUS.UNKNOWN;

      if (attendanceLocal) {
        newState.attendanceLocal = attendanceLocal;
        newStatus = attendanceLocal.status;
      }

      setState(newState);
      setStatusLocal(newStatus);

      setOpenDialogInfo(true);
    }
  };

  const handleCloseDialogInfo = () => {
    setOpenDialogInfo(false);
  };

  const totalHoursCalculator = (hours) => {
    return minutesToHoursAndMinutes(calculateWorkingTime(hours));
  };

  const getTooltipContent = (attendanceLocal, day) => {
    let cellAttendance = [];

    if (day.workingDay || day.weekend) {
      if (attendanceLocal) {
        if (attendanceLocal.hours.length !== 0) {
          let hoursCalculated = totalHoursCalculator(attendanceLocal.hours);

          attendanceLocal.hours.forEach((set) => {
            cellAttendance.push(
              <TableRow key={uuidv4()}>
                <TableCell>{set.stepIn}</TableCell>
                <TableCell>{set.stepOut}</TableCell>
              </TableRow>
            );
          });

          return (
            <>
              <p>
                {get(
                  settings,
                  'timesheetViewer.tooltipItems.workedHoursLabel',
                  {
                    default: 'Worked hours',
                  }
                ) +
                  ': ' +
                  hoursCalculated}
              </p>
              <p>
                {get(
                  settings,
                  'timesheetViewer.tooltipItems.statusLabels.status',
                  {
                    default: 'Status',
                  }
                )}
                {':' +
                  get(
                    settings,
                    'timesheetViewer.tooltipItems.statusLabels.atWorkLabel',
                    {
                      default: 'Present',
                    }
                  )}
              </p>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {get(
                        settings,
                        'timesheetViewer.dialogItems.tableHead.attendancesInLabel',
                        { default: 'In' }
                      )}
                    </TableCell>
                    <TableCell>
                      {get(
                        settings,
                        'timesheetViewer.dialogItems.tableHead.attendancesOutLabel',
                        { default: 'Out' }
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{cellAttendance}</TableBody>
              </Table>
            </>
          );
        } else {
          if (attendanceLocal.status !== WORK_STATUS.REST) {
            return (
              <>
                <Typography variant="subtitle2">
                  {get(
                    settings,
                    'timesheetViewer.tooltipItems.statusLabels.noHoursLabel',
                    {
                      default: 'No hours',
                    }
                  )}
                </Typography>
              </>
            );
          } else {
            return (
              <>
                <Typography variant="subtitle2">
                  {get(
                    settings,
                    'timesheetViewer.tooltipItems.statusLabels.restLabel',
                    {
                      default: 'Rest',
                    }
                  )}
                </Typography>
              </>
            );
          }
        }
      } else return '';
    } else return '';
  };

  const year = currentSheetDate.split('-')[0];
  const month = currentSheetDate.split('-')[1];
  const daysLabel = DAYS_ROMANIAN;
  const daysInMonth = new Date(year, month, 0).getDate();

  let daysCell = [];
  let daysInfo = [];

  daysCell.push(
    <TableCell key={'id'}>
      <div className={classes.hoursCell}>{SUM_SYMBOL}</div>
    </TableCell>
  );

  for (let dayIndex = 1; dayIndex <= daysInMonth; dayIndex++) {
    const dayDate = new Date(year, month - 1, dayIndex);
    const dayOfWeek = dayDate.getDay();

    const isWeekend = [0, 6].includes(dayOfWeek);

    const cellClassName = isWeekend ? 'Weekend' : 'Work';

    daysCell.push(
      <TableCell
        key={dayIndex}
        className={classes[`tableHeader${cellClassName}DaysCell`]}
      >
        <div className={classes.tableHeaderCellContent}>
          {dayIndex}
          <br />
          {daysLabel[dayOfWeek]}
        </div>
      </TableCell>
    );

    daysInfo.push({
      date: dayDate,
      workingDay: !isWeekend,
      holiday: freeDaysThisMonth.includes(dayIndex),
      weekend: isWeekend,
    });
  }

  return (
    <MuiThemeProvider theme={tooltipTheme}>
      <>
        <TableContainer className={classes.overviewTableSheet}>
          <Table stickyHeader size="small" padding="none">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderStickyCell} />
                {daysCell}
              </TableRow>
            </TableHead>
            <TableBody>
              {attendances.map((rowData, rowIndex) => {
                const isCurrentUser = userName === rowData.employeeName;
                const userInitials = rowData.employeeName
                  .split(' ')
                  .map((section) => section[0])
                  .join('')
                  .toUpperCase();

                const hourAsNumber = Number(rowData.totalHours.split(':')[0]);

                return (
                  <React.Fragment key={rowIndex}>
                    <TableRow>
                      <TableCell
                        key={`name-${rowIndex}`}
                        className={classes.tableBodyStickyCell}
                      >
                        <Avatar
                          className={
                            isCurrentUser
                              ? classes.currentUserAvatarIcon
                              : classes.userAvatarIcon
                          }
                        >
                          {userInitials}
                        </Avatar>
                        <Typography variant="body2">
                          {rowData.employeeName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        key={rowIndex}
                        className={classes.hoursStickyCell}
                      >
                        {hourAsNumber}h
                      </TableCell>
                      {daysInfo.map((day, dayIndex) => {
                        const attendanceLocal = rowData.attendance.find(
                          (attendanceDay) =>
                            moment(attendanceDay.date, DATE_FORMAT).isSame(
                              day.date,
                              'day'
                            )
                        );
                        return (
                          <TableCellContent
                            key={dayIndex}
                            tooltipTitle={getTooltipContent(
                              attendanceLocal,
                              day
                            )}
                            attendanceLocal={attendanceLocal}
                            day={day}
                            settings={settings}
                            onClick={() =>
                              handleClickCell(
                                attendanceLocal,
                                rowData.employeeID,
                                rowData.employeeName,
                                day
                              )
                            }
                          />
                        );
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {openDialogInfo && (
          <AttendanceInfo
            open={openDialogInfo}
            handleCloseDialogInfo={handleCloseDialogInfo}
            state={state}
            statusLocal={statusLocal}
            setStatusLocal={setStatusLocal}
            settings={settings}
            refetchData={refetchData}
          />
        )}
      </>
    </MuiThemeProvider>
  );
});

export default AttendanceSheet;
