export default class APIHelper {
  static get baseUrl() {
    return '/api';
  }

  getConfig(method, data, stringify) {
    const config = { method, credentials: 'include' };

    if (data) {
      config.body = stringify ? JSON.stringify(data) : data;
    }
    if (stringify) {
      config.headers = { 'Content-Type': 'application/json' };
    }

    return config;
  }

  buildUrl(path) {
    return `${APIHelper.baseUrl}${path}`;
  }

  fetchData(url, method = 'GET', body = null, stringify = false) {
    return fetch(
      `${APIHelper.baseUrl}${url}`,
      this.getConfig(method, body, stringify)
    ).then((result) => result);
  }
}
