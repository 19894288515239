//* Library imports
import React, { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import get from 'get-value';

//*Material UI
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Button,
  IconButton,
  makeStyles,
  Modal,
  Input,
  Tooltip,
} from '@material-ui/core';
import { Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons';

//* Custom imports
import CardRow from './card-row';
import PasswordForm from './dialogs/password-form';
import APIHelper from '../../../helpers/api-helper';

//*Constants
import { VALID_MOBILE_NUMBER } from '../../../utils/form-constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '500px',
    height: 'auto',
    padding: '10px',
    marginTop: '2em',
    marginBottom: 'auto',
    marginLeft: '10px',
    borderRadius: '5px',
    [theme.breakpoints.only('md')]: {
      width: 'calc(50% - 10px)',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 10px)',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
      width: '100%',
    },
  },
  title: {
    fontSize: '22px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  fields: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 'inherit',
  },
  secondFieldStyle: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'rtl',
  },
  passwordButton: {
    padding: '0px',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

// Mutations:
const createEmployeeSignatureMutation = gql`
  mutation ($employeeID: ID!) {
    createEmployeeSignature(employeeID: $employeeID)
  }
`;

const updateEmployeeSignatureMutation = gql`
  mutation ($employeeID: ID!) {
    updateEmployeeSignature(employeeID: $employeeID)
  }
`;

const CardDisplay = (props) => {
  const {
    title,
    employeeData,
    saveEmployeeChanges,
    edit,
    setEdit,
    savePasswordChanges,
    settings,
    employeeInfoRefetch,
    showAccountSnackbar,
  } = props;

  const [openForm, setOpenForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [data, setData] = useState({});
  const [openSignature, setOpenSignature] = useState(false);
  const [employeeSignature, setEmployeeSignature] = useState(undefined);
  const [canSubmit, setCanSubmit] = useState(true);

  const requiredLabel = get(settings, 'formDialog.requiredInput', {
    default: 'Required',
  });

  const classes = useStyles();

  useEffect(() => {
    setData(employeeData);
  }, [employeeData]);

  const [createEmployeeSignature, {}] = useMutation(
    createEmployeeSignatureMutation,
    {
      onCompleted: (res) => {
        uploadSignature(employeeSignature, res.createEmployeeSignature);
        setEmployeeSignature(undefined);
        employeeInfoRefetch();
        showAccountSnackbar('createdEmployeeSignature');
      },
    }
  );

  const [updateEmployeeSignature, {}] = useMutation(
    updateEmployeeSignatureMutation,
    {
      onCompleted: (res) => {
        uploadSignature(employeeSignature, res.updateEmployeeSignature);
        setEmployeeSignature(undefined);
        employeeInfoRefetch();
        showAccountSnackbar('updateEmployeeSignature');
      },
    }
  );

  const handleOnClick = () => {
    setEdit(true);
  };

  const handleCancelEditClick = () => {
    setEdit(!edit);
    setData(employeeData);
  };

  //* Phone validation
  const validatePhoneNumber = (values) => {
    const isValidMobileNumber = VALID_MOBILE_NUMBER.test(values);
    return isValidMobileNumber
      ? ''
      : get(settings, 'accountPage.account.invalidNumber', {
          default: 'invalidNumber',
        });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'firstName':
        if (value === '') {
          setErrorMessage({
            ...errorMessage,
            [name]: requiredLabel,
          });
          setCanSubmit(false);
        } else {
          const { firstName, ...newErrorMessage } = errorMessage;
          setErrorMessage({ ...newErrorMessage });

          if (newErrorMessage && Object.keys(newErrorMessage).length === 0)
            setCanSubmit(true);
        }
        break;

      case 'lastName':
        if (value === '') {
          setErrorMessage({
            ...errorMessage,
            [name]: requiredLabel,
          });
          setCanSubmit(false);
        } else {
          const { lastName, ...newErrorMessage } = errorMessage;
          setErrorMessage({ ...newErrorMessage });

          if (newErrorMessage && Object.keys(newErrorMessage).length === 0)
            setCanSubmit(true);
        }
        break;

      case 'mobile':
        const validation = validatePhoneNumber(value);
        if (validation) {
          setErrorMessage({ ...errorMessage, mobile: validation });
          setCanSubmit(false);
        } else {
          const { mobile, ...newErrorMessage } = errorMessage;
          setErrorMessage({ ...newErrorMessage });

          if (newErrorMessage && Object.keys(newErrorMessage).length === 0)
            setCanSubmit(true);
        }
        break;
      default:
        setCanSubmit(true);
    }

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleOpenSignature = () => {
    setOpenSignature(true);
  };

  const handleCloseSignature = () => {
    setOpenSignature(false);
  };

  const handleAddSignature = (event) => {
    const { files } = event.target;
    if (files[0]) {
      setEmployeeSignature(files[0]);
      createEmployeeSignature({
        variables: { employeeID: employeeData.id },
      });
    }
  };

  const handleUpdateSignature = (event) => {
    const { files } = event.target;
    if (files[0]) {
      setEmployeeSignature(files[0]);
      updateEmployeeSignature({
        variables: {
          employeeID: employeeData.id,
        },
      });
    }
  };

  const uploadSignature = (file, path) => {
    const form = new FormData();
    form.append('myfile', file, path);
    APIHelper.prototype
      .fetchData(`/cdn/upload`, 'POST', form)
      .catch(console.error);
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <div className={classes.fields}>
            <Typography
              variant="subtitle1"
              gutterBottom
              className={classes.title}
            >
              {title}
            </Typography>
            {edit ? (
              <div className={classes.secondFieldStyle}>
                <Tooltip
                  title={get(settings, 'accountPage.tooltip.updateInfo', {
                    default: 'Edit info',
                  })}
                >
                  <IconButton
                    color="secondary"
                    onClick={() => saveEmployeeChanges(data)}
                    disabled={!canSubmit}
                  >
                    <DoneIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={get(settings, 'accountPage.tooltip.cancelInfo', {
                    default: 'Cancel edit',
                  })}
                >
                  <IconButton color="secondary" onClick={handleCancelEditClick}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div className={classes.secondFieldStyle}>
                <Button color="secondary" onClick={handleOnClick}>
                  {get(settings, 'accountPage.account.editInfo', {
                    default: 'editInfo',
                  })}
                </Button>
              </div>
            )}
          </div>
          <Divider />
          <CardRow
            rowTitle={get(settings, 'accountPage.account.lastName', {
              default: 'lastName',
            })}
            value={data.lastName || ''}
            edit={edit}
            name="lastName"
            onChange={handleChange}
            error={!!errorMessage.lastName || false}
            errorMessage={errorMessage.lastName || ''}
          />
          <Divider />
          <CardRow
            rowTitle={get(settings, 'accountPage.account.firstName', {
              default: 'firstName',
            })}
            value={data.firstName || ''}
            edit={edit}
            name="firstName"
            onChange={handleChange}
            error={!!errorMessage.firstName || false}
            errorMessage={errorMessage.firstName || ''}
          />
          <Divider />
          <CardRow
            rowTitle={get(settings, 'accountPage.account.birthDate', {
              default: 'birthDate',
            })}
            value={data.birthDate}
            edit={edit}
            name="birthDate"
            onChange={handleChange}
            type="date"
          />
          <Divider />
          <CardRow
            rowTitle={get(settings, 'accountPage.account.mobile', {
              default: 'mobile',
            })}
            value={data.mobile || ''}
            edit={edit}
            name="mobile"
            error={!!errorMessage.mobile || false}
            errorMessage={errorMessage.mobile || ''}
            onChange={handleChange}
          />
          <Divider />
          <div className={classes.fields}>
            <Typography variant="body1">
              {get(settings, 'accountPage.account.email', {
                default: 'email',
              })}
            </Typography>
            <div className={classes.secondFieldStyle}>
              <Typography>{data.email || ''}</Typography>
            </div>
          </div>
          <Divider />
          <div className={classes.fields}>
            <Typography variant="body1">
              {get(settings, 'accountPage.account.password', {
                default: 'password',
              })}
            </Typography>
            <div className={classes.secondFieldStyle}>
              <Button
                color="secondary"
                className={classes.passwordButton}
                onClick={() => setOpenForm(true)}
              >
                {get(settings, 'accountPage.account.changePassword', {
                  default: 'changePassword',
                })}
              </Button>
            </div>
          </div>
          <Divider />
          <div className={classes.fields}>
            <Typography variant="body1">
              {get(settings, 'accountPage.account.signature', {
                default: 'signature',
              })}
            </Typography>
            <div className={classes.secondFieldStyle}>
              {data.signature ? (
                <>
                  <label htmlFor="contained-button-file">
                    <Input
                      style={{ display: 'none' }}
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      name="logo"
                      onChange={handleUpdateSignature}
                    />
                    <Button
                      color="secondary"
                      component="span"
                      className={classes.passwordButton}
                    >
                      {get(settings, 'accountPage.account.changeSignature', {
                        default: 'changeSignature',
                      })}
                    </Button>
                  </label>
                  <img
                    src={`${APIHelper.baseUrl}/cdn/${data.signature}`}
                    alt=""
                    style={{ height: '30px' }}
                    onClick={handleOpenSignature}
                  />
                </>
              ) : (
                <label htmlFor="contained-button-file">
                  <Input
                    style={{ display: 'none' }}
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    name="logo"
                    onChange={handleAddSignature}
                  />
                  <Button
                    color="secondary"
                    component="span"
                    className={classes.passwordButton}
                  >
                    {get(settings, 'accountPage.account.addSignature', {
                      default: 'addSignature',
                    })}
                  </Button>
                </label>
              )}
            </div>
          </div>
          <Divider />
        </CardContent>
      </Card>
      <PasswordForm
        settings={settings}
        openForm={openForm}
        handleOnClose={() => setOpenForm(false)}
        savePasswordChanges={savePasswordChanges}
      />
      <Modal open={openSignature} onClose={handleCloseSignature}>
        <div className={classes.paper}>
          <img
            src={`${APIHelper.baseUrl}/cdn/${data.signature}`}
            alt=""
            style={{ width: '350px' }}
          />
        </div>
      </Modal>
    </>
  );
};

export default CardDisplay;
