import React, { useState } from 'react';
import get from 'get-value';

// importing Material UI core
import {
  makeStyles,
  Card,
  Button,
  Divider,
  Typography,
  Grid,
} from '@material-ui/core';

// importing Material UI icons
import DateRangeIcon from '@material-ui/icons/DateRange';

// importing internal components
import { getLanguageSupport } from '../../utils/helper';
import Toolbar from '../../core/toolbar';
import FreeDaysDialog from './dialogs/freedays-edit';

const settings = getLanguageSupport();

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    width: '450px',
    height: 'auto',
    padding: '10px',
    marginTop: '2em',
    borderRadius: '5px',
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
  },
  daysButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '22px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  gridItem: {
    marginTop: '10px',
  },
}));

const Settings = () => {
  const classes = useStyles();

  const [openDaysDialog, setOpenDaysDialog] = useState(false);

  return (
    <div className="sectionContainer">
      <Toolbar
        title={get(settings, 'settings.title', {
          default: 'Settings',
        })}
      />

      <Card className={classes.root} variant="outlined">
        <Typography className={classes.title}>
          {get(settings, 'settings.cardTitle', {
            default: 'Project settings',
          })}
        </Typography>
        <Divider />
        <Grid
          container
          spacing={2}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item className={classes.gridItem}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<DateRangeIcon />}
              onClick={() => setOpenDaysDialog(true)}
              className={classes.daysButton}
            >
              {get(settings, 'attendancePage.freeDaysEdit', {
                default: 'Free Days',
              })}
            </Button>
          </Grid>
        </Grid>
      </Card>

      <FreeDaysDialog
        open={openDaysDialog}
        handleClose={() => setOpenDaysDialog(false)}
      />
    </div>
  );
};

export default Settings;
