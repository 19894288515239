import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import get from 'get-value';

// importing Material UI elements
import { Tooltip, IconButton, Link } from '@material-ui/core';

// importing Material UI icons
import { GetApp } from '@material-ui/icons';

// importing internal components
import DialogLayout from '../../../../components/dialogs/dialog-layout';
import ListContainer from '../../../../core/list-container';
import CircularUnderLoad from '../../../../components/loading-animation';
import APIHelper from '../../../../helpers/api-helper';

// Queries :
const readDocumentsFromDocumentRequestQuery = gql`
  query ($id: ID!) {
    readDocumentsFromDocumentRequest(id: $id) {
      id
      type
      description
      uploadURL
    }
  }
`;

const DownloadFiles = (props) => {
  const { openForm, settings, handleClose, documentRequestID, templateName } =
    props;

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [documentsData, setDocumentsData] = useState(null);

  const [readDocumentsData, {}] = useLazyQuery(
    readDocumentsFromDocumentRequestQuery,
    {
      onCompleted: (retrievedData) => {
        if (retrievedData && retrievedData.readDocumentsFromDocumentRequest) {
          setDocumentsData(retrievedData);
          setIsDataLoading(false);
        }
      },
    }
  );

  useEffect(() => {
    if (documentRequestID) {
      setIsDataLoading(true);
      readDocumentsData({
        variables: {
          id: documentRequestID,
        },
      });
    }
    return () => {
      setDocumentsData(null);
      setIsDataLoading(true);
    };
  }, [documentRequestID]);

  // Define DataGrid columns
  const columns = [
    {
      field: 'description',
      headerName: get(settings, 'documents.dataGridColumns.description', {
        default: 'Description',
      }),
      width: 330,
    },
    {
      field: 'type',
      headerName: get(settings, 'documents.dataGridColumns.documentType', {
        default: 'Type',
      }),
      width: 100,
      valueFormatter: ({ value }) =>
        get(settings, `registry.documentTypeSelectLabel.${value}`, {
          default: value,
        }),
    },
    {
      field: 'download',
      headerName: get(settings, 'documents.dataGridColumns.download', {
        default: 'Download',
      }),
      width: 120,
      align: 'center',
      renderCell: (params) => {
        return (
          <Tooltip
            title={get(settings, 'documents.dataGridColumns.download', {
              default: 'Download',
            })}
          >
            <Link
              href={`${APIHelper.baseUrl}/cdn/${params.row.uploadURL}`}
              target="_blank"
              rel="noreferrer"
            >
              <IconButton>
                <GetApp />
              </IconButton>
            </Link>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <DialogLayout
      showSubmit={false}
      formTitle={templateName}
      open={openForm}
      formCancelButton={get(settings, 'formDialog.closeButtonLabel', {
        default: 'Exit',
      })}
      handleClose={handleClose}
    >
      {isDataLoading ? (
        <CircularUnderLoad size={40} />
      ) : (
        <ListContainer
          miniListContainer
          checkboxSelection={false}
          items={documentsData.readDocumentsFromDocumentRequest}
          loading={false}
          columns={columns}
        />
      )}
    </DialogLayout>
  );
};

export default DownloadFiles;
