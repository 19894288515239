import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import get from 'get-value';

// importing material UI elements
import {
  Avatar,
  ListItem,
  makeStyles,
  Typography,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core';

// importing Material UI icons
import { Check, Warning } from '@material-ui/icons';

// importing components
import ConfirmForm from './confirm-form';
import CircularUnderLoad from '../loading-animation';
import withSnackbar from '../../utils/with-snackbar';

// importing constants
import { SNACKBAR_SEVERITY } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  confirmDialogInfo: {
    fontWeight: 'bold',
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
}));

const RemoveForm = (props) => {
  const {
    settings,
    open,
    handleCloseForm,
    itemID,
    handleRemove,
    itemName,
    collection,
    query,
  } = props;

  const classes = useStyles();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [removeItemData, setRemoveItemData] = useState('');

  const [readEmployee] = useLazyQuery(query, {
    onCompleted: (data) => {
      if (data) {
        setRemoveItemData(data.countEmployees);
        setIsDataLoading(false);
      } else {
        props.showSnackbar(
          get(settings, `confirmDialog.errorLoadingData`, {
            default: 'Error on loading data.',
          }),
          SNACKBAR_SEVERITY.ERROR
        );
      }
    },
  });

  useEffect(() => {
    if (itemID) {
      setIsDataLoading(true);
      readEmployee({
        variables: {
          id: itemID,
        },
      });
    }
  }, [open]);

  return (
    <ConfirmForm
      settings={settings}
      open={!!open}
      title={`${get(settings, 'confirmDialog.dialogTitleRemove', {
        default: '',
      })} ${get(settings, `${collection}.confirmDialog.title`, {
        default: 'Confirm dialog',
      })}`}
      disableSubmit={!!removeItemData}
      handleSubmit={handleRemove}
      handleClose={handleCloseForm}
    >
      {isDataLoading ? (
        <CircularUnderLoad />
      ) : (
        <>
          <Typography className={classes.confirmDialogInfo}>
            {!removeItemData
              ? get(
                  settings,
                  `${collection}.confirmDialog.deletableItemsHeader`,
                  {
                    default: 'The item below will be deleted:',
                  }
                )
              : get(
                  settings,
                  `${collection}.confirmDialog.notDeletableItemsHeader`,
                  {
                    default: 'The item below cannot be deleted:',
                  }
                )}
          </Typography>
          {!removeItemData ? (
            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.success}>
                  <Check />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={itemName} />
            </ListItem>
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.warning}>
                  <Warning />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={itemName}
                secondary={`Usage: ${removeItemData} employees`}
                secondary={get(
                  settings,
                  `${collection}.confirmDialog.usageText`,
                  {
                    default: `Usage: ${removeItemData} employees`,
                  }
                ).replace('{numberOfEmployees}', removeItemData)}
              />
            </ListItem>
          )}
        </>
      )}
    </ConfirmForm>
  );
};

export default withSnackbar(RemoveForm);
