import React, { useState, useRef, useEffect } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import JoditEditor from 'jodit-react';
import { useParams, useHistory } from 'react-router-dom';
import get from 'get-value';

// importing Material UI elements
import {
  Chip,
  Grid,
  Button,
  Checkbox,
  makeStyles,
  FormControlLabel,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

// importing Material UI icons
import {
  Face,
  DateRange,
  CalendarToday,
  Assignment,
  Create,
  Send,
} from '@material-ui/icons';

// importing internal components
import Toolbar from '../../../core/toolbar';
import withSnackbar from '../../../utils/with-snackbar';
import CircularUnderLoad from '../../../components/loading-animation';
import TextField from '../../../components/forms/text-field';
import { buildFields } from '../../../helpers/document-helper';

// importing constants
import { SNACKBAR_SEVERITY, WORK_STATUS } from '../../../utils/constants';
import { DOCUMENT_TEMPLATE_FIELDS } from '../../../utils/form-constants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
    maxWidth: '100%',
    marginBottom: 15,
    textAlign: 'center',
  },
  chipStyle: {
    marginRight: 10,
    marginTop: 10,
  },
  gridContainer: {
    display: 'flex',
    padding: '10px 0 0 25px',
  },

  footerForm: {
    position: 'absolute',
    bottom: 0,
    width: 380,
  },
  helperTextEditor: {
    color: '#f44336',
    marginLeft: 20,
  },
  editorError: {
    border: '1px solid #f44336',
    margin: '20px 0 0 20px',
  },
  editor: {
    padding: '20px 0 0 20px',
    marginTop: 20,
  },
  sideBar: {
    padding: '0 10px 0 20px',
    marginTop: -10,
    position: 'relative',
  },
  divider: {
    color: '#7c7b7b',
    margin: '20px 0 0',
    fontWeight: 'bold',
  },
  applicationTypeSelect: {
    width: '50%',
  },
  addTemplateButton: {
    width: '100%',
    marginTop: 10,
  },
  editorContainer: {
    backgroundColor: '#ffffff',
  },
}));

// Queries
const readDocumentTemplateQuery = gql`
  query DocumentTemplate($documentID: ID!) {
    readDocumentTemplate(documentID: $documentID) {
      id
      name
      company {
        id
        companyName
      }
      verificationNeeded
      templateHTML
      documentType
    }
  }
`;

const readCompanyNameQuery = gql`
  query readCompany($id: ID!) {
    readCompany(id: $id) {
      companyName
    }
  }
`;

// Mutations
const addDocumentTemplateMutation = gql`
  mutation createDocumentTemplate(
    $name: String!
    $verificationNeeded: Boolean
    $templateHTML: String!
    $company: ID!
    $fields: [FieldInput]
    $documentType: String
  ) {
    createDocumentTemplate(
      name: $name
      verificationNeeded: $verificationNeeded
      templateHTML: $templateHTML
      company: $company
      fields: $fields
      documentType: $documentType
    ) {
      id
      name
      templateHTML
      verificationNeeded
      company {
        companyName
      }
      documentType
    }
  }
`;

const updateDocumentTemplateMutation = gql`
  mutation updateDocumentTemplate(
    $id: ID!
    $name: String!
    $verificationNeeded: Boolean
    $templateHTML: String!
    $company: ID!
    $fields: [FieldInput]
    $documentType: String
  ) {
    updateDocumentTemplate(
      id: $id
      name: $name
      verificationNeeded: $verificationNeeded
      templateHTML: $templateHTML
      company: $company
      fields: $fields
      documentType: $documentType
    )
  }
`;

const TemplateForm = (props) => {
  const classes = useStyles();
  const { settings } = props;

  const history = useHistory();
  const templateID = useParams().id;
  const isCreatePage = !templateID;

  const createdDocumentTemplate = localStorage.getItem(
    'createdDocumentTemplate'
  );
  localStorage.removeItem('createdDocumentTemplate');

  useEffect(() => {
    const companyID = localStorage.getItem('companyId');
    localStorage.removeItem('companyId');
    !isCreatePage &&
      readDocumentTemplate({ variables: { documentID: templateID } });

    if (companyID) {
      readCompanyName({
        variables: {
          id: companyID,
        },
      });
      setInputParams({ ...inputParams, company: companyID });
    }

    createdDocumentTemplate &&
      props.showSnackbar(
        get(
          settings,
          'documentTemplate.snackbar.successfullyCreatedDocumentTemplate',
          { default: 'Success!' }
        ),
        SNACKBAR_SEVERITY.SUCCESS
      );
  }, []);

  // Employee chip information
  const chipsInfo = {
    'employee.lastName': get(settings, 'documentTemplate.chipsInfo.lastName', {
      default: 'Employee last name',
    }),
    'employee.firstName': get(
      settings,
      'documentTemplate.chipsInfo.firstName',
      {
        default: 'Employee first name',
      }
    ),
    'employee.signature': get(
      settings,
      'documentTemplate.chipsInfo.signature',
      {
        default: 'Employee signature',
      }
    ),
    'employee.ssn': get(settings, 'documentTemplate.chipsInfo.ssn', {
      default: 'Employee ssn',
    }),
    'employee.idCardSeries': get(
      settings,
      'documentTemplate.chipsInfo.idCardSeries',
      {
        default: 'Employee ssn',
      }
    ),
    'employee.idCardNumber': get(
      settings,
      'documentTemplate.chipsInfo.idCardNumber',
      {
        default: 'Employee ssn',
      }
    ),

    'employee.positionName': get(
      settings,
      'documentTemplate.chipsInfo.positionName',
      {
        default: 'Position name of employee ',
      }
    ),
    'employee.dateOfEmployment': get(
      settings,
      'documentTemplate.chipsInfo.dateOfEmployment',
      {
        default: 'Employee date of employment ',
      }
    ),
    'employee.contractType': get(
      settings,
      'documentTemplate.chipsInfo.contractType',
      {
        default: 'Contract type of employee ',
      }
    ),
  };

  //employees date infos
  const datesChipsInfo = {
    dateFrom: get(settings, 'documentTemplate.chipsInfo.dateFrom', {
      default: 'Date from',
    }),
    dateTo: get(settings, 'documentTemplate.chipsInfo.dateTo', {
      default: 'Date to',
    }),
    numberOfDays: get(settings, 'documentTemplate.chipsInfo.numberOfDays', {
      default: 'Days',
    }),
    replacementName: get(
      settings,
      'documentTemplate.chipsInfo.replacementName',
      {
        default: 'Replacement Name',
      }
    ),
  };

  const genericChipsInfo = {
    'company.lastRegistrationNumber': get(
      settings,
      'documentTemplate.chipsInfo.lastRegistrationNumber',
      {
        default: 'Registry number',
      }
    ),
    documentDestination: get(
      settings,
      'documentTemplate.chipsInfo.documentDestination',
      {
        default: 'Document destination',
      }
    ),
    'system.currentDate': get(
      settings,
      'documentTemplate.chipsInfo.currentDate',
      {
        default: 'Current date',
      }
    ),
    'system.currentYear': get(
      settings,
      'documentTemplate.chipsInfo.currentYear',
      {
        default: 'Current year',
      }
    ),
  };

  const getAvatar = (key) => {
    switch (key) {
      case chipsInfo['employee.lastName']:
      case chipsInfo['employee.firstName']:
      case chipsInfo['employee.ssn']:
      case chipsInfo['employee.idCardSeries']:
      case chipsInfo['employee.idCardNumber']:
      case chipsInfo['employee.positionName']:
      case datesChipsInfo.replacementName:
        return <Face />;
      case datesChipsInfo.dateFrom:
      case datesChipsInfo.dateTo:
      case datesChipsInfo.numberOfDays:
      case chipsInfo['employee.dateOfEmployment']:
        return <DateRange />;
      case genericChipsInfo['system.currentDate']:
      case genericChipsInfo['system.currentYear']:
        return <CalendarToday />;
      case genericChipsInfo['company.lastRegistrationNumber']:
      case chipsInfo['employee.contractType']:
        return <Assignment />;
      case chipsInfo['employee.signature']:
        return <Create />;
      case genericChipsInfo.documentDestination:
        return <Send />;
      default:
        return null;
    }
  };

  const APPLICATION_TYPES = [
    {
      label: get(
        settings,
        `documentTemplate.applicationTypes.${WORK_STATUS.REST}`,
        {
          default: 'Annual leave',
        }
      ),
      value: WORK_STATUS.REST,
    },
    {
      label: get(
        settings,
        `documentTemplate.applicationTypes.${WORK_STATUS.MATERNAL_LEAVE}`,
        {
          default: 'Maternity leave',
        }
      ),
      value: WORK_STATUS.MATERNAL_LEAVE,
    },
    {
      label: get(
        settings,
        `documentTemplate.applicationTypes.${WORK_STATUS.PARENTAL_LEAVE}`,
        {
          default: 'Parental leave',
        }
      ),
      value: WORK_STATUS.PARENTAL_LEAVE,
    },
  ];

  // Mutations:
  const [addDocumentTemplate, {}] = useMutation(addDocumentTemplateMutation, {
    onCompleted: (res) => {
      localStorage.setItem('createdDocumentTemplate', true);
      history.push(`/document-template/${res.createDocumentTemplate.id}`);
    },
  });

  const [updateDocumentTemplate, {}] = useMutation(
    updateDocumentTemplateMutation
  );

  //inputs state
  const [inputParams, setInputParams] = useState(DOCUMENT_TEMPLATE_FIELDS);
  const [inputErrors, setInputErrors] = useState({
    name: '',
    company: '',
    editor: '',
    documentType: '',
  });

  // state for the editor
  let editorRef;
  const editor = useRef(null);
  const [editorContent, setEditorContent] = useState('');

  // update Document template
  const [readDocumentTemplate, { loading: DocumentTemplateLoading }] =
    useLazyQuery(readDocumentTemplateQuery, {
      onCompleted: (result) => {
        setInputParams({
          name: result.readDocumentTemplate.name,
          company: result.readDocumentTemplate.company.id,
          companyName: result.readDocumentTemplate.company.companyName,
          verificationNeeded: result.readDocumentTemplate.verificationNeeded,
          documentType: result.readDocumentTemplate.documentType,
        });
        setEditorContent(result.readDocumentTemplate.templateHTML);
      },
    });

  const [readCompanyName] = useLazyQuery(readCompanyNameQuery, {
    onCompleted: (result) => {
      setInputParams({
        ...inputParams,
        companyName: result.readCompany.companyName,
      });
    },
  });

  // add or update
  const handleSubmit = () => {
    const formIncomplete = checkFormIncomplete();
    if (!formIncomplete) {
      if (isCreatePage) {
        handleCreateDocTemplate();
      } else {
        handleUpdateDocTemplate();
      }
    }
  };

  const checkFormIncomplete = () => {
    let formIncomplete = false;
    let errors = {};

    Object.entries(inputParams).map(([name, value]) => {
      // daca nu include verificationNeeded si url si nu are valoare
      if (
        !['verificationNeeded', 'url', 'documentType'].includes(name) &&
        !value
      ) {
        formIncomplete = true;

        errors = {
          ...errors,
          [name]: get(settings, 'formDialog.requiredInput', {
            default: 'Required!',
          }),
        };
      }
    });

    if (editor.current.value === '') {
      errors = {
        ...errors,
        editor: get(settings, 'formDialog.requiredInput', {
          default: 'Required!',
        }),
      };

      formIncomplete = true;
    }

    setInputErrors(errors);
    return formIncomplete;
  };

  const handleCreateDocTemplate = () => {
    const templateFields = buildFields(editor.current.value);

    addDocumentTemplate({
      variables: {
        name: inputParams.name,
        verificationNeeded: inputParams.verificationNeeded,
        templateHTML: editor.current.value,
        company: inputParams.company,
        fields: templateFields,
        documentType: inputParams.documentType,
      },
    });
  };

  const handleUpdateDocTemplate = () => {
    const templateFields = buildFields(editor.current.value);

    updateDocumentTemplate({
      variables: {
        id: templateID,
        name: inputParams.name,
        verificationNeeded: inputParams.verificationNeeded,
        templateHTML: editor.current.value,
        company: inputParams.company,
        fields: templateFields,
        documentType: inputParams.documentType,
      },
    }).then((result) => {
      const { errors } = result;
      if (errors) {
        props.showSnackbar(
          get(settings, 'access.serverError', { default: 'Error!' }),
          SNACKBAR_SEVERITY.ERROR
        );
      } else {
        props.showSnackbar(
          get(
            settings,
            'documentTemplate.snackbar.successfullyUpdatedDocumentTemplate',
            { default: 'Success!' }
          ),
          SNACKBAR_SEVERITY.SUCCESS
        );
      }
    });
  };

  // editor config
  const config = {
    readonly: false,
    minHeight: 700,
    placeholder: '',
    askBeforePasteHTML: false,
    defaultActionOnPaste: 'insert_only_text',
    toolbarInlineForSelection: false,
    uploader: {
      insertImageAsBase64URI: true,
    },
    disablePlugins: 'about,copy-format,clipboard',
    showSelectAll: false,
  };

  const handleSelect = (e) => {
    setInputParams({ ...inputParams, documentType: e.target.value });
  };

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === 'verificationNeeded') {
      setInputParams({ ...inputParams, [name]: checked, documentType: 'RL' });
    } else {
      inputErrors[name] !== '' &&
        setInputErrors({ ...inputErrors, [name]: '', documentType: '' });
      setInputParams({ ...inputParams, [name]: value });
    }
  };

  const onBlurEditor = (currentValue, event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onEditorChange = (editorNewValue) => {
    inputErrors.editor !== '' && setInputErrors({ ...inputErrors, editor: '' });
    editor.current.value = editorNewValue;
  };

  const handleChipClick = (chip) => {
    let textContainer = document.createElement('span');
    textContainer.innerHTML = ` {${chip}} `;
    editorRef.selection.insertNode(textContainer);
  };

  if (DocumentTemplateLoading)
    return (
      <div className="sectionContainer">
        <Toolbar
          title={get(settings, 'addDocumentTemplate.toolbar.title', {
            default: 'Document template',
          })}
          buttons={[]}
          backwardLink={`/templates}`}
        />
        <CircularUnderLoad />
      </div>
    );

  return (
    <div className="sectionContainer">
      <Toolbar
        title={get(settings, 'addDocumentTemplate.toolbar.title', {
          default: 'Document template',
        })}
        buttons={[]}
        backwardLink={`/templates/${inputParams.company}`}
      />

      <FormControl
        error={!!inputErrors.editor}
        className={inputErrors.editor ? classes.editorError : classes.editor}
      >
        <Grid container>
          <Grid item md={9} className={classes.editorContainer}>
            <JoditEditor
              id="idEditor"
              editorRef={(ref) => {
                editorRef = ref;
              }}
              ref={editor}
              name="content"
              value={editorContent}
              config={config}
              tabIndex={1} // tabIndex of textarea
              onBlur={onBlurEditor}
              onChange={onEditorChange}
              errorMessages="true"
            />
          </Grid>
          <Grid item md={3} className={classes.sideBar}>
            <TextField
              className={classes.documentName}
              value={inputParams.name}
              handleChange={handleInputChange}
              variant="standard"
              size="small"
              length={12}
              error={inputErrors.name}
              name="name"
              label={get(
                settings,
                'documentTemplate.fieldsLabel.documentName',
                {
                  default: 'Document name',
                }
              )}
            />

            <TextField
              value={inputParams.companyName}
              label={get(settings, 'documentTemplate.fieldsLabel.company', {
                default: 'Company',
              })}
              variant="standard"
              name="company"
              size="small"
              length={12}
              disabled
            />

            <div className={classes.divider}>
              {get(settings, 'documentTemplate.chipsDivider.employees', {
                default: 'Employees',
              })}
            </div>

            {Object.keys(chipsInfo).map((chip) => (
              <Chip
                key={chip}
                className={classes.chipStyle}
                avatar={getAvatar(chipsInfo[chip])}
                clickable
                variant="outlined"
                label={chipsInfo[chip]}
                onClick={() => handleChipClick(chip)}
              />
            ))}

            <div className={classes.divider}>
              {get(settings, 'documentTemplate.chipsDivider.dynamicFields', {
                default: 'Dynamic fields',
              })}
            </div>

            {Object.keys(datesChipsInfo).map((chip) => (
              <Chip
                key={chip}
                className={classes.chipStyle}
                avatar={getAvatar(datesChipsInfo[chip])}
                clickable
                variant="outlined"
                label={datesChipsInfo[chip]}
                onClick={() => handleChipClick(chip)}
              />
            ))}

            <div className={classes.divider}>
              {get(settings, 'documentTemplate.chipsDivider.genericData', {
                default: 'Generic data',
              })}
            </div>

            {Object.keys(genericChipsInfo).map((chip) => (
              <Chip
                key={chip}
                className={classes.chipStyle}
                avatar={getAvatar(genericChipsInfo[chip])}
                clickable
                variant="outlined"
                label={genericChipsInfo[chip]}
                onClick={() => handleChipClick(chip)}
              />
            ))}
            <div className={classes.footerForm}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="verificationNeeded"
                    checked={inputParams.verificationNeeded}
                    onChange={handleInputChange}
                    color="primary"
                    size="small"
                  />
                }
                label={get(
                  settings,
                  'documentTemplate.fieldsLabel.verificationNeeded',
                  {
                    default: 'Verification needed',
                  }
                )}
              />
              {inputParams.verificationNeeded && (
                <Select
                  autoWidth
                  name="applicationType"
                  onChange={handleSelect}
                  className={classes.applicationTypeSelect}
                  value={inputParams.documentType}
                  displayEmpty
                >
                  {APPLICATION_TYPES.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleSubmit}
                className={classes.addTemplateButton}
              >
                {isCreatePage
                  ? get(settings, 'documentTemplate.submitButton.addTemplate', {
                      default: 'Add template',
                    })
                  : get(
                      settings,
                      'documentTemplate.submitButton.updateTemplate',
                      {
                        default: 'Update template',
                      }
                    )}
              </Button>
            </div>
          </Grid>
        </Grid>
      </FormControl>
      {inputErrors.editor && (
        <FormHelperText className={classes.helperTextEditor}>
          {inputErrors.editor}
        </FormHelperText>
      )}
    </div>
  );
};
export default withSnackbar(TemplateForm);
