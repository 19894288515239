import React from 'react';
import { Divider, makeStyles, Typography, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { observer } from 'mobx-react';

import { useStore } from '../../../stores';
import {
  calculateWorkingTime,
  minutesToHoursAndMinutes,
} from '../../../helpers/date-helper';

const useStyles = makeStyles({
  fields: {
    padding: '25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hours: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconButton: {
    marginLeft: '5px',
  },
});

function calculateHours(schedule, currentDay) {
  if (
    schedule &&
    schedule[currentDay] &&
    schedule[currentDay].every((step) => !!step.stepOut && !!step.stepIn)
  ) {
    return minutesToHoursAndMinutes(calculateWorkingTime(schedule[currentDay]));
  }

  return '-';
}

const WorkingScheduleRow = observer(({ name, currentDay, onEdit }) => {
  const classes = useStyles();
  const mainStore = useStore();

  const { employeeData } = mainStore;
  const hours = calculateHours(employeeData.workingSchedule, currentDay);

  return (
    <>
      <Divider />
      <div className={classes.fields}>
        <Typography disabled>{name}</Typography>

        <div className={classes.hours}>
          <Typography disabled>{hours}</Typography>
          <IconButton
            className={classes.iconButton}
            size="small"
            edge="end"
            onClick={onEdit}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    </>
  );
});

export default WorkingScheduleRow;
