import React, { useState, useEffect } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import get from 'get-value';

// importing material UI elements
import { Grid, Button, Input } from '@material-ui/core';

// importing internal components
import FormGroup from '../../../../components/forms/form-group';
import TextField from '../../../../components/forms/text-field';
import DialogLayout from '../../../../components/dialogs/dialog-layout';
import CircularUnderLoad from '../../../../components/loading-animation';
import withSnackbar from '../../../../utils/with-snackbar';

// importing constants
import { SNACKBAR_SEVERITY } from '../../../../utils/constants';
import { COMPANY_FIELDS } from '../../../../utils/form-constants';
import { checkIBAN, checkSSN } from '../../../../helpers/companies-helper';
import APIHelper from '../../../../helpers/api-helper';

// Queries:
const readCompanyQuery = gql`
  query readCompany($id: ID!) {
    readCompany(id: $id) {
      id
      companyName
      vatNumber
      registrationNumber
      address
      logo
      representatives {
        firstName
        lastName
        ssn
      }
      bankAccounts {
        bankName
        iban
      }
    }
  }
`;

const readCompaniesNamesQuery = gql`
  query readCompaniesNames($excludedID: ID) {
    readCompaniesNames(excludedID: $excludedID)
  }
`;

// Mutations:
const createCompanyMutation = gql`
  mutation (
    $companyName: String!
    $vatNumber: String!
    $registrationNumber: String!
    $address: String!
    $representatives: [RepresentativeInput]!
    $bankAccounts: [BankAccountInput]!
    $logo: String
  ) {
    createCompany(
      companyName: $companyName
      vatNumber: $vatNumber
      registrationNumber: $registrationNumber
      address: $address
      representatives: $representatives
      bankAccounts: $bankAccounts
      logo: $logo
    )
  }
`;

const updateCompanyMutation = gql`
  mutation (
    $id: ID!
    $companyName: String!
    $vatNumber: String!
    $registrationNumber: String!
    $address: String!
    $representatives: [RepresentativeInput]!
    $bankAccounts: [BankAccountInput]!
    $logo: String
  ) {
    updateCompany(
      id: $id
      companyName: $companyName
      vatNumber: $vatNumber
      registrationNumber: $registrationNumber
      address: $address
      representatives: $representatives
      bankAccounts: $bankAccounts
      logo: $logo
    )
  }
`;

const CompanyForm = ({
  settings,
  handleSubmitForm,
  handleCloseForm,
  open,
  companyID,
}) => {
  const [companyLogo, setCompanyLogo] = useState(undefined);
  const [formInputs, setFormInputs] = useState(COMPANY_FIELDS);
  const [formInputErrors, setFormInputErrors] = useState(COMPANY_FIELDS);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [currentCompanies, setCurrentCompanies] = useState([]);

  const isCreateForm = !companyID;

  const [readCompany] = useLazyQuery(readCompanyQuery, {
    onCompleted: (data) => {
      if (data && data.readCompany) {
        setFormInputs(data.readCompany);
        setIsDataLoading(false);
      } else {
        props.showSnackbar(
          get(settings, `formDialog.errorLoadingData`, {
            default: 'Error on loading data.',
          }),
          SNACKBAR_SEVERITY.ERROR
        );
      }
    },
  });

  const [readCompaniesNames] = useLazyQuery(readCompaniesNamesQuery, {
    onCompleted: (data) => {
      if (data && data.readCompaniesNames) {
        setCurrentCompanies(data.readCompaniesNames);
        setIsDataLoading(false);
      } else {
        props.showSnackbar(
          get(settings, `formDialog.errorLoadingData`, {
            default: 'Error on loading data.',
          }),
          SNACKBAR_SEVERITY.ERROR
        );
      }
    },
  });

  useEffect(() => {
    if (companyID) {
      setIsDataLoading(true);
      readCompany({
        variables: {
          id: companyID,
        },
      });
      readCompaniesNames({
        variables: {
          excludedID: companyID,
        },
      });
    } else {
      readCompaniesNames();
      setIsDataLoading(false);
    }
  }, [open]);

  const uploadLogo = (path) => {
    const form = new FormData();
    form.append('myfile', companyLogo, path);

    APIHelper.prototype
      .fetchData(`/cdn/upload`, 'POST', form)
      .catch(console.error);
  };

  // Mutation functions:
  const [createCompany] = useMutation(createCompanyMutation, {
    onCompleted: (company) => {
      if (companyLogo) uploadLogo(company.createCompany);

      handleClose();
      handleSubmitForm('successfullyCreatedMessage');
    },
  });

  const handleCreateCompany = () => {
    createCompany({ variables: formInputs });
  };

  const [updateCompany] = useMutation(updateCompanyMutation, {
    onCompleted: (company) => {
      if (companyLogo) uploadLogo(company.updateCompany);

      handleClose();
      handleSubmitForm('successfullyUpdatedMessage');
    },
  });

  const handleUpdateCompany = () => {
    const { id, logo, ...company } = formInputs;
    updateCompany({
      variables: { ...company, id: id, logo: companyLogo ? logo : undefined },
    });

    handleCloseForm();
  };

  const handleChange = (event, updatedIndex, updatedKey) => {
    const { name, value, files, errorValue } = event.target;
    if (formInputErrors[name] !== '') {
      if (!Array.isArray(value)) {
        setFormInputErrors({ ...formInputErrors, [name]: '' });
      } else {
        const updatedObject = formInputErrors[name].map((item, index) => {
          if (index === updatedIndex) {
            return { ...item, [updatedKey]: '' };
          }
          return item;
        });
        setFormInputErrors({
          ...formInputErrors,
          [name]: updatedObject,
        });
      }
    }
    errorValue &&
      setFormInputErrors({ ...formInputErrors, [name]: errorValue });
    if (files) {
      setCompanyLogo(files[0]);
      const logoValue = value.split('\\').slice(-1)[0];
      setFormInputs({ ...formInputs, [name]: logoValue });
    } else {
      setFormInputs({ ...formInputs, [name]: value });
    }
  };

  const checkFormIncomplete = () => {
    let formIncomplete = false;

    const validateCompanyName = (companyName) => {
      return currentCompanies.includes(companyName)
        ? get(settings, 'companies.formDialog.alreadyExistCompany', {
            default: 'Company already exists!',
          })
        : '';
    };

    const getGroupErrors = (groupValue) => {
      return groupValue.map((groupFields) =>
        Object.fromEntries(
          Object.entries(groupFields).map(([fieldName, fieldValue]) => {
            if (!fieldValue) formIncomplete = true;
            if (fieldName === 'ssn' && fieldValue) {
              const validation = checkSSN(fieldValue, settings);
              if (validation) formIncomplete = true;
              return [fieldName, validation];
            }
            if (fieldName === 'iban' && fieldValue) {
              const validation = checkIBAN(fieldValue, settings);
              if (validation) formIncomplete = true;
              return [fieldName, validation];
            }
            return [
              fieldName,
              !fieldValue
                ? get(settings, 'formDialog.requiredInput', {
                    default: 'Required',
                  })
                : '',
            ];
          })
        )
      );
    };

    // TODO: refactor this part of code
    let formInputErrors = { ...COMPANY_FIELDS };
    Object.entries(formInputs).map(([name, value]) => {
      // Check if company name already exist:
      if (name === 'companyName' && value) {
        const isInvalid = validateCompanyName(value);
        if (isInvalid) formIncomplete = true;
        formInputErrors = { ...formInputErrors, [name]: isInvalid };
      }
      // Skip logo field:
      else if (name === 'logo') return;
      // Check if it has empty field(s):
      else if (!value) {
        formIncomplete = true;
        formInputErrors = {
          ...formInputErrors,
          [name]: get(settings, 'formDialog.requiredInput', {
            default: 'Required',
          }),
        };
      }
      // Check if it has empty group(s):
      else if (Array.isArray(value)) {
        formInputErrors = {
          ...formInputErrors,
          [name]: getGroupErrors(value),
        };
      }
    });
    setFormInputErrors(formInputErrors);

    return formIncomplete;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formIncomplete = checkFormIncomplete();
    if (!formIncomplete) {
      isCreateForm ? handleCreateCompany() : handleUpdateCompany();
    }
  };

  const handleClose = () => {
    setFormInputs(COMPANY_FIELDS);
    setFormInputErrors(COMPANY_FIELDS);
    setCompanyLogo(undefined);
    handleCloseForm();
  };

  return (
    <DialogLayout
      open={!!open}
      formTitle={
        isCreateForm
          ? get(settings, 'companies.formDialog.createTitle')
          : get(settings, 'companies.formDialog.editTitle')
      }
      formSubmitButton={
        isCreateForm
          ? get(settings, 'formDialog.submitButtonLabel')
          : get(settings, 'formDialog.modifyButtonLabel')
      }
      formCancelButton={get(settings, 'formDialog.cancelButtonLabel')}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
    >
      {isDataLoading ? (
        <CircularUnderLoad />
      ) : (
        <>
          <Grid container spacing={2}>
            <TextField
              name="companyName"
              value={formInputs.companyName}
              label={get(
                settings,
                'companies.formDialog.fieldsLabel.companyName',
                {
                  default: 'Company name',
                }
              )}
              error={formInputErrors.companyName}
              handleChange={handleChange}
            />

            <TextField
              name="vatNumber"
              value={formInputs.vatNumber}
              label={get(
                settings,
                'companies.formDialog.fieldsLabel.vatNumber',
                {
                  default: 'VAT number',
                }
              )}
              error={formInputErrors.vatNumber}
              handleChange={handleChange}
            />

            <TextField
              name="registrationNumber"
              value={formInputs.registrationNumber}
              label={get(
                settings,
                'companies.formDialog.fieldsLabel.registrationNumber',
                { default: 'Registration Number' }
              )}
              error={formInputErrors.registrationNumber}
              handleChange={handleChange}
            />

            <TextField
              name="address"
              value={formInputs.address}
              label={get(settings, 'companies.formDialog.fieldsLabel.address', {
                default: 'Address',
              })}
              error={formInputErrors.address}
              handleChange={handleChange}
            />

            <TextField
              disabled
              value={formInputs.logo || ''}
              required={false}
              label={get(settings, 'companies.formDialog.fieldsLabel.logo', {
                default: 'Logo',
              })}
              InputLabelProps={{ shrink: true }}
            />
            <Grid item sm={6} xs>
              <label htmlFor="contained-button-file">
                <Input
                  style={{ display: 'none' }}
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  name="logo"
                  onChange={handleChange}
                />
                <Button variant="outlined" component="span">
                  {get(settings, 'companies.formDialog.fieldsLabel.upload', {
                    default: 'Upload',
                  })}
                </Button>
              </label>
            </Grid>
          </Grid>
          <FormGroup
            name="representatives"
            value={formInputs.representatives}
            defaultValue={COMPANY_FIELDS.representatives}
            label={get(
              settings,
              'companies.formDialog.fieldsLabel.representatives',
              { default: 'Representatives' }
            )}
            error={formInputErrors.representatives}
            handleChange={handleChange}
          />

          <FormGroup
            name="bankAccounts"
            value={formInputs.bankAccounts}
            defaultValue={COMPANY_FIELDS.bankAccounts}
            label={get(
              settings,
              'companies.formDialog.fieldsLabel.bankAccounts',
              {
                default: 'Bank Accounts',
              }
            )}
            error={formInputErrors.bankAccounts}
            handleChange={handleChange}
          />
        </>
      )}
    </DialogLayout>
  );
};

export default withSnackbar(CompanyForm);
