import React, { useEffect, useState } from 'react';

import { Grid, TextField as MaterialTextField } from '@material-ui/core';

const TextField = (props) => {
  const {
    settings,
    type = 'text',
    name = 'field-name',
    value = '',
    label = 'field-label',
    error = '',
    length = 6,
    required = true,
    handleChange = undefined,
    margin = 'dense',
    disabled = false,
    InputProps,
    ...restProps
  } = props;

  const [readOnly, setReadOnly] = useState(true);
  let timeoutHandle;

  useEffect(() => {
    if (!InputProps || !InputProps.readOnly) {
      timeoutHandle = setTimeout(() => {
        setReadOnly(false);
      }, 500);
    }

    return () => {
      clearTimeout(timeoutHandle);
    };
  }, []);

  return (
    <Grid item sm={length} xs={length}>
      <MaterialTextField
        fullWidth
        type={type}
        name={name}
        value={value ?? ''}
        label={label}
        margin={margin}
        required={required}
        error={!!error}
        helperText={error}
        onChange={handleChange}
        disabled={disabled}
        InputProps={{
          readOnly,
          ...InputProps,
        }}
        {...restProps}
      />
    </Grid>
  );
};
export default TextField;
