import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import get from 'get-value';

// importing material UI elements
import { Grid, makeStyles } from '@material-ui/core';
import { AddCircleOutlineOutlined } from '@material-ui/icons';

// importing components
import Toolbar from '../../core/toolbar';
import ToolbarFilter from '../../core/toolbar-filter';
import TeamsForm from './components/dialogs/teams-form';
import RemoveForm from '../../components/dialogs/remove-form';
import CardDisplay from './components/card-display';
import withSnackbar from '../../utils/with-snackbar';
import CircularUnderLoad from '../../components/loading-animation';
import { useStore } from '../../stores';

// importing constants
import { SNACKBAR_SEVERITY } from '../../utils/constants';

const useStyles = makeStyles((theme) => {
  return {
    addButton: {
      margin: '5px',
      color: '#ffffff',
      backgroundColor: '#3f51b5',
    },
    teams: {
      padding: '10px',
      [theme.breakpoints.down('xs')]: {
        padding: '0',
      },
    },
  };
});

// Queries:
const readAllTeamsQuery = gql`
  query Team($filter: String) {
    readTeams: readAllTeams(filter: $filter) {
      id
      teamName
      description
      logo
      members {
        member {
          initials
        }
      }
      totalMembers
    }
  }
`;

const readEmployeeTeamsQuery = gql`
  query Team($filter: String, $employeeID: ID!) {
    readTeams: readEmployeeTeams(filter: $filter, employeeID: $employeeID) {
      id
      teamName
      description
      logo
      members {
        member {
          initials
        }
      }
      totalMembers
    }
  }
`;

const countEmployeesQuery = gql`
  query readTeam($id: ID!) {
    countEmployees: countTeamEmployees(teamId: $id)
  }
`;

// Mutations:
const removeTeamMutation = gql`
  mutation($id: ID!) {
    removeTeam(id: $id) {
      id
      logo
    }
  }
`;

const Teams = (props) => {
  const { settings } = props;

  const classes = useStyles();
  const store = useStore();

  const { isAdmin, employeeID } = store.employeeData;

  // State
  const [filterValue, setFilterValue] = useState('');
  const [focusedTeam, setFocusedTeam] = useState(null);
  const [focusedItemName, setFocusedItemName] = useState('');
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // Handlers
  const handleFilterChange = (value) => {
    setFilterValue(value);
  };

  const handleSubmitForm = async (
    message,
    severity = SNACKBAR_SEVERITY.SUCCESS
  ) => {
    await refetch();
    props.showSnackbar(
      get(settings, `teams.snackbar.${message}`, { default: message }),
      severity
    );
  };
  const handleClickEdit = (team) => {
    setOpenFormDialog(true);
    setFocusedTeam(team);
  };

  const handleClickRemove = (team) => {
    setOpenConfirmDialog(true);
    setFocusedTeam(team.id);
    setFocusedItemName(team.teamName);
  };

  // Query functions:
  const { data, loading, refetch } = isAdmin
    ? useQuery(readAllTeamsQuery, {
        variables: { filter: filterValue },
      })
    : useQuery(readEmployeeTeamsQuery, {
        variables: {
          filter: filterValue,
          employeeID: employeeID,
        },
        skip: !employeeID,
      });

  // Mutation function:
  const [removeTeam, {}] = useMutation(removeTeamMutation, {
    onCompleted: () => {
      setOpenConfirmDialog(false);
      setFocusedTeam(null);
      setFocusedItemName('');
      handleSubmitForm('successfullyRemovedMessage');
    },
  });

  const handleRemoveTeam = async () => {
    await removeTeam({
      variables: { id: focusedTeam },
    });
  };

  // Stuff for toolbar
  const toolbarButtons = !data
    ? []
    : [
        {
          name: get(settings, 'teams.toolbar.addButton', {
            default: 'Add button',
          }),
          style: classes.addButton,
          icon: <AddCircleOutlineOutlined />,
          handleAction: () => setOpenFormDialog(true),
          disabled: !isAdmin,
        },
      ];

  return (
    <div className='sectionContainer'>
      <Toolbar
        title={get(settings, 'teams.toolbar.title')}
        buttons={toolbarButtons}
      >
        <ToolbarFilter
          settings={get(settings, 'toolbarFilter')}
          handleFilterChange={handleFilterChange}
        />
      </Toolbar>

      {loading ? (
        <CircularUnderLoad />
      ) : (
        <Grid container spacing={2} className={classes.teams}>
          {data?.readTeams?.map((team) => (
            <CardDisplay
              isAdmin={isAdmin}
              key={team.id}
              settings={settings}
              team={team}
              handleClickEdit={() => handleClickEdit(team.id)}
              handleClickRemove={() => handleClickRemove(team)}
              handleSubmitForm={handleSubmitForm}
            />
          ))}
        </Grid>
      )}

      <TeamsForm
        settings={settings}
        open={openFormDialog}
        teamID={focusedTeam}
        handleSubmitForm={handleSubmitForm}
        handleCloseForm={() => {
          setOpenFormDialog(false);
          setFocusedTeam(null);
        }}
      />

      <RemoveForm
        settings={settings}
        open={openConfirmDialog}
        handleCloseForm={() => {
          setOpenConfirmDialog(false);
          setFocusedTeam(null);
        }}
        itemID={focusedTeam}
        itemName={focusedItemName}
        collection='teams'
        handleRemove={handleRemoveTeam}
        query={countEmployeesQuery}
      />
    </div>
  );
};

export default withSnackbar(Teams);
