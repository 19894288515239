import React, { useState, useEffect } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import get from 'get-value';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

// importing Material UI elements
import { Divider, Typography, makeStyles, Grid } from '@material-ui/core';

// importing internal components
import DialogLayout from '../../../../components/dialogs/dialog-layout';
import DestinationsRender from '../destinations-render';
import UploadSection from '../upload-section';
import withSnackbar from '../../../../utils/with-snackbar';

import {
  calculateDays,
  FORM_FIELDS,
  completeSystemFields,
  filterFields,
  SOURCE_TYPES,
  inputPropsRender,
  uniqueArray,
  enumerateForFreeDay,
  MANDATORY_FIELDS,
} from '../../../../helpers/document-helper';
import { useStore } from '../../../../stores';

// importing constants
import {
  DOCUMENT_REQUEST_STATUS,
  SNACKBAR_SEVERITY,
} from '../../../../utils/constants';

import {
  DATE_FORMAT,
  momentDateFormat,
  SINGLE_DATE_FORMAT,
} from '../../../../helpers/date-helper';

import APIHelper from '../../../../helpers/api-helper';
import TextField from '../../../../components/forms/text-field';
import { getLanguageSupport } from '../../../../utils/helper';

const settings = getLanguageSupport();

const FORM_TITLE = get(settings, 'documents.formDialog.createTitle', {
  default: 'Create Title',
});

const SUBMIT_BUTTON = get(
  settings,
  'documents.formDialog.fieldsLabel.submitButton',
  {
    default: 'Submit',
  }
);

const CANCEL_BUTTON = get(settings, 'formDialog.cancelButtonLabel', {
  default: 'Cancel',
});

const UPLOAD_FILES = get(settings, 'documents.formDialog.fieldsLabel.upload', {
  default: 'Upload files',
});

const useStyles = makeStyles({
  padding: {
    padding: '3px',
  },
  secondDiv: {
    overflowX: 'auto',
    maxWidth: '600px',
    padding: '3px',
  },
  lastDiv: {
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: '120px',
  },
});

// Queries
const readRequiredData = gql`
  query ($documentID: ID!, $employeeID: ID!, $year: String!) {
    readDocumentTemplate(documentID: $documentID) {
      company {
        companyName
      }
      verificationNeeded
      fields {
        fieldName
        fieldType
        fieldSource
        fieldValue
      }
      documentType
    }
    readAllRequestDestinations(employeeID: $employeeID) {
      type
      destinations {
        fullName
        id
        userData {
          isAdmin
        }
      }
    }
    readAllFreeDaysForYear(year: $year) {
      year
      days
    }
  }
`;

// Mutations
const createDocumentRequest = gql`
  mutation createDocumentRequest(
    $date: DateTime!
    $source: String!
    $description: String
    $documentVerification: [DocumentVerificationInput]
    $fields: [FieldInput]
    $template: ID!
  ) {
    createDocumentRequest(
      date: $date
      source: $source
      description: $description
      documentVerification: $documentVerification
      fields: $fields
      template: $template
    ) {
      id
      date
    }
  }
`;

const createUploadedDocuments = gql`
  mutation createUploadedDocuments(
    $documentRequestID: ID!
    $uploadDocuments: [UploadDocumentsInput]
  ) {
    createUploadedDocuments(
      documentRequestID: $documentRequestID
      uploadDocuments: $uploadDocuments
    ) {
      returnDocuments {
        documentID
        uploadURL
      }
      requestID
      templateID
      companyID
    }
  }
`;

const CURRENT_YEAR = moment().format(SINGLE_DATE_FORMAT.YEAR);

const DocumentForm = (props) => {
  const {
    openForm,
    handleClose,
    documentID,
    refetchHistoryData,
    refetchPendingData,
    documentTitle,
  } = props;
  const store = useStore();
  const { employeeID } = store.employeeData;
  const DEFAULT_ARRAY_DATA = [{ description: '', uploadURL: '' }];

  let fieldsData;

  const classes = useStyles();
  const [dataLoading, setDataLoading] = useState(true);
  const [dialogData, setDialogData] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formInputs, setFormInputs] = useState({});
  const [systemInput, setSystemInput] = useState([]);
  const [dataArray, setDataArray] = useState(DEFAULT_ARRAY_DATA);
  const [filesArray, setFilesArray] = useState([]);
  const [adminDestination, setAdminDestination] = useState(null);
  const [adminRequired, setAdminRequired] = useState(false);

  const history = useHistory();

  const [readDocumentFormData] = useLazyQuery(readRequiredData, {
    onCompleted: (retrievedData) => {
      if (
        retrievedData &&
        retrievedData.readDocumentTemplate &&
        retrievedData.readAllRequestDestinations &&
        retrievedData.readAllFreeDaysForYear
      ) {
        setDialogData(retrievedData);

        let documentFields = {};
        let systemArray = [...systemInput];

        retrievedData.readDocumentTemplate.fields.forEach((fieldItem) => {
          documentFields[fieldItem.fieldName] = '';
          if (fieldItem.fieldSource === SOURCE_TYPES.system) {
            systemArray.push(fieldItem.fieldName);
          }
        });

        if (
          retrievedData.readAllRequestDestinations.length === 2 &&
          retrievedData.readAllRequestDestinations[1].destinations.length > 1
        ) {
          setAdminRequired(true);
        }

        setSystemInput(systemArray);
        setFormInputs(documentFields);
        setDataLoading(false);
      }
    },
  });

  const [uploadDocuments, {}] = useMutation(createUploadedDocuments, {
    onCompleted: async (data) => {
      uploadOnServer(data);
      props.showSnackbar(
        get(settings, 'documents.uploadDialog.successMessage', {
          default: 'Upload was successful!',
        }),
        SNACKBAR_SEVERITY.SUCCESS
      );
      handleClose();
    },
  });

  const handleDestinationChange = (event, values) => {
    setAdminDestination(values);

    if (!values) {
      setDisabled(true);
    }
  };

  useEffect(() => {
    if (documentID && openForm) {
      setDataLoading(true);
      readDocumentFormData({
        variables: { documentID, employeeID, year: CURRENT_YEAR },
      });
    }
  }, [documentID, openForm]);

  useEffect(() => {
    // TODO: Refactor file upload validation - Check if files to upload are correctly encoded
    // If document request data is not loaded don't check fields
    if (dataLoading) return;

    if (!isEmpty(formInputs)) {
      const allFieldsComplete = Object.keys(formInputs).every((fieldName) => {
        if (
          dialogData.readDocumentTemplate.verificationNeeded &&
          adminRequired &&
          !adminDestination
        )
          return false;
        if (!MANDATORY_FIELDS.includes(fieldName)) return true;

        return formInputs[fieldName].trim() !== '';
      });

      if (allFieldsComplete) {
        setDisabled(false);
      }
    }
  }, [formInputs, dataLoading, adminRequired, adminDestination]);

  const [sendDocumentRequest, {}] = useMutation(createDocumentRequest, {
    onCompleted: async () => {
      await refetchHistoryData();
      await refetchPendingData();
    },
  });

  const uploadOnServer = (data) => {
    let index = 0;

    data.createUploadedDocuments.returnDocuments.forEach((documentItem) => {
      const extension = documentItem.uploadURL.split('.');

      const documentPath = `documents/${
        data.createUploadedDocuments.companyID
      }/${data.createUploadedDocuments.templateID}/${moment().format(
        SINGLE_DATE_FORMAT.YEAR
      )}/${moment().format(SINGLE_DATE_FORMAT.MONTH)}/${[
        data.createUploadedDocuments.requestID,
        documentItem.documentID,
      ].join('_')}.${extension[1]}`;

      const form = new FormData();
      form.append('myfile', filesArray[index], documentPath);
      APIHelper.prototype
        .fetchData(`/cdn/upload`, 'POST', form)
        .then((index += 1));
    });
  };

  const handleChange = (event) => {
    let { name, value } = event.target;

    const freeDays = dialogData?.readAllFreeDaysForYear?.days ?? [];
    let hasFreeDay = 0;
    let start = formInputs?.dateFrom;
    let end = formInputs?.dateTo;
    // if selected interval contains a free day, recalculate period

    // dateTo -> dateFrom
    if (start !== '' && name === FORM_FIELDS.dateTo) {
      hasFreeDay = enumerateForFreeDay(start, value, freeDays);

      if (hasFreeDay !== 0) {
        value = moment(value).add(freeDays, 'days');
        value = value.format(DATE_FORMAT);
      }

      setFormInputs({
        ...formInputs,
        [FORM_FIELDS.numberOfDays]:
          hasFreeDay === 0
            ? String(calculateDays(start, value, freeDays))
            : String(calculateDays(start, value, freeDays) - hasFreeDay),
        [FORM_FIELDS.dateTo]: value,
      });

      return;
    }

    // dateFrom -> dateTo
    if (end !== '' && name === FORM_FIELDS.dateFrom) {
      hasFreeDay = enumerateForFreeDay(value, end, freeDays);

      if (hasFreeDay !== 0) {
        value = moment(value).subtract(freeDays, 'days');
        value = value.format(DATE_FORMAT);
      }

      setFormInputs({
        ...formInputs,
        [FORM_FIELDS.numberOfDays]:
          hasFreeDay === 0
            ? String(calculateDays(value, end, freeDays))
            : String(calculateDays(value, end, freeDays) - hasFreeDay),
        [FORM_FIELDS.dateFrom]: value,
      });

      return;
    }

    setFormInputs({ ...formInputs, [name]: value });

    if (value.trim() === '') {
      setDisabled(true);
    }
  };

  const handleCreateDocumentRequest = async () => {
    const currentDate = momentDateFormat();
    const documentVerificationArray = [];

    if (dialogData.readDocumentTemplate.verificationNeeded) {
      let currentDestinations = [];
      dialogData.readAllRequestDestinations[0].destinations.forEach(
        (requestItem) => {
          currentDestinations.push(requestItem.id);
        }
      );

      adminDestination && currentDestinations.push(adminDestination.id);

      currentDestinations = uniqueArray(currentDestinations);

      currentDestinations.forEach((destinationItem) => {
        documentVerificationArray.push({
          destination: destinationItem,
          status: DOCUMENT_REQUEST_STATUS.PENDING,
        });
      });
    }

    const requestFields = completeSystemFields(
      formInputs,
      dialogData.readDocumentTemplate.fields
    );

    return sendDocumentRequest({
      variables: {
        date: moment.utc(currentDate), // FIXME: Move date generation on backend
        source: employeeID,
        description: documentTitle,
        documentVerification: documentVerificationArray,
        fields: requestFields,
        template: documentID,
      },
    })
      .then(async (data) => {
        if (filesArray.length !== 0) {
          await uploadDocuments({
            variables: {
              documentRequestID: data.data.createDocumentRequest.id,
              uploadDocuments: dataArray,
            },
          });
        }

        return data.data.createDocumentRequest.id;
      })
      .catch(console.error);
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    setIsSubmitting(true);
    handleCreateDocumentRequest()
      .then((documentId) => {
        setFormInputs({});
        setSystemInput([]);
        setDisabled(true);
        setAdminRequired(false);
        handleClose();
        setFilesArray([]);
        setDataArray(DEFAULT_ARRAY_DATA);
        setAdminDestination(null);
        props.showSnackbar(
          get(settings, 'documents.formDialog.successTitle', {
            default: 'Document request was successful!',
          }),
          SNACKBAR_SEVERITY.SUCCESS
        );

        return documentId;
      })
      .then((documentId) => {
        setIsSubmitting(false);

        if (documentId) {
          history.push(`document-request-details/${documentId}/history`);
        }
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const handleCloseForm = (event) => {
    event.preventDefault();

    setFormInputs({});
    setSystemInput([]);
    setDisabled(true);
    setAdminRequired(false);
    setFilesArray([]);
    handleClose();
    setAdminDestination(null);
    setDataArray(DEFAULT_ARRAY_DATA);
  };

  if (!dataLoading) {
    fieldsData = filterFields(dialogData.readDocumentTemplate.fields);
  }

  const documentTypeLabel = get(
    settings,
    `documentTemplate.applicationTypes.${dialogData?.readDocumentTemplate?.documentType}`,
    { default: null }
  );

  return (
    <DialogLayout
      formTitle={FORM_TITLE}
      subtitle={documentTypeLabel}
      open={openForm}
      isButtonLoading={isSubmitting}
      formSubmitButton={SUBMIT_BUTTON}
      formCancelButton={CANCEL_BUTTON}
      handleSubmit={handleSubmitForm}
      handleClose={handleCloseForm}
      isLoading={dataLoading}
      disabledSubmit={disabled}
    >
      <div className={classes.padding}>
        <>
          {fieldsData && fieldsData.length > 0 && (
            <Grid container>
              {fieldsData.map((mapItem) => {
                if (mapItem.fieldSource === SOURCE_TYPES.user) {
                  return (
                    <TextField
                      length={12}
                      variant="outlined"
                      key={mapItem.fieldName}
                      type={mapItem.fieldType}
                      name={mapItem.fieldName}
                      value={formInputs ? formInputs[mapItem.fieldName] : ''}
                      label={get(
                        settings,
                        `documents.formDialog.fieldsLabel.${mapItem.fieldName}`,
                        {
                          default: `${mapItem.fieldName}`,
                        }
                      )}
                      InputProps={inputPropsRender(
                        mapItem.fieldName,
                        formInputs?.dateFrom || '',
                        formInputs?.dateTo || ''
                      )}
                      InputLabelProps={{ shrink: true }}
                      handleChange={handleChange}
                      disabled={mapItem.fieldName === FORM_FIELDS.numberOfDays}
                    />
                  );
                }
              })}
            </Grid>
          )}

          {dialogData?.readDocumentTemplate?.verificationNeeded && (
            <>
              <div className={classes.secondDiv}>
                <DestinationsRender
                  settings={settings}
                  destinations={dialogData.readAllRequestDestinations}
                  destinationsArray={adminDestination}
                  handleChange={handleDestinationChange}
                />
              </div>
              <Divider />
            </>
          )}
        </>
      </div>

      <div className={classes.padding}>
        <Typography color="textSecondary">{UPLOAD_FILES}</Typography>

        <div className={classes.lastDiv}>
          <UploadSection
            dataArray={dataArray}
            setDataArray={setDataArray}
            settings={settings}
            filesArray={filesArray}
            setFilesArray={setFilesArray}
          />
        </div>
      </div>
    </DialogLayout>
  );
};

export default withSnackbar(DocumentForm);
