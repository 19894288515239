import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = 'M62K24NMwsoQPdj0wyCBdLKivhGCZXCW';

export const encryptData = (email, password) => {
  const data = [{ savedEmail: email }, { savedPassword: password }];
  return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
};

export const decryptData = (key) => {
  try {
    if (key) {
      const bytes = CryptoJS.AES.decrypt(key, ENCRYPTION_KEY);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  } catch {}

  return [];
};

export const LOGIN_ERRORS = {
  INCORRECT_CREDENTIALS: 'incorrectCredentials',
  DISABLED_ACCOUNT: 'disabledAccount',
};
