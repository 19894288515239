import React from 'react';
import { Grid, Chip, Tooltip, makeStyles } from '@material-ui/core';
import get from 'get-value';

import AutocompleteField from '../../attendance/components/autocomplete-field';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export const AdminDestination = ({
  admins,
  settings,
  handleChange,
  adminDestination,
}) => {
  const classes = useStyles();

  if (!admins) {
    return null;
  }

  if (admins.length === 1) {
    return (
      <Grid item>
        <Tooltip
          title={get(settings, 'documents.formDialog.destinations', {
            default: 'Destinations',
          })}
        >
          <Chip label={admins[0].fullName} className={classes.chip} />
        </Tooltip>
      </Grid>
    );
  }

  if (admins.length !== 0) {
    return (
      <Grid item md={6} xs={12} className={classes.autocompleteContainer}>
        <AutocompleteField
          filterData={admins || []}
          handleChange={handleChange}
          filterElements={adminDestination}
          optionElement={'fullName'}
          filterPlaceHolder={get(settings, 'documents.formDialog.admins', {
            default: 'Admins',
          })}
        />
      </Grid>
    );
  }
};
