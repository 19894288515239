import moment from 'moment';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const RO_DATE_FORMAT = 'DD-MM-YYYY';
export const TIME_FORMAT = 'HH:mm';
export const RO_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm A';

export const SINGLE_DATE_FORMAT = {
  YEAR: 'YYYY',
  MONTH: 'M',
  DAYMONTH: 'DD/MM',
};

export const momentTimeFormat = (dateString) => {
  if (dateString) {
    return moment(new Date(dateString)).format(TIME_FORMAT);
  }
  return moment().format(TIME_FORMAT);
};

export const momentDateFormat = (dateTimeString) => {
  if (dateTimeString) {
    return moment(new Date(dateTimeString)).format(DATE_FORMAT);
  }
  return moment().format(DATE_FORMAT);
};

export const calculateWorkingTime = (hours) => {
  let totalWorkingMinutes = 0;
  hours.forEach((touple) => {
    if (touple.stepIn && touple.stepOut) {
      totalWorkingMinutes += moment
        .duration(
          moment(touple.stepOut, 'HH:mm').diff(moment(touple.stepIn, 'HH:mm'))
        )
        .asMinutes();
    }
  });
  return totalWorkingMinutes;
};

export const minutesToHoursAndMinutes = (minutes) => {
  return (minutes / 60).toFixed(1) + 'h';
};

export const minutesToHours = (minutes) => {
  return Math.floor(minutes / 60) + 'h';
};

export const calculateMonthAndYear = (currentDate) => {
  const [currentMonth, currentYear] = currentDate.split('-');
  return `${currentMonth}-${currentYear}`;
};

// Function to properly calculate freeDays from backend
export const dayRender = (dateEntry) => {
  const [day, month] = dateEntry.split('/');

  const paddedDay = day.padStart(2, '0');
  const paddedMonth = (parseInt(month) + 1).toString().padStart(2, '0');

  return `${paddedDay}/${paddedMonth}`;
};
