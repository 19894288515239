import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function CircularUnderLoad(props) {
  const { size = 60 } = props;
  return (
    <div style={{ margin: 'auto', position: 'fixed', top: '45%', left: '50%' }}>
      <CircularProgress size={size} />
    </div>
  );
}
