import { createContext, useContext } from 'react';

//Stores
import { MainStore } from './MainStore';

// Store context providers
export const StoreContext = createContext(null);

// Store initialization
export function storeBuilder() {
  return new MainStore();
}

export const useStore = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('Store cannot be used outside StoreProvider!');
  }

  return store;
};
