import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import get from 'get-value';

// importing Material UI elements
import {
  Avatar,
  ListItem,
  makeStyles,
  ListItemAvatar,
  Link,
  ListItemText,
} from '@material-ui/core';

// importing Material UI icons
import { Delete, PersonAdd } from '@material-ui/icons';

// importing internal components
import Toolbar from '../../../core/toolbar';
import ToolbarFilter from '../../../core/toolbar-filter';
import ListContainer from '../../../core/list-container';
import withSnackbar from '../../../utils/with-snackbar';
import EmployeesForm from '../components/dialogs/employees-form';
import RemoveEmployeesForm from '../components/dialogs/remove-employees-form';
import { getLanguageSupport } from '../../../utils/helper';

// importing constants
import { PAGE_SIZE, SNACKBAR_SEVERITY } from '../../../utils/constants';
import {
  STATUS_TYPE,
  translateEmployeeStatus,
} from '../../../helpers/employee-helper';

const useStyles = makeStyles((theme) => ({
  addButton: {
    margin: '5px',
    color: '#ffffff',
    backgroundColor: '#3f51b5',
  },
  removeButton: {
    margin: '5px',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  inactive: {
    color: '#9FA0A4',
  },
}));

// Queries:
const readCompanyQuery = gql`
  query($id: ID!) {
    readCompany(id: $id) {
      companyName
    }
  }
`;

const readCompanyEmployeesQuery = gql`
  query($companyId: ID!, $offset: Int!, $limit: Int!, $filter: String) {
    countCompanyEmployees(companyId: $companyId, filter: $filter)
    readCompanyEmployees(
      companyId: $companyId
      offset: $offset
      limit: $limit
      filter: $filter
    ) {
      id
      fullName
      status
      initials
      userData {
        email
      }
      mobile
    }
  }
`;

// Mutations:
const removeEmployeesMutation = gql`
  mutation($companyId: ID!, $employeeIds: [ID]!) {
    removeEmployeesFromCompany(companyId: $companyId, employeeIds: $employeeIds)
    removeEmployees(employeeIds: $employeeIds)
  }
`;

const settings = getLanguageSupport();

const Employees = (props) => {
  const classes = useStyles();
  const companyId = useParams().id;

  // State for form-dialog
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [selection, setSelection] = useState([]);
  const [focusedEmployeeId, setFocusedEmployeeId] = useState(null);

  // State for confirm-dialog
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectionNames, setSelectionNames] = useState([]);

  // State for toolbar filter
  const [filterValue, setFilterValue] = useState('');
  const handleFilterChange = (value) => {
    setFilterValue(value);
  };

  // State for title
  const [companyName, setCompanyName] = useState('');

  // DataGrid Pagination
  const [gridPage, setGridPage] = useState(1);
  const handlePageChange = async (params) => {
    setGridPage(params.page);
  };

  useEffect(() => {
    if (companyId) readCompany({ variables: { id: companyId } });
    return () => {
      setCompanyName('');
    };
  }, []);

  // Query functions:
  const [readCompany, {}] = useLazyQuery(readCompanyQuery, {
    onCompleted: (res) => {
      setCompanyName(res.readCompany.companyName);
    },
  });

  const { data, loading, refetch } = useQuery(readCompanyEmployeesQuery, {
    variables: {
      companyId: companyId,
      offset: (gridPage - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      filter: filterValue,
    },
  });

  const handleRowClick = async (id) => {
    setOpenFormDialog(true);
    setFocusedEmployeeId(id);
  };

  // Mutation functions:

  const [removeEmployeesFromCompany, {}] = useMutation(
    removeEmployeesMutation,
    {
      onCompleted: async () => {
        handleSubmitForm('successfullyRemovedEmployeesFromCompany');
        setSelection([]);
      },
    }
  );

  const handleClickRemoveButton = () => {
    if (selection.length !== 0) {
      setOpenConfirmDialog(true);
      setSelectionNames(
        data?.readCompanyEmployees?.filter((item) =>
          selection.includes(item.id.toString())
        )
      );
    } else {
      props.showSnackbar(
        get(
          settings,
          'companies.snackbar.firstSelectEmployeesToRemoveMessage',
          {
            default: 'Warning',
          }
        ),

        SNACKBAR_SEVERITY.WARNING
      );
    }
  };

  const handleRemoveEmployees = () => {
    removeEmployeesFromCompany({
      variables: { companyId: companyId, employeeIds: selection },
    });
  };

  const handleSubmitForm = async (message) => {
    setFocusedEmployeeId(null);
    setOpenConfirmDialog(false);
    setSelectionNames([]);
    await refetch();
    props.showSnackbar(
      get(settings, `companies.snackbar.${message}`, {
        default: 'Success!',
      }),
      SNACKBAR_SEVERITY.SUCCESS
    );
  };

  // Stuff for toolbar
  const toolbarButtons = !data
    ? []
    : [
        {
          name: get(settings, 'companies.company.addEmployeeButton', {
            default: 'Add employees',
          }),
          style: classes.addButton,
          icon: <PersonAdd />,
          handleAction: () => setOpenFormDialog(true),
        },
        {
          name: get(settings, 'companies.company.removeEmployeeButton', {
            default: 'Remove employees',
          }),
          style: classes.removeButton,
          icon: <Delete />,
          handleAction: handleClickRemoveButton,
        },
      ];

  // Define DataGrid columns
  const columns = [
    {
      field: 'fullName',
      headerName: get(settings, 'companies.company.fullName', {
        default: 'Name',
      }),
      width: 350,
      renderCell: (params) => {
        return (
          <ListItem>
            <ListItemAvatar>
              <Avatar>{params.row.initials}</Avatar>
            </ListItemAvatar>
            <Link onClick={() => handleRowClick(params.row.id)}>
              {params.row.status === STATUS_TYPE.DISABLED ? (
                <ListItemText
                  className={classes.inactive}
                  primary={params.row.fullName}
                  secondary={params.row.userData.email}
                />
              ) : (
                <ListItemText
                  primary={params.row.fullName}
                  secondary={params.row.userData.email}
                />
              )}
            </Link>
          </ListItem>
        );
      },
    },
    {
      field: 'status',
      headerName: get(settings, 'formDialog.statusEmployee', {
        default: 'Status',
      }),
      width: 180,
      valueFormatter: ({ value }) => translateEmployeeStatus(value),
    },
    {
      field: 'mobile',
      headerName: get(
        settings,
        'employees.listContainer.columnsHeader.mobile',
        { default: 'Mobile number' }
      ),
      width: 180,
    },
  ];

  const handleCloseDialog = () => {
    setOpenFormDialog(false);
    setFocusedEmployeeId(null);
  };

  const toolbarTitle = `${get(settings, 'employees.toolbar.title', {
    default: 'Employees',
  })} - ${companyName}
  `;

  return (
    <div className={'sectionContainer'}>
      <Toolbar
        backwardLink='/companies'
        title={toolbarTitle}
        buttons={toolbarButtons}
      >
        <ToolbarFilter
          settings={get(settings, 'toolbarFilter', {
            default: { filterLabel: 'Filter' },
          })}
          handleFilterChange={handleFilterChange}
        />
      </Toolbar>

      {data && (
        <ListContainer
          loading={loading}
          items={data.readCompanyEmployees}
          columns={columns}
          gridPage={gridPage}
          rowCount={data.countCompanyEmployees}
          onPageChange={handlePageChange}
          setSelection={setSelection}
        />
      )}

      <EmployeesForm
        open={openFormDialog}
        companyId={companyId}
        handleSubmitForm={handleSubmitForm}
        handleClose={handleCloseDialog}
        focusedEmployee={focusedEmployeeId}
      />

      <RemoveEmployeesForm
        settings={settings}
        open={openConfirmDialog}
        handleClose={() => {
          setOpenConfirmDialog(false);
          setSelectionNames([]);
        }}
        handleSubmit={handleRemoveEmployees}
        selectionNames={selectionNames}
        selection={selection}
      />
    </div>
  );
};

export default withSnackbar(Employees);
