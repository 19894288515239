import { MEMBER_TYPE } from '../utils/constants';

import { getLanguageSupport } from '../utils/helper';
import get from 'get-value';

const language = getLanguageSupport();

export const getEmployeeInitialsFullName = (fullName) => {
  return fullName
    .split(' ')
    .map((section) => section[0])
    .join('')
    .toUpperCase();
};

export const getEmployeeInitialsSplitName = (names) => {
  return names
    .map((section) => section[0])
    .join('')
    .toUpperCase();
};

export const getMemberType = (type) => {
  switch (type) {
    case 0:
      return MEMBER_TYPE.OWNER;
    case 1:
      return MEMBER_TYPE.BACKUP;
    case 2:
      return MEMBER_TYPE.MEMBER;
  }
};

export const getMemberTypeNumber = (type) => {
  switch (type) {
    case MEMBER_TYPE.OWNER:
      return 0;
    case MEMBER_TYPE.BACKUP:
      return 1;
    case MEMBER_TYPE.MEMBER:
      return 2;
  }
};

export const STATUS_TYPE = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

export const EMPLOYMENT_TYPE = {
  CONTRACT: 'contract',
  PFA: 'pfa',
};

export const translateEmployeeStatus = (status) => {
  switch (status) {
    case STATUS_TYPE.ENABLED:
      return get(language, 'companyEmployee.enabled', { default: 'enabled' });
    case STATUS_TYPE.DISABLED:
      return get(language, 'companyEmployee.disabled', { default: 'disabled' });
  }
};
