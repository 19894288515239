import React from 'react';

// importing Material UI core
import { Input, IconButton, makeStyles } from '@material-ui/core';

// importing Material UI icons
import {
  NavigateBefore as PreviousDateIcon,
  NavigateNext as NextDateIcon,
} from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  dateButtons: {
    fontSize: 30,
  },
}));

function DateBrowser(props) {
  const {
    currentMonthAndYear,
    changeFieldValue,
    dateNavigate,
    dateNavigation,
  } = props;
  const classes = useStyles();

  return (
    <>
      <IconButton
        color='primary'
        onClick={() => dateNavigate(dateNavigation.back)}
        className={classes.dateButton}
      >
        <PreviousDateIcon className={classes.dateButtons} />
      </IconButton>

      <Input
        className={classes.inputDateField}
        type='month'
        name='currentSheetDate'
        value={currentMonthAndYear}
        onChange={changeFieldValue}
      />

      <IconButton
        color='primary'
        onClick={() => dateNavigate(dateNavigation.next)}
        className={classes.dateButton}
      >
        <NextDateIcon className={classes.dateButtons} />
      </IconButton>
    </>
  );
}

export default DateBrowser;
