import React from 'react';
import get from 'get-value';

//* Material UI
import { Card, Divider, makeStyles, Typography } from '@material-ui/core';
import EmploymentRow from './employment-row';
import moment from 'moment';

//* Constants
import { RO_DATE_FORMAT } from '../../../helpers/date-helper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '500px',
    height: 'auto',
    padding: '10px',
    marginBottom: 'auto',
    marginTop: '2em',
    marginLeft: '10px',
    borderRadius: '5px',
    [theme.breakpoints.only('md')]: {
      width: 'calc(50% - 10px)',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 10px)',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
      width: '100%',
    },
  },
  title: {
    fontSize: '22px',
    fontWeight: 'bold',
    marginBottom: '10px',
    padding: '35px',
  },
}));

const EmploymentDataDisplay = (props) => {
  const { employeeData, settings } = props;
  const classes = useStyles();

  return (
    <>
      <Card className={classes.root}>
        <Typography className={classes.title}>
          {get(settings, 'accountPage.account.employmentInfo', {
            default: 'Employment Info',
          })}
        </Typography>
        <Divider />
        <EmploymentRow
          name={get(settings, 'accountPage.account.ssn', { default: 'SSN' })}
          value={employeeData.ssn}
        />
        <Divider />
        <EmploymentRow
          name={get(settings, 'accountPage.account.series', {
            default: 'Series',
          })}
          value={employeeData.series + ' ' + employeeData.number}
        />
        <Divider />
        <EmploymentRow
          name={get(settings, 'accountPage.account.freeDays', {
            default: 'Free Days',
          })}
          value={employeeData.remainingVacationDays}
        />
        <Divider />
        <EmploymentRow
          name={get(settings, 'accountPage.account.dateEmployment', {
            default: 'Date of employment',
          })}
          value={moment(employeeData.dateOfEmployment).format(RO_DATE_FORMAT)}
        />
        <Divider />
      </Card>
    </>
  );
};

export default EmploymentDataDisplay;
