import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const appBarHeight = 45;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#3D4977',
    maxHeight: appBarHeight,
    zIndex: 1103,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logo: {
    width: '130px',
  },
}));

const HeaderContent = (props) => {
  const { handleDrawerToggle } = props;

  const classes = useStyles();

  return (
    <AppBar position='fixed' className={classes.appBar} elevation={0}>
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <img
          src='/images/timekey-logo-purple.png'
          alt='Logo'
          className={classes.logo}
        />
      </Toolbar>
    </AppBar>
  );
};

export default HeaderContent;
