import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import get from 'get-value';
import PropTypes from 'prop-types';

// importing Material UI elements
import {
  Grid,
  Button,
  IconButton,
  Tabs,
  Tab,
  Box,
  Tooltip,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

// importing Material UI icons
import { GetApp, Add, CenterFocusStrong } from '@material-ui/icons';

// importing internal components
import Toolbar from '../../core/toolbar';
import CardDisplay from '../dashboard/components/card-display';
import ListContainer from '../../core/list-container';
import DocumentFormDialog from './components/dialogs/document-form';
import DownloadFiles from './components/dialogs/download-files';
import withSnackbar from '../../utils/with-snackbar';
import CircularUnderLoad from '../../components/loading-animation';
import { useStore } from '../../stores';

// importing constants
import { RO_DATE_FORMAT } from '../../helpers/date-helper';
import {
  DOCUMENT_REQUEST_STATUS,
  REQUESTS_FILTER_STATUS,
  HISTORY_LOCATION,
  PAGE_SIZE,
  DOCUMENT_DIALOG_TYPES,
} from '../../utils/constants';
import { EMPLOYMENT_TYPE } from '../../helpers/employee-helper';
import { FORM_FIELDS } from '../../helpers/document-helper';

const useStyles = makeStyles(() => ({
  cardContainer: {
    marginTop: 12,
  },
  tabWidth: {
    minWidth: 50,
  },

  cardsStyle: {
    marginLeft: 12,
    height: 195,
    marginBottom: 12,
    padding: 0,
  },
  buttonGroup: {
    marginLeft: 'auto',
    display: 'block',
    marginTop: '8px',
  },
  firstDiv: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
  },
  iconButton: {
    backgroundColor: 'white',
    border: '1px solid #3f51b5',
  },
  button: {
    color: '#3f51b5',
    width: '30px',
    height: '30px',
  },
  typography: {
    fontWeight: 'bold',
  },
  docName: {
    textAlign: 'center',
    textTransform: 'capitalize',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.7)',
    marginTop: '-5px',
  },
  tooltipText: {
    fontSize: 15,
  },
  docType: {
    textAlign: 'center',
    textTransform: 'capitalize',
    marginTop: 20,
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

// Queries:
const readAllDocumentTemplatesByEmployee = gql`
  query readAllDocumentTemplatesByEmployee($id: ID!) {
    readAllDocumentTemplatesByEmployee(id: $id) {
      id
      name
      company {
        companyName
      }
      templateHTML
      documentType
    }
  }
`;

const readIsTeamLeader = gql`
  query readIsTeamLeader($id: ID!) {
    readIsTeamLeader(id: $id)
  }
`;

const readDocumentRequestsQuery = gql`
  query DocumentRequests(
    $source: ID
    $destination: ID
    $offset: Int!
    $limit: Int!
    $filter: String
  ) {
    countDocumentRequestsByEmployee(
      source: $source
      filter: $filter
      destination: $destination
    )
    readDocumentRequestsByEmployee(
      offset: $offset
      limit: $limit
      filter: $filter
      source: $source
      destination: $destination
    ) {
      documentRequest {
        id
        date
        description
        source {
          id
          firstName
          lastName
        }
        documentVerification {
          status
        }

        documents {
          id
        }

        comments {
          id
          date
          source {
            id
            firstName
            lastName
          }
          comment
        }

        fields {
          fieldName
          fieldType
          fieldValue
        }
        template {
          name
          templateHTML
          company {
            id
            companyName
          }
        }
      }
      globalStatus
    }
  }
`;

const readDocumentRequestsPendingQuery = gql`
  query DocumentRequests(
    $destination: ID
    $offset: Int!
    $limit: Int!
    $filter: String
    $requestsFilterStatus: String
  ) {
    countDocumentRequestsByEmployeePending(
      filter: $filter
      destination: $destination
      requestsFilterStatus: $requestsFilterStatus
    )

    readDocumentRequestsByEmployeePending(
      offset: $offset
      limit: $limit
      filter: $filter
      destination: $destination
      requestsFilterStatus: $requestsFilterStatus
    ) {
      documentRequest {
        id
        date
        description

        source {
          fullName
        }
      }
      status
      globalStatus
    }
  }
`;

const TabPanel = (props) => {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      className="MuiBoxCustom"
      {...other}
    >
      {tabValue === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  tabValue: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
};

const Documents = (props) => {
  const store = useStore();
  const { employeeID, employmentType } = store.employeeData;
  const isCollaborator = employmentType === EMPLOYMENT_TYPE.PFA;

  const classes = useStyles();
  const { settings } = props;

  // State for the toolbar tabs
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
    setGridPage(1);
  };

  // DataGrid Pagination
  const [gridPage, setGridPage] = useState(1);
  const handlePageChange = async (params) => {
    setGridPage(params.page);
  };

  // State for filtering requests in pending documents
  const [requestsFilterStatus, setRequestsFilterStatus] = useState(
    REQUESTS_FILTER_STATUS.ALL
  );

  const handleRequestsFilter = (_, newRequestFilter) => {
    setRequestsFilterStatus(
      !newRequestFilter ? requestsFilterStatus : newRequestFilter
    );
    setGridPage(1);
  };

  const handleCloseDialog = (dialogType) => {
    if (dialogType === DOCUMENT_DIALOG_TYPES.FORM_TYPE) {
      setOpenFormDialog(false);
    } else {
      setDialogDownloadDocumentsData('');
      setOpenDownloadDocumentsDialog(false);
    }
  };

  // state for release documents form dialog
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [selectedDocumentTemplate, setSelectedDocumentTemplate] = useState({});

  // state for download documents form dialog
  const [openDownloadDocumentsDialog, setOpenDownloadDocumentsDialog] =
    useState(false);

  const [dialogDownloadDocumentsData, setDialogDownloadDocumentsData] =
    useState('');

  // Query function:
  // get document requests history
  const {
    data: documentRequestsHistoryData,
    loading: documentRequestsHistoryLoading,
    refetch: documentRequestsHistoryRefetch,
  } = useQuery(readDocumentRequestsQuery, {
    variables: {
      source: employeeID,
      offset: (gridPage - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
    },
    skip: !employeeID,
  });

  // get document requests pending
  const {
    data: documentRequestsPendingData,
    loading: documentRequestsPendingLoading,
    refetch: documentRequestsPendingRefetch,
  } = useQuery(readDocumentRequestsPendingQuery, {
    variables: {
      destination: employeeID,
      offset: (gridPage - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      requestsFilterStatus,
    },
    skip: !employeeID,
  });

  // get all Documents Templates
  const { data: documentTemplateData, loading: documentTemplateLoading } =
    useQuery(readAllDocumentTemplatesByEmployee, {
      variables: {
        id: employeeID,
      },
      skip: !employeeID,
    });

  // define if it's team leader or not
  const { data: isTeamLeaderData } = useQuery(readIsTeamLeader, {
    variables: {
      id: employeeID,
    },
    skip: !employeeID,
  });

  const handleOpenDownloadDocumentsDialog = (data) => {
    setDialogDownloadDocumentsData({
      documentRequestID: data.id,
      templateName: data.template.name,
    });
    setOpenDownloadDocumentsDialog(true);
  };

  // Define DataGrid columns
  const historyColumns = [
    {
      field: 'date',
      headerName: get(settings, 'documents.dataGridColumns.date', {
        default: 'Date',
      }),
      width: 150,
      valueFormatter: ({ value }) => moment(value).format(RO_DATE_FORMAT),
    },
    {
      field: 'description',
      headerName: get(settings, 'documents.dataGridColumns.description', {
        default: 'Description',
      }),
      width: 350,
    },
    {
      field: 'globalStatus',
      headerName: get(settings, 'documents.dataGridColumns.globalStatus', {
        default: 'Status',
      }),
      width: 150,
      valueFormatter: ({ value }) =>
        get(
          settings,
          `documents.dataGridColumns.documentRequestStatus.${value}`,
          {
            default: value,
          }
        ),
    },
    {
      field: 'download',
      headerName: get(settings, 'documents.dataGridColumns.download', {
        default: 'Download',
      }),
      width: 100,
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        if (params.row.globalStatus !== DOCUMENT_REQUEST_STATUS.APPROVED) {
          return <> </>;
        }
        return (
          <Tooltip
            title={get(settings, 'documents.dataGridColumns.download', {
              default: 'Download',
            })}
          >
            <IconButton
              onClick={() => handleOpenDownloadDocumentsDialog(params.row)}
            >
              <GetApp />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: 'numberOfDays',
      headerName: get(settings, 'documents.dataGridColumns.numberOfDays', {
        default: 'Number of days',
      }),
      align: 'center',
      width: 150,
      valueGetter: (params) => {
        if (params.row.globalStatus !== DOCUMENT_REQUEST_STATUS.APPROVED)
          return null;
        const field = params?.row?.fields?.find(
          (item) => item.fieldName === FORM_FIELDS.numberOfDays
        );
        return field?.fieldValue;
      },
    },
    {
      field: 'details',
      headerName: get(settings, 'documents.dataGridColumns.details', {
        default: 'Details',
      }),
      width: 100,
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            component={NavLink}
            to={`/document-request-details/${params.row.id}/${HISTORY_LOCATION}`}
          >
            {get(settings, 'documents.dataGridColumns.details', {
              default: 'Details',
            })}
          </Button>
        );
      },
    },
  ];

  const pendingColumns = [
    {
      field: 'date',
      headerName: get(settings, 'documents.dataGridColumns.date', {
        default: 'Date',
      }),
      width: 150,
      valueFormatter: ({ value }) => moment(value).format(RO_DATE_FORMAT),
    },
    {
      field: 'source',
      headerName: get(settings, 'documents.dataGridColumns.source', {
        default: 'Source',
      }),
      width: 200,
      valueFormatter: ({ value }) => value.fullName,
      valueGetter: ({ value }) => value.fullName,
    },
    {
      field: 'description',
      headerName: get(settings, 'documents.dataGridColumns.description', {
        default: 'Description',
      }),
      width: 350,
    },

    {
      field: 'status',
      headerName: get(settings, 'documents.dataGridColumns.status', {
        default: 'Status',
      }),
      width: 150,
      valueFormatter: ({ value }) =>
        get(
          settings,
          `documents.dataGridColumns.documentRequestStatus.${value}`,
          {
            default: value,
          }
        ),
    },
    {
      field: 'globalStatus',
      headerName: get(settings, 'documents.dataGridColumns.globalStatus', {
        default: 'Status Global',
      }),
      width: 200,
      valueFormatter: ({ value }) =>
        get(
          settings,
          `documents.dataGridColumns.documentRequestStatus.${value}`,
          {
            default: value,
          }
        ),
    },
    {
      field: 'details',
      headerName: get(settings, 'documents.dataGridColumns.details', {
        default: 'Details',
      }),
      width: 100,
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            component={NavLink}
            to={`/document-request-details/${params.row.id}/${'pending'}`}
          >
            {get(settings, 'documents.dataGridColumns.details', {
              default: 'Details',
            })}
          </Button>
        );
      },
    },
  ];

  let isTeamLead;
  let newDocumentRequestsPendingData;
  let newDocumentRequestsHistoryData;

  if (
    documentTemplateLoading ||
    !isTeamLeaderData ||
    !documentRequestsPendingData ||
    !documentRequestsHistoryData
  ) {
    return (
      <div className="sectionContainer">
        <Toolbar
          title={get(settings, 'documents.toolbar.title', {
            default: 'Document template',
          })}
        />
        <CircularUnderLoad />
      </div>
    );
  }

  isTeamLead = isTeamLeaderData.readIsTeamLeader;

  newDocumentRequestsPendingData =
    documentRequestsPendingData?.readDocumentRequestsByEmployeePending?.map(
      (row) => ({
        ...row.documentRequest,
        status: row.status,
        globalStatus: row.globalStatus,
      })
    );
  newDocumentRequestsHistoryData =
    documentRequestsHistoryData?.readDocumentRequestsByEmployee?.map((row) => ({
      ...row.documentRequest,
      globalStatus: row.globalStatus,
    }));

  return (
    <div className="sectionContainer">
      <Toolbar
        title={get(settings, 'documents.toolbar.title', {
          default: 'Document template',
        })}
      >
        <Grid container spacing={2}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable"
          >
            <Tab
              className={classes.tabWidth}
              label={get(settings, 'documents.toolbar.tabLabels.historic', {
                default: 'Historic',
              })}
              {...a11yProps(0)}
            />
            {isTeamLead && (
              <Tab
                label={get(
                  settings,
                  'documents.toolbar.tabLabels.pendingRequests',
                  {
                    default: 'Pending requests',
                  }
                )}
                {...a11yProps(1)}
              />
            )}
            {!isCollaborator && (
              <Tab
                label={get(
                  settings,
                  'documents.toolbar.tabLabels.releaseDocuments',
                  {
                    default: 'Release documents',
                  }
                )}
                {...a11yProps(2)}
              />
            )}
          </Tabs>

          {isTeamLead && tabValue === 1 && (
            <ToggleButtonGroup
              value={requestsFilterStatus}
              exclusive
              size="small"
              className={classes.buttonGroup}
              onChange={handleRequestsFilter}
            >
              <ToggleButton value={REQUESTS_FILTER_STATUS.ALL}>
                {get(
                  settings,
                  `documents.toolbar.requestsFilterStatus.${REQUESTS_FILTER_STATUS.ALL}`,
                  {
                    default: REQUESTS_FILTER_STATUS.ALL,
                  }
                )}
              </ToggleButton>
              <ToggleButton value={REQUESTS_FILTER_STATUS.PENDING}>
                {get(
                  settings,
                  `documents.toolbar.requestsFilterStatus.${REQUESTS_FILTER_STATUS.PENDING}`,
                  {
                    default: REQUESTS_FILTER_STATUS.PENDING,
                  }
                )}
              </ToggleButton>
              <ToggleButton value={REQUESTS_FILTER_STATUS.VALIDATED}>
                {get(
                  settings,
                  `documents.toolbar.requestsFilterStatus.${REQUESTS_FILTER_STATUS.VALIDATED}`,
                  {
                    default: REQUESTS_FILTER_STATUS.VALIDATED,
                  }
                )}
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Grid>
      </Toolbar>
      <TabPanel tabValue={tabValue} index={0}>
        <ListContainer
          items={
            documentRequestsHistoryLoading ? [] : newDocumentRequestsHistoryData
          }
          loading={documentRequestsHistoryLoading}
          columns={historyColumns}
          gridPage={gridPage}
          rowCount={
            documentRequestsHistoryData &&
            documentRequestsHistoryData.countDocumentRequestsByEmployee
          }
          onPageChange={handlePageChange}
          checkboxSelection={false}
          disableColumnMenu
        />
      </TabPanel>
      {isTeamLead && (
        <TabPanel tabValue={tabValue} index={1}>
          <ListContainer
            items={
              documentRequestsPendingLoading
                ? []
                : newDocumentRequestsPendingData
            }
            loading={documentRequestsPendingLoading}
            columns={pendingColumns}
            gridPage={gridPage}
            rowCount={
              documentRequestsPendingData &&
              documentRequestsPendingData.countDocumentRequestsByEmployeePending
            }
            onPageChange={handlePageChange}
            checkboxSelection={false}
          />
        </TabPanel>
      )}
      {!isCollaborator && (
        <TabPanel tabValue={tabValue} index={isTeamLead ? 2 : 1}>
          <Grid container spacing={2} className={classes.cardContainer}>
            {documentTemplateData.readAllDocumentTemplatesByEmployee.map(
              (document) => (
                <CardDisplay key={document.id} extraStyle={classes.cardsStyle}>
                  <Tooltip
                    title={document.name}
                    classes={{ tooltip: classes.tooltipText }}
                    arrow
                  >
                    <Typography variant="h5" className={classes.docName}>
                      {document.name}
                    </Typography>
                  </Tooltip>

                  <div className={classes.firstDiv}>
                    <Tooltip
                      title={get(
                        settings,
                        'documents.cardContainer.releaseDocumentButton',
                        { default: 'Release document' }
                      )}
                      arrow
                      classes={{ tooltip: classes.tooltipText }}
                    >
                      <IconButton
                        onClick={() => {
                          setSelectedDocumentTemplate(document);
                          setOpenFormDialog(true);
                        }}
                        className={classes.iconButton}
                      >
                        <Add className={classes.button} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  {document?.documentType && (
                    <Typography className={classes.docType}>
                      {get(
                        settings,
                        `documentTemplate.applicationTypes.${document?.documentType}`
                      )}
                    </Typography>
                  )}
                </CardDisplay>
              )
            )}
          </Grid>

          <DocumentFormDialog
            openForm={openFormDialog}
            documentID={selectedDocumentTemplate.id}
            handleClose={() => {
              handleCloseDialog(DOCUMENT_DIALOG_TYPES.FORM_TYPE);
            }}
            documentTitle={selectedDocumentTemplate.name}
            refetchHistoryData={documentRequestsHistoryRefetch}
            refetchPendingData={documentRequestsPendingRefetch}
            props={props}
          />
        </TabPanel>
      )}

      <DownloadFiles
        settings={settings}
        openForm={openDownloadDocumentsDialog}
        templateName={dialogDownloadDocumentsData.templateName}
        documentRequestID={dialogDownloadDocumentsData.documentRequestID}
        handleClose={() => {
          handleCloseDialog(DOCUMENT_DIALOG_TYPES.DOWNLOAD_TYPE);
        }}
      />
    </div>
  );
};

export default withSnackbar(Documents);
