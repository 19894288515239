import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import get from 'get-value';

// importing Material UI elements
import {
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TableFooter,
  makeStyles,
} from '@material-ui/core';

// importing Material UI icons
import { Check as CheckIcon, Block as BlockIcon } from '@material-ui/icons';

// importing internal components
import { useStore } from '../../../stores';
import {
  translateStatus,
  DOCUMENT_REQUEST_STATUS,
} from '../../../helpers/document-helper';

const createUpdatedStatusMutation = gql`
  mutation updateStatusDocumentRequest(
    $id: ID!
    $status: StatusType!
    $approvedBy: ID!
  ) {
    updateStatusDocumentRequest(
      id: $id
      status: $status
      approvedBy: $approvedBy
    )
  }
`;

const useStyles = makeStyles({
  border: {
    borderLeft: '1px solid #D3D3D3',
    borderRight: '1px solid #D3D3D3',
  },
  borderLeft: {
    borderRight: '1px solid #D3D3D3',
  },
  tableRow: {
    borderLeft: '1px solid #D3D3D3',
    borderBottom: 'none',
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  rowSpan: {
    borderBottom: 'none',
  },
});

const StatusTable = ({
  settings,
  documentRequestID,
  documentVerification,
  validate = true,
  disabledEdit = false,
  documentRequestRefetch,
  globalStatus,
  refetchDownload,
}) => {
  const store = useStore();
  const { employeeID } = store.employeeData;

  const [validateState, setValidateState] = useState(validate);

  const classes = useStyles();

  const [createStatusUpdate, {}] = useMutation(createUpdatedStatusMutation, {
    onCompleted: async () => {
      await documentRequestRefetch();
      await refetchDownload();
    },
  });

  const handleButtonClick = async (action) => {
    const status =
      action === 1
        ? DOCUMENT_REQUEST_STATUS.APPROVED
        : DOCUMENT_REQUEST_STATUS.REJECTED;

    await createStatusUpdate({
      variables: {
        id: documentRequestID,
        status: status,
        approvedBy: employeeID,
      },
    });
  };

  const renderRows = documentVerification.map((mapItem) => {
    let canValidate =
      employeeID === mapItem.destination.id &&
      mapItem.status === DOCUMENT_REQUEST_STATUS.PENDING;

    return (
      <TableRow key={`${mapItem.destination.id}-${mapItem.status}`}>
        <TableCell className={classes.border}>
          {mapItem.destination.lastName + ' ' + mapItem.destination.firstName}
        </TableCell>
        <TableCell>{translateStatus(mapItem.status)}</TableCell>

        {canValidate ? (
          <TableCell>
            <Tooltip
              title={get(settings, 'documents.statusTable.approveTooltip', {
                default: 'Accept document',
              })}
            >
              <IconButton
                aria-label="accept"
                onClick={() => {
                  setValidateState(false);
                  handleButtonClick(1);
                }}
                color="primary"
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={get(settings, 'documents.statusTable.rejectTooltip', {
                default: 'Reject document',
              })}
            >
              <IconButton
                aria-label="reject"
                onClick={() => {
                  setValidateState(false);
                  handleButtonClick(0);
                }}
                color="secondary"
              >
                <BlockIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell className={classes.borderLeft}></TableCell>
        )}
      </TableRow>
    );
  });

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {get(settings, 'documents.dataGridColumns.destination', {
                default: 'Destination',
              })}
            </TableCell>
            <TableCell>
              {get(settings, 'documents.dataGridColumns.status', {
                default: 'Status',
              })}
            </TableCell>
            {validateState && !disabledEdit ? (
              <TableCell>
                {get(settings, 'documents.dataGridColumns.validate', {
                  default: 'Validate',
                })}
              </TableCell>
            ) : (
              <TableCell></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{renderRows}</TableBody>
        <TableFooter>
          <TableRow>
            <TableCell rowSpan={3} className={classes.rowSpan} />
            <TableCell colSpan={2} className={classes.tableRow}>
              {translateStatus(globalStatus)}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};

export default StatusTable;
