import React, { useEffect } from 'react';
import get from 'get-value';

// importing Material UI elements
import {
  TextField,
  IconButton,
  Grid,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

// importing Material UI icons
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// importing internal components
import { getLanguageSupport } from '../../../utils/helper';

const settings = getLanguageSupport();

const TEXT_FIELD = get(settings, 'attendancePage.day', {
  default: { filterLabel: 'Day' },
});

const REMOVE_DAY = get(settings, 'attendancePage.removeDay', {
  default: { filterLabel: 'Remove' },
});

const ADD_DAY = get(settings, 'attendancePage.addDay', {
  default: { filterLabel: 'Add' },
});

const useStyles = makeStyles({
  scroll: {
    overflowY: 'scrollbar',
    maxHeight: 300,
  },
  firstGrid: {
    padding: '0px',
    alignItems: 'space-between',
  },
  textField: {
    marginRight: '15px',
  },
  iconButton: {
    padding: '0px',
    marginTop: '7px',
  },
  icon: {
    padding: '0px',
    marginTop: '15px',
    color: 'black',
  },
  iconsContainer: {
    textAlign: 'center',
  },
  padding: {
    padding: '0px',
  },
});

const FreeDaysMap = (props) => {
  const { freeDays = [], setFreeDays } = props;

  const classes = useStyles();

  const lastIndex = freeDays.length - 1;

  // Focus last entry only if it's empty
  useEffect(() => {
    if (freeDays && freeDays.length > 0) {
      const lastEntry = freeDays[lastIndex];
      if (lastEntry === '')
        document.getElementById(`freeDay-${lastIndex}`).focus();
    }
  }, [freeDays]);

  const handleChange = (event, rowIndex) => {
    const currentArray = [...freeDays];

    currentArray[rowIndex] = event.target.value;

    setFreeDays(currentArray);
  };

  const handleAddFields = () => {
    setFreeDays([...freeDays, '']);
  };

  const handleRowDelete = (rowIndex) => {
    const currentArray = [...freeDays];

    currentArray.splice(rowIndex, 1);

    setFreeDays(currentArray);
  };

  const handleKeyUp = (event, rowIndex) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      if (rowIndex === lastIndex) {
        handleAddFields();
      } else {
        document.getElementById(`freeDay-${rowIndex + 1}`).focus();
      }
    }
  };

  return (
    <div className={classes.scroll}>
      {freeDays &&
        freeDays.map((dayItem, rowIndex) => (
          <Grid
            container
            key={rowIndex}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item className={classes.firstGrid}>
              <TextField
                id={`freeDay-${rowIndex}`}
                className={classes.textField}
                label={TEXT_FIELD}
                value={dayItem}
                onChange={(event) => handleChange(event, rowIndex)}
                onKeyUp={(event) => handleKeyUp(event, rowIndex)}
              />
            </Grid>
            <Grid item className={classes.padding}>
              <Tooltip title={REMOVE_DAY}>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    handleRowDelete(rowIndex);
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ))}
      <div className={classes.iconsContainer}>
        <Tooltip title={ADD_DAY}>
          <IconButton onClick={handleAddFields} className={classes.icon}>
            <AddCircleIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default FreeDaysMap;
