import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import get from 'get-value';

// importing material UI elements
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import withSnackbar from '../../utils/with-snackbar';
import { SNACKBAR_SEVERITY } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

//* Queries
const sendFeedbackMutation = gql`
  mutation sendFeedback($feedbackContent: String!, $feedbackType: String!) {
    sendFeedback(feedbackContent: $feedbackContent, feedbackType: $feedbackType)
  }
`;

const DEFAULT_VALUES = {
  feedbackType: 'feedback',
  feedbackContent: '',
};

const FeedbackForm = ({ handleClose, isOpen, settings, showSnackbar }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [sendingFeedback, setSendingFeedback] = useState(false);

  const [formData, setFormData] = useState({
    ...DEFAULT_VALUES,
  });

  const handleSubmit = async () => {
    setSendingFeedback(true);
    await sendFeedback({
      variables: {
        feedbackContent,
        feedbackType,
      },
    });
  };

  const { feedbackType, feedbackContent } = formData;

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleError = () => {
    setSendingFeedback(false);
    showSnackbar(
      get(settings, `feedbackForm.errorText`, {
        default:
          'There was an error sending the feedback! Please try again later!',
      }),
      SNACKBAR_SEVERITY.ERROR
    );
  };

  const handleSuccess = () => {
    setFormData({ ...DEFAULT_VALUES });
    setSendingFeedback(false);
    handleClose();
    showSnackbar(
      get(settings, `feedbackForm.successText`, {
        default: 'The feedback has been sent! Thanks!',
      }),
      SNACKBAR_SEVERITY.SUCCESS
    );
  };

  const [sendFeedback, { error }] = useMutation(sendFeedbackMutation, {
    onCompleted: () => {
      if (error) return handleError();

      handleSuccess();
    },
    onError: handleError,
  });

  const onClose = () => {
    setFormData({ ...DEFAULT_VALUES });
    setSendingFeedback(false);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth={'sm'}
      fullScreen={fullScreen}
      onClose={onClose}
    >
      <DialogTitle id="form-dialog-title">
        {get(settings, 'feedbackForm.formTitle', 'Feedback Form')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {get(
            settings,
            'feedbackForm.formText',
            'We are always looking to improve the application so any feedback is welcome!'
          )}
        </DialogContentText>
        <TextField
          id="feedbackContent"
          label={get(settings, 'feedbackForm.fieldLabels.feedback', 'Feedback')}
          name="feedbackContent"
          value={feedbackContent}
          onChange={handleChange}
          variant="filled"
          multiline
          fullWidth
          rows={4}
          autoFocus
          className={classes.formControl}
        />
        <FormControl variant="filled" fullWidth className={classes.formControl}>
          <InputLabel id="feedback-type-label">
            {get(
              settings,
              'feedbackForm.fieldLabels.feedbackType',
              'Feedback Type'
            )}
          </InputLabel>
          <Select
            labelId="feedback-type-label"
            id="feedbackType"
            name="feedbackType"
            value={feedbackType}
            onChange={handleChange}
          >
            <MenuItem value="feedback">
              {get(settings, 'feedbackForm.feedbackTypes.feedback', 'Feedback')}
            </MenuItem>
            <MenuItem value="error">
              {get(settings, 'feedbackForm.feedbackTypes.error', 'Error')}
            </MenuItem>
            <MenuItem value="idea">
              {get(settings, 'feedbackForm.feedbackTypes.idea', 'Idea')}
            </MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {get(settings, 'feedbackForm.closeButton', 'Close')}
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={sendingFeedback}
        >
          {get(settings, 'feedbackForm.sendButton', 'Send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withSnackbar(FeedbackForm);
