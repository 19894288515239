import React, { useEffect, useState } from 'react';
import get from 'get-value';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import { observer } from 'mobx-react';

import TimePickerDate from '../../../attendance/components/time-picker';
import { useStore } from '../../../../stores';

const useStyles = makeStyles(() => ({
  box: {
    marginBottom: '4px',
    display: 'flex',
    flexDirection: 'row',
  },
  inBox: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '5px',
  },
  outBox: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '5px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '400px',
    overflow: 'auto',
    maxHeight: '500px',
  },
  deleteButton: {
    marginTop: '16px',
    paddingLeft: '6px',
    paddingRight: '6px',
    marginLeft: '2px',
  },
  dialog: {
    minWidth: '400px',
  },
  buttonsContainer: {
    alignSelf: 'end',
  },
}));

const WorkingScheduleDialog = observer(
  ({ selectedDay, setSelectedDay, settings, updateWorkingSchedule }) => {
    const classes = useStyles();
    const mainStore = useStore();

    const { employeeData } = mainStore;
    const { workingSchedule } = employeeData;

    const { name, day } = selectedDay ?? {};

    // Declare local state in order to have possibility to cancel changes
    const [selectedSchedule, updateSchedule] = useState(workingSchedule[day]);

    useEffect(() => {
      updateSchedule(workingSchedule[day]);
    }, [selectedDay]);

    const handleChange = (stepIndex, currentValue, key) => {
      if (currentValue) {
        const updatedSteps = selectedSchedule.map((step, index) => {
          if (index === stepIndex) {
            return {
              ...step,
              [key]: currentValue.format('HH:mm'),
            };
          }

          return step;
        });

        updateSchedule(updatedSteps);
      }
    };

    const onAddStep = () => {
      const newEntry = { stepIn: null, stepOut: null };

      updateSchedule([...(selectedSchedule ?? []), newEntry]);
    };

    const onDeleteStep = (stepIndex) => {
      const filteredSteps = selectedSchedule.filter(
        (_step, index) => index !== stepIndex
      );

      updateSchedule(filteredSteps);
    };

    const onClose = () => {
      setSelectedDay(null);
    };

    const onSave = () => {
      // Eliminate empty entries or partial entries
      const filteredSchedule = selectedSchedule.filter(
        (item) => item.stepIn && item.stepOut
      );

      let newSchedule = {
        ...workingSchedule,
      };

      if (filteredSchedule.length === 0) {
        delete newSchedule[day];
      } else {
        newSchedule = {
          ...newSchedule,
          [day]: filteredSchedule,
        };
      }

      // Update userSchedule in employeeData
      mainStore.setUserSchedule(newSchedule);

      // Update in db
      updateWorkingSchedule({
        variables: {
          employeeID: employeeData.employeeID,
          workingSchedule: newSchedule,
        },
      });

      // Close dialog
      setSelectedDay(null);
    };

    return (
      <Dialog open={!!selectedDay} onClose={onClose}>
        <DialogTitle>{name}</DialogTitle>
        <DialogContent className={classes.dialogContent} dividers>
          {selectedSchedule?.map((item, stepIndex) => (
            <Box className={classes.box} key={stepIndex}>
              <Box className={classes.inBox}>
                <Typography>
                  {get(settings, 'stepButtons.stepIn', {
                    default: 'Step In',
                  })}
                </Typography>
                <TimePickerDate
                  name="stepIn"
                  index={stepIndex}
                  disabled={false}
                  value={item.stepIn}
                  onChange={handleChange}
                />
              </Box>

              <Box className={classes.outBox}>
                <Typography>
                  {get(settings, 'stepButtons.stepOut', {
                    default: 'Step Out',
                  })}
                </Typography>

                <TimePickerDate
                  name="stepOut"
                  index={stepIndex}
                  disabled={!item.stepIn}
                  value={item.stepOut}
                  onChange={handleChange}
                />
              </Box>

              <IconButton
                onClick={() => onDeleteStep(stepIndex)}
                className={classes.deleteButton}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}

          <IconButton onClick={onAddStep}>
            <AddCircleIcon fontSize="small" />
          </IconButton>

          <div className={classes.buttonsContainer}>
            <Button variant="text" onClick={onClose}>
              {get(settings, 'timesheetViewer.dialogItems.cancelButtonLabel', {
                default: 'Cancel',
              })}
            </Button>
            <Button variant="text" onClick={onSave}>
              {get(settings, 'timesheetViewer.dialogItems.saveButtonLabel', {
                default: 'Save',
              })}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
);

export default WorkingScheduleDialog;
