const PAGE_SIZE = 10;

const SNACKBAR_SEVERITY = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

const MEMBER_TYPE = {
  OWNER: 'owner',
  BACKUP: 'backup',
  MEMBER: 'member',
};

const MEMBER_TYPE_CODES = {
  OWNER: 0,
  BACKUP: 1,
  MEMBER: 2,
};

const TEMPLATE_BUTTON_TYPES = {
  EDIT: 'EDIT',
  REMOVE: 'REMOVE',
};

const DOCUMENT_TYPE = {
  IN: 'IN',
  OUT: 'OUT',
};

const DOCUMENT_REQUEST_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  CANCELED: 'Canceled',
};

const DAYS_ROMANIAN = ['Du', 'Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sâ'];
const DAYS_ENGLISH = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const SUM_SYMBOL = 'Σ';

const REQUESTS_FILTER_STATUS = {
  ALL: 'All',
  PENDING: 'Pending',
  VALIDATED: 'Validated',
};

const HISTORY_LOCATION = 'history';

const DOCUMENT_DIALOG_TYPES = {
  DOWNLOAD_TYPE: 'Download',
  UPLOAD_TYPE: 'Upload',
  FORM_TYPE: 'Form',
};

const WORK_STATUS = {
  WORK: 'W',
  REST: 'RL',
  MATERNAL_LEAVE: 'ML',
  PARENTAL_LEAVE: 'PL',
  UNKNOWN: 'U',
};

export {
  PAGE_SIZE,
  SNACKBAR_SEVERITY,
  MEMBER_TYPE,
  DOCUMENT_TYPE,
  DOCUMENT_REQUEST_STATUS,
  REQUESTS_FILTER_STATUS,
  HISTORY_LOCATION,
  DOCUMENT_DIALOG_TYPES,
  DAYS_ROMANIAN,
  DAYS_ENGLISH,
  SUM_SYMBOL,
  WORK_STATUS,
  MEMBER_TYPE_CODES,
  TEMPLATE_BUTTON_TYPES,
};
