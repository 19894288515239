import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import get from 'get-value';
import { useParams, useHistory } from 'react-router-dom';

// importing Material UI elements
import {
  Grid,
  Typography,
  makeStyles,
  IconButton,
  Tooltip,
} from '@material-ui/core';

// importing Material UI icons
import {
  AddCircleOutlineOutlined,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

// importing internal components
import withSnackbar from '../../../utils/with-snackbar';
import { getLanguageSupport } from '../../../utils/helper';
import Toolbar from '../../../core/toolbar';
import DashboardCard from '../../dashboard/components/card-display';
import ConfirmForm from '../../../components/dialogs/confirm-form';
import CircularUnderLoad from '../../../components/loading-animation';

// importing constants
import {
  SNACKBAR_SEVERITY,
  TEMPLATE_BUTTON_TYPES,
} from '../../../utils/constants';

const settings = getLanguageSupport();

const useStyles = makeStyles(() => ({
  addButton: {
    margin: '5px',
    color: '#ffffff',
    backgroundColor: '#3f51b5',
  },
  removeButton: {
    margin: '5px',
  },
  cardContainer: {
    marginTop: 12,
  },
  deleteButton: {
    marginLeft: 'auto !important',
  },
  editButton: {
    backgroundColor: 'white',
    boxShadow: '3px 5px #EEEEEE',
    marginBottom: '15px',
    color: '#2C2BFA',
  },
  deleteButton: {
    backgroundColor: 'white',
    boxShadow: '3px 5px #EEEEEE',
    marginBottom: '15px',
    color: 'red',
    marginLeft: '15px',
  },
  iconsContainer: {
    textAlign: 'center',
  },
}));

// Queries:
const readAllDocumentTemplatesQuery = gql`
  query readAllDocumentTemplates($companyId: ID!) {
    readCompany(id: $companyId) {
      id
      companyName
    }

    readAllDocumentTemplates(companyId: $companyId) {
      template {
        id
        name
        company {
          companyName
        }
        templateHTML
      }
      inUse
    }
  }
`;

// Delete
const removeDocumentTemplateMutation = gql`
  mutation($id: ID!) {
    removeDocumentTemplate(id: $id)
  }
`;

const Templates = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const companyId = useParams().id;
  localStorage.setItem('companyId', companyId);

  // confirm dialog state
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [removeTemplate, setRemoveTemplate] = useState('');

  // Stuff for toolbar
  const toolbarButtons = [
    {
      name: get(settings, 'documents.toolbar.addButton', { default: 'Add' }),
      style: classes.addButton,
      icon: <AddCircleOutlineOutlined />,
      handleAction: () => {},
      to: `/document-template`,
    },
  ];

  // Mutation function:
  const [removeDocumentTemplate, {}] = useMutation(
    removeDocumentTemplateMutation
  );
  // remove a specific template

  const handleClickRemoveDocumentTemplate = (id) => {
    setRemoveTemplate(id);
    setOpenConfirmDialog(true);
  };

  const handleRemoveDocumentTemplate = async (id) => {
    await removeDocumentTemplate({
      variables: { id: id },
    });
    setOpenConfirmDialog(false);
    await refetchDocumentTemplates();
    props.showSnackbar(
      get(settings, 'templates.snackbar.successfullyRemovedDocumentTemplate', {
        default: 'Success',
      }),

      SNACKBAR_SEVERITY.SUCCESS
    );
  };

  const handleButtonsClick = (template, button) => {
    if (template.inUse) {
      props.showSnackbar(
        get(settings, 'templates.snackbar.templateInUse', {
          default: 'Template is currently in use!',
        }),
        SNACKBAR_SEVERITY.ERROR
      );
    } else {
      if (button === TEMPLATE_BUTTON_TYPES.EDIT) {
        history.push(`/document-template/${template.template.id}`);
      }
      handleClickRemoveDocumentTemplate(template.template.id);
    }
  };

  // get all Documents Templates
  const { data, refetch: refetchDocumentTemplates } = useQuery(
    readAllDocumentTemplatesQuery,
    {
      variables: { companyId: companyId },
    }
  );
  if (!data)
    return (
      <div className='sectionContainer'>
        <Toolbar
          backwardLink='/companies'
          title={get(settings, 'templates.toolbar.title', {
            default: 'Templates',
          })}
          buttons={toolbarButtons}
        />
        <CircularUnderLoad />
      </div>
    );

  return (
    <div className='sectionContainer'>
      <Toolbar
        backwardLink='/companies'
        title={`${get(settings, 'templates.toolbar.title', {
          default: 'Templates',
        })} - ${get(data, 'readCompany.companyName', {
          default: 'company name',
        })}`}
        buttons={toolbarButtons}
      />
      <Grid container spacing={2} className={classes.cardContainer}>
        {data.readAllDocumentTemplates.map((template) => (
          <DashboardCard
            key={template.template.id}
            title={template.template.name}
          >
            <div className={classes.iconsContainer}>
              <Tooltip
                title={get(settings, 'templates.content.editButton', {
                  default: 'Edit',
                })}
              >
                <IconButton
                  className={classes.editButton}
                  onClick={() =>
                    handleButtonsClick(template, TEMPLATE_BUTTON_TYPES.EDIT)
                  }
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={get(settings, 'templates.toolbar.removeButton', {
                  default: 'Remove',
                })}
              >
                <IconButton
                  className={classes.deleteButton}
                  onClick={() =>
                    handleButtonsClick(template, TEMPLATE_BUTTON_TYPES.REMOVE)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </DashboardCard>
        ))}
      </Grid>
      {openConfirmDialog && (
        <ConfirmForm
          settings={settings}
          open={openConfirmDialog}
          title={`${get(settings, 'confirmDialog.dialogTitleRemove', {
            default: '',
          })} ${get(settings, 'templates.confirmDialog.title', {
            default: 'document template',
          })}`}
          handleClose={() => setOpenConfirmDialog(false)}
          handleSubmit={() => handleRemoveDocumentTemplate(removeTemplate)}
        >
          <Typography>
            {get(settings, 'templates.confirmDialog.removeMessage', {
              default: 'Attention, document template will be deleted!',
            })}
          </Typography>
        </ConfirmForm>
      )}
    </div>
  );
};

export default withSnackbar(Templates);
