const VALID_EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const VALID_MOBILE_NUMBER =
  /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/;

const COMPANY_FIELDS = {
  companyName: '',
  vatNumber: '',
  registrationNumber: '',
  address: '',
  logo: '',
  representatives: [
    {
      firstName: '',
      lastName: '',
      ssn: '',
    },
  ],
  bankAccounts: [
    {
      bankName: '',
      iban: '',
    },
  ],
};

const TEAM_FIELDS = {
  teamName: '',
  logo: '',
  description: '',
};

const EMPLOYEE_FIELDS = {
  firstName: '',
  lastName: '',
  email: '',

  birthDate: '',
  mobile: '',

  password: '',
  confirmPassword: '',

  idCardSeries: '',
  idCardNumber: '',

  ssn: '',

  vacationDays: '',
  takenVacationDays: '',
  dateOfEmployment: '',

  contractType: '',
  positionName: '',

  isAdmin: false,
  employmentType: 'contract',
};

const DOCUMENT_FIELDS = {
  dateFrom: '',
  dateTo: '',
  numberOfDays: '',
  'employee.lastName': '',
  'employee.firstName': '',
  'employee.ssn': '',
  'employee.idCardSeries': '',
  'employee.dateOfEmployment': '',
  'employee.idCardNumber': '',
  replacementName: '',
  'company.lastRegistrationNumber': '',
  'system.currentDate': '',
  'system.currentYear': '',
};

const EMPLOYEE_TO_TEAM_FIELDS = {
  employee: null,
  memberType: '',
};

const EMPLOYEE_TO_COMPANY_FIELDS = {
  employee: '',
};

const REGISTRY_ITEM_FIELDS = {
  type: '',
  date: '',
  allocatedBy: '',
  description: '',
  number: '',
  company: '',
};

const DOCUMENT_TEMPLATE_FIELDS = {
  name: '',
  url: '',
  company: '',
  companyName: '',
  verificationNeeded: false,
  documentType: '',
};

export {
  VALID_EMAIL,
  VALID_MOBILE_NUMBER,
  COMPANY_FIELDS,
  TEAM_FIELDS,
  EMPLOYEE_FIELDS,
  DOCUMENT_FIELDS,
  EMPLOYEE_TO_TEAM_FIELDS,
  EMPLOYEE_TO_COMPANY_FIELDS,
  REGISTRY_ITEM_FIELDS,
  DOCUMENT_TEMPLATE_FIELDS,
};
