import APIHelper from '../helpers/api-helper';

export const logout = (callback) => {
  APIHelper.prototype
    .fetchData(`/auth/logout`, 'GET')
    .catch(console.error)
    .finally(() => {
      if (callback) callback();
    });
};
