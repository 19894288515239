import React from 'react';
import get from 'get-value';
import { Link } from 'react-router-dom';

import {
  Card,
  List,
  Grid,
  Divider,
  ListItem,
  CardMedia,
  IconButton,
  makeStyles,
  Typography,
  CardContent,
  CardActions,
  ListItemText,
  Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    flexGrow: 1,
    marginTop: 10,
    minHeight: '690px',
  },
  titleContainer: {
    padding: '10px 16px 0 16px',
  },
  cardTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
  },
  detail: {
    paddingLeft: theme.spacing(4),
    fontSize: '8px',
  },
  cardActions: {
    justify: 'center',
  },
  listItemContent: {
    fontSize: '14px',
  },
  secondaryCardText: {
    fontSize: '13px',
    color: '#0000008A',
    marginTop: 10,
  },

  detailsContainer: {
    paddingTop: 0,
  },
  tooltipText: {
    fontSize: 18,
  },
}));

const CardLayout = (props) => {
  const {
    item,
    title,
    actions,
    details,
    settings,
    collection,
    logoImageUrl,
    detailsHeader,
  } = props;

  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
        <Card className={classes.cardRoot} elevation={2}>
          <CardMedia className={classes.cardMedia} image={logoImageUrl} />
          <CardContent className={classes.titleContainer}>
            <Tooltip
              title={title}
              placement="top"
              arrow
              classes={{ tooltip: classes.tooltipText }}
            >
            <Typography className={classes.cardTitle}>{title}</Typography>
            </Tooltip>
          </CardContent>
          <CardActions disableSpacing>
            {actions.map((action, index) => (
              <Tooltip
                key={index}
                title={get(
                  settings,
                  `${collection}.cardContainer.tooltips.${action.name}`
                )}
              >
                {action.linkTo ? (
                  <Link className={action.iconClassName} to={action.linkTo}>
                    <IconButton disabled={action.disabled}>
                      {action.icon}
                    </IconButton>
                  </Link>
                ) : (
                  <IconButton
                    onClick={() => action.onClick(item.id)}
                    className={action.iconClassName}
                    disabled={action.disabled}
                    component="div"
                  >
                    {action.icon}
                  </IconButton>
                )}
              </Tooltip>
            ))}
          </CardActions>
          <CardContent className={classes.detailsContainer}>
            <List dense component="div" disablePadding>
              {detailsHeader}
              <Divider variant="middle" light />
              {details.map(({ name, value }) => (
                <ListItem
                  className={classes.detail}
                  key={`collapse-list-item-${name}`}
                >
                
                  <ListItemText
                    className={classes.listItemContent}
                    disableTypography
                    primary={get(
                      settings,
                      `${collection}.cardContainer.cardDetails.${name}`,
                      { default: `Detail - ${name}` }
                    )}
                    secondary={
                      <div className={classes.secondaryCardText}>{value}</div>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default CardLayout;
