import React from 'react';
import get from 'get-value';

// importing material UI elements
import {
  makeStyles,
  ListItemIcon,
  ListItem,
  Typography,
} from '@material-ui/core';

// importing Material UI icons
import {
  Edit,
  People,
  Delete,
  Assignment,
  BorderAll,
  Business,
} from '@material-ui/icons';

// importing components
import CardLayout from '../../../core/card-layout';

import APIHelper from '../../../helpers/api-helper';

const useStyles = makeStyles(() => ({
  deleteIcon: {
    marginLeft: 'auto',
  },
  iconLink: {
    textDecoration: 'none',
  },
  detailsHeader: {
    fontWeight: 'bold',
  },
}));

const CardDisplay = (props) => {
  const { company, settings, handleClickEdit, handleClickRemove } = props;
  const classes = useStyles();

  const cardActions = [
    {
      name: 'edit',
      icon: <Edit />,
      onClick: handleClickEdit,
    },
    {
      name: 'members',
      icon: (
        <>
          <People />{' '}
          <span style={{ fontSize: '20px' }}>{company.totalEmployees}</span>
        </>
      ),
      linkTo: `/companies/${company.id}`,
      iconClassName: classes.iconLink,
    },
    {
      name: 'registry',
      icon: <Assignment />,
      linkTo: `/registry/${company.id}`,
    },
    {
      name: 'templates',
      icon: <BorderAll />,
      linkTo: `/templates/${company.id}`,
    },
    {
      name: 'remove',
      icon: <Delete />,
      onClick: handleClickRemove,
      iconClassName: classes.deleteIcon,
      disabled: company.totalEmployees !== 0,
    },
  ];

  const detailsHeader = (
    <ListItem dense>
      <ListItemIcon>
        <Business />
      </ListItemIcon>
      <Typography className={classes.detailsHeader}>
        {get(settings, `companies.cardContainer.cardHeader`, {
          default: 'Card header',
        })}
      </Typography>
    </ListItem>
  );

  const companyLogo = () => {
    const fileName = company.logo ?? 'not-available.png';
    return `${APIHelper.baseUrl}/cdn/${fileName}`;
  };

  return (
    <CardLayout
      item={company}
      settings={settings}
      collection={'companies'}
      title={company.companyName}
      actions={cardActions}
      logoImageUrl={companyLogo()}
      detailsHeader={detailsHeader}
      details={[
        { name: 'companyName', value: company.companyName },
        { name: 'vatNumber', value: company.vatNumber },
        { name: 'registrationNumber', value: company.registrationNumber },
        { name: 'address', value: company.address },
      ]}
    />
  );
};

export default CardDisplay;
