import React from 'react';

// importing material UI elements
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  useTheme,
  useMediaQuery,
  Divider,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  progressContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  title: {
    paddingBottom: '0px',
  },
  subtitle: {
    padding: '0px 24px',
  },
  divider: {
    marginTop: '12px',
  },
});

const DialogFooter = ({
  uniformForm,
  handleClose,
  formCancelButton,
  showSubmit,
  handleSubmit,
  disabledSubmit,
  isButtonLoading,
  formSubmitButton,
}) => {
  if (isButtonLoading)
    return (
      <div style={{ display: 'flex', margin: '20px auto' }}>
        <CircularProgress size={30} />
      </div>
    );

  if (!uniformForm)
    return (
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          {formCancelButton}
        </Button>
        {showSubmit && (
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={disabledSubmit || isButtonLoading}
          >
            {formSubmitButton}
          </Button>
        )}
      </DialogActions>
    );

  return null;
};

const DialogLayout = (props) => {
  const {
    isButtonLoading = false,
    open = false,
    isLoading = false,
    handleClose = undefined,
    formTitle = 'form-title',
    handleSubmit = undefined,
    formSubmitButton = 'Submit',
    formCancelButton = 'Cancel',
    showSubmit = true,
    disabledSubmit = false,
    maxWidth = 'sm',
    uniformForm = false,
    subtitle = null,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(maxWidth));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={maxWidth}
    >
      <DialogTitle id="form-dialog-title" className={classes.title}>
        {formTitle}
      </DialogTitle>
      {subtitle && (
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      )}
      <Divider className={classes.divider} />
      <DialogContent>
        {isLoading ? (
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
        ) : (
          props.children
        )}
      </DialogContent>

      <DialogFooter
        uniformForm={uniformForm}
        handleClose={handleClose}
        formCancelButton={formCancelButton}
        showSubmit={showSubmit}
        handleSubmit={handleSubmit}
        disabledSubmit={disabledSubmit}
        isButtonLoading={isButtonLoading}
        formSubmitButton={formSubmitButton}
      />
    </Dialog>
  );
};

export default DialogLayout;
